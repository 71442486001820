import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import {
  Button,
  Grid,
  Modal,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { yyyymmdd } from '../libs/date_convert';
import { CSVLink } from 'react-csv';
import { Items, Order } from '../libs/GraphQL.js';

export default class DownloadProductionCount extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: false,
      forecastData: {},
      currentDate: new Date(),
      searchDates: this.generateDateRange(new Date()),
      showPicker: true,
      regionFilter: 'all-regions',
      alertModal: {
        mode: false,
        text: '',
      },
      shelfLife: {},
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.getForecastData = this.getForecastData.bind(this);
    this.generateDateRange = this.generateDateRange.bind(this);
  }
  showDatePicker() {
    this.setState({
      showPicker: !this.state.showPicker,
      isLoading: false,
    });
  }

  applyMinnesotaFilter(addressForDelivery) {
    return (
      addressForDelivery !== null && addressForDelivery.startsWith('MN_MSP_')
    );
  }

  generateDateRange(value) {
    let endDate = new Date(value.getTime());
    let copiedValue = new Date(value.getTime());
    let searchDates = [];
    endDate.setDate(endDate.getDate() + 7);
    while (copiedValue < endDate) {
      searchDates.push(yyyymmdd(copiedValue, '-'));
      copiedValue.setDate(copiedValue.getDate() + 1);
    }
    return searchDates;
  }
  handleChange(e) {
    console.log(e);
    this.setState({ Modal_quant: e.target.value });
  }
  async componentDidMount() {
    this.getLiveVendItems();
  }

  async getLiveVendItems() {
    try {
      var shelfLife = {};
      let itemsRaw = await Items();
      if ('data' in itemsRaw && 'items' in itemsRaw['data']) {
        let items = itemsRaw.data.items.filter(
          (item) => !item.Categories || !item.Categories.includes('Drinks'),
        );
        for (var idx in items) {
          shelfLife[items[idx]['id']] = items[idx]['shelfLife'];
        }

        this.setState({ shelfLife: shelfLife });
      } else {
        let alertModal = this.state.alertModal;
        alertModal['mode'] = true;
        alertModal['text'] = ['Unable to load Vend items'];
        this.setState({ alertModal: alertModal });
      }
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      alertModal['text'] = [
        'Unable to load Vend items, Please check your internet and your permissions',
      ];
      this.setState({ alertModal: alertModal });
    }
  }
  async getForecastData() {
    this.setState({
      isLoading: true,
    });
    try {
      //---------------
      var forecastData = [];
      let orderState = [];
      for (var search_date in this.state.searchDates) {
        let order = await Order(
          null,
          this.state.searchDates[search_date],
          null,
        );
        orderState = orderState.concat(order.data.ordersByDay.orders);
        while (order.data.ordersByDay.nextToken !== null) {
          console.log(order.data.ordersByDay.nextToken);
          order = await Order(
            order.data.ordersByDay.nextToken,
            this.state.searchDates[search_date],
            null,
          );
          orderState = orderState.concat(order.data.ordersByDay.orders);
        }
      }
      // Dedupe
      orderState = Array.from(new Set(orderState.map(JSON.stringify))).map(
        JSON.parse,
      );
      // apply filter
      orderState = orderState.filter(
        (orderData) =>
          this.state.regionFilter === 'all-regions' ||
          (this.state.regionFilter === 'minnesota' &&
            this.applyMinnesotaFilter(orderData['addressForDelivery'])),
      );
      var finalCount = {};
      for (var item in orderState) {
        for (var iCount in orderState[item]['itemsCount']) {
          var ic = orderState[item]['itemsCount'][iCount];
          if (
            'productionDate' in ic &&
            this.state.searchDates.includes(ic['productionDate'])
          ) {
            if (!finalCount.hasOwnProperty(ic['productionDate'])) {
              finalCount[ic['productionDate']] = {};
            }

            if (
              !finalCount[ic['productionDate']].hasOwnProperty(ic['itemName'])
            ) {
              finalCount[ic['productionDate']][ic['itemName']] = 0;
            }
            finalCount[ic['productionDate']][ic['itemName']] += ic['count'];
          }
        }
      }
      for (let proddate in finalCount) {
        for (item in finalCount[proddate]) {
          var temp = {};
          temp['count'] = finalCount[proddate][item];
          temp['item'] = item;
          var donateByDate = new Date(proddate);
          donateByDate = new Date(
            donateByDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000,
          );
          // Add a single day because it should get n full days in fridge
          donateByDate.setDate(donateByDate.getDate() + 1);
          if (!(item in this.state.shelfLife)) {
            temp['donateBy'] = 'Shelf life for this item is unavailable';
          } else {
            donateByDate.setDate(
              donateByDate.getDate() + this.state.shelfLife[item],
            );
            temp['donateBy'] = yyyymmdd(donateByDate, '-');
          }
          temp['prodDate'] = proddate;
          forecastData.push(temp);
        }
      }
      //---------------------
      this.setState({
        isLoading: false,
        forecastData: forecastData,
        showPicker: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleDateChange(value) {
    this.setState({
      searchDates: this.generateDateRange(value),
      currentDate: value,
    });
  }

  getFileName() {
    var startingDate = 'unknown';
    if (
      this.state.searchDates !== undefined &&
      this.state.searchDates.length > 0
    ) {
      startingDate = this.state.searchDates[0];
    }
    return (
      'ProductionCount-Starting ' + startingDate + '-' + this.state.regionFilter
    );
  }

  render() {
    let headers = [];
    let rows = [];
    headers = [
      <tr key={'header-table'}>
        <th key={'prodDate'}>Production Date</th>
        <th key={'item'}>Item</th>
        <th key={'count'}>Count</th>
        <th key={'donateBy'}>Donate By</th>
      </tr>,
    ];

    if (this.state.forecastData.length > 0) {
      rows = this.state.forecastData
        .sort(function compare(a, b) {
          if (a.prodDate < b.prodDate) {
            return -1;
          }
          if (a.prodDate > b.prodDate) {
            return 1;
          }
          return 0;
        })
        .map((header) => (
          <tr key={header['item'] + header['prodDate']}>
            <td>{header['prodDate']}</td>
            <td>{header['item']}</td>
            <td>{header['count']}</td>
            <td>{header['donateBy']}</td>
          </tr>
        ));
    } else {
      rows = (
        <tr key={'NO-Data'}>
          <td key={'NO-Data'}> No Data for these dates</td>
        </tr>
      );
    }
    return (
      <div>
        {this.state.alertModal.mode && (
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{this.state.alertModal.text}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  var alertModal = this.state.alertModal;
                  alertModal['mode'] = false;
                  this.setState({ alertModal: alertModal });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {!this.state.showPicker && (
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            onClick={this.showDatePicker}
            type="submit"
            isLoading={this.state.isLoading}
            text="Choose Another Date or Filter"
            loadingText="Loading Locations…"
          />
        )}
        {this.state.showPicker && (
          <Grid style={{ minHeight: '100vh' }}>
            <Row className="text-center" style={{ marginBottom: '15px' }}>
              <h3>Start Date:</h3>
              <DatePicker
                onChange={this.handleDateChange}
                value={this.state.currentDate}
              />
            </Row>
            <Row className="text-center" style={{ marginBottom: '15px' }}>
              <ToggleButtonGroup
                type="radio"
                name="options"
                value={this.state.regionFilter}
                onChange={(e) => {
                  this.setState({
                    regionFilter: e,
                  });
                }}
                style={{ zIndex: 0 }}
              >
                <ToggleButton value="all-regions">All Regions</ToggleButton>
                <ToggleButton value="minnesota">MSP</ToggleButton>
              </ToggleButtonGroup>
            </Row>
            <Row className="text-center">
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={this.getForecastData}
                disabled={this.state.isLoading}
              >
                {' '}
                {!this.state.isLoading ? 'Search' : 'Loading...'}{' '}
              </Button>
            </Row>
          </Grid>
        )}
        {!this.state.isLoading && !this.state.showPicker && (
          <div>
            <h3>
              {`Filters Applied: ${this.state.regionFilter
                .toUpperCase()
                .replace(/-/g, ' ')}`}
            </h3>
            <div>
              <Table bordered responsive>
                <thead>{headers}</thead>
                <tbody>{rows}</tbody>
              </Table>
            </div>
            <div>
              <Row className="text-center">
                <h2>
                  <CSVLink
                    data={this.state.forecastData}
                    filename={this.getFileName()}
                  >
                    Download as CSV
                  </CSVLink>
                </h2>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
