import React, { useRef, useState } from 'react';
import styledComponent from '../utils/styledComponent';

const FileUploadForm = styledComponent('form', {
  height: '170px',
  width: '100%',
  textAlign: 'center',
  position: 'relative',
  '& p': {
    fontSize: '18px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  '& input': {
    display: 'none',
  },
  '& label': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #cbd5e1',
    borderRadius: '1rem',
    backgroundColor: '#f3f4fc',
    transition: '0.5s',
  },
  '& label.dragActive': {
    backgroundColor: '#FFFFFF',
  },
  '& button': {
    cursor: 'pointer',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  '& button:hover': {
    textDecorationLine: 'underline',
  },
  '& .dragFileElement': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});

const FileUpload = ({ onFileChange }) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChange(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <FileUploadForm
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        id="inputFileUpload"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={handleChange}
      />
      <label
        htmlFor="inputFileUpload"
        className={dragActive ? 'dragActive' : ''}
      >
        {dragActive ? (
          <p>Drop file here</p>
        ) : (
          <div>
            <p>Drag and drop your file here or</p>
            <button onClick={onButtonClick}>Upload a file</button>
          </div>
        )}
      </label>
      {dragActive && (
        <div
          className="dragFileElement"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </FileUploadForm>
  );
};

export default FileUpload;
