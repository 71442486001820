/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../../../atoms/Button';
import styledComponent from '../../../utils/styledComponent';
import NumberTicker from '../../../atoms/forms/NumberTicker';
import { CreatePickPackLineItemInputMutation } from '../../../libs/GraphQL';
import { generateLayout } from '../../../libs/generateLayout';

const StyledSection = styledComponent('section', {
  '& label': {
    fontSize: '14px',
  },
});

const OversAndUnders = React.memo(
  ({ isLoading, pickItem, history, itemsData }) => {
    const items = [...itemsData];
    const [overUnderData, setOverUnderData] = useState(
      pickItem.itemsCount || [],
    );

    const updateItem = (value, item) => {
      const indexOfValue = overUnderData.findIndex(
        (i) => i.itemName === item.id,
      );
      const updated = overUnderData;
      if (indexOfValue <= -1) {
        updated.push({ itemName: item.id, errorCount: value });
      } else {
        updated[indexOfValue] = {
          itemName: item.id,
          errorCount: value,
        };
      }
      setOverUnderData(updated);
    };
    // Excluding drinks, maple chili nut mix and chocolate trail mix
    const exclusionList = [
      'LACOLOMBE_TRIPLE_DRAFT',
      'SPINDRIFT_RASPBERRY_LIME',
      'LACOLOMBE_VANILLA_DRAFT',
      'BREW_DR_LOVE',
      'HARMLESS_COCONUT_WATER',
      'MAPLE_CHILI_NUT_MIX',
      'CHOCOLATE_TRAIL_MIX',
    ];
    let layout = generateLayout(items);
    return (
      <StyledSection data-testid="oversAndUnders">
        <h3>Overs and Unders</h3>
        <p>
          Enter a number or use the arrows. Positive numbers indicate overs and
          negative numbers unders.
        </p>
        {!isLoading && items.length && (
          <ul>
            {Object.keys(layout).map((keyName) => {
              return Object.keys(layout[keyName]).map((itemKeyName) => {
                return layout[keyName][itemKeyName].map((itemKey) => {
                  const item = items.find((itemVal) => itemVal.id === itemKey);
                  if (item) {
                    const itemCount = pickItem.itemsCount
                      ? pickItem.itemsCount.find(
                          (val) => val.itemName === item.id,
                        )
                      : 0;
                    return !exclusionList.includes(item.id) ? (
                      <li key={item.id}>
                        <NumberTicker
                          allowNegativeNumbers
                          colorize
                          colorizeValue={50}
                          defaultValue={itemCount ? itemCount.errorCount : 0}
                          label={item.prettyName}
                          name={item.id}
                          handleSelection={(value) => {
                            updateItem(value, item);
                          }}
                        />
                      </li>
                    ) : null;
                  }
                  return null;
                });
              });
            })}
            <li>
              <Button
                data-testid="oversAndUndersBtn"
                onClick={() => {
                  CreatePickPackLineItemInputMutation({
                    ...pickItem,
                    ...{
                      itemsCount: overUnderData,
                    },
                  })
                    .then(() => {
                      toast.success('Overs and Unders Updated.');
                      history.push('/ole/index');
                    })
                    .catch(() => {
                      toast.error('Please try again later.');
                    });
                }}
              >
                Update Overs and Unders
              </Button>
            </li>
          </ul>
        )}
      </StyledSection>
    );
  },
);

OversAndUnders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  pickItem: PropTypes.shape({
    itemsCount: PropTypes.array,
  }).isRequired,
  itemsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

OversAndUnders.defaultProps = {
  isLoading: false,
};

export default OversAndUnders;
