import { Storage } from 'aws-amplify';

export const uploadFileToS3 = async (file, keyPrefix, uploadKey = '') => {
  try {
    const currentDate = new Date();
    const dateString = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    const timeString = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    const key = uploadKey
      ? `${keyPrefix}/${uploadKey}${dateString}T${timeString}.csv`
      : `${keyPrefix}/${dateString}T${timeString}.csv`;

    const result = await Storage.put(key, file);

    console.log('Upload Success', result);
    return result;
  } catch (error) {
    console.error('Error in S3 file upload:', error);
    throw error;
  }
};
