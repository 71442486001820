import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styledComponent from '../utils/styledComponent';

const StyledUl = styledComponent('ul', {
  '& a': {
    color: '#5F8E40',
  },
  '& li': {
    display: 'inline',
    paddingRight: '5px',
  },
  fontSize: '16px',
  listStyle: 'none',
  margin: '20px 0',
  padding: 0,
});

const Button = ({ items, ...otherProps }) => {
  return (
    <StyledUl {...otherProps}>
      {items.map((item, i) => (
        <li key={i}>
          {item.link && <Link to={item.link}>{item.text} &gt;</Link>}
          {!item.link && <span>{item.text}</span>}
        </li>
      ))}
    </StyledUl>
  );
};
Button.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Button;
