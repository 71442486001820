import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { NavDropdown, MenuItem, NavItem } from 'react-bootstrap';
import { contextState } from '../ContextProvider';

const NavItems = ({ history }) => {
  const { contextData } = contextState();
  return (
    <>
      {contextData.features.ole && (
        <NavItem
          onClick={(e) => {
            e.preventDefault();
            history.push('/ole/index');
          }}
          eventKey={7}
        >
          OLE
        </NavItem>
      )}
      <NavDropdown
        key="Orders"
        eventKey="6.1"
        title="Orders"
        id="basic-nav-dropdown"
      >
        <LinkContainer to="/orders-v2/bulk-create">
          <MenuItem>Create</MenuItem>
        </LinkContainer>
        <LinkContainer to="/orders/list">
          <MenuItem>View All</MenuItem>
        </LinkContainer>
        <LinkContainer to="/orders/massupdate">
          <MenuItem>Order Mass Update</MenuItem>
        </LinkContainer>
        <LinkContainer to="/orders/download">
          <MenuItem>Download Range</MenuItem>
        </LinkContainer>
      </NavDropdown>
      <NavDropdown
        key="Production"
        eventKey="6.2"
        title="Production"
        id="basic-nav-dropdown"
      >
        <LinkContainer to="/productionline">
          <MenuItem>Production Line</MenuItem>
        </LinkContainer>
        <LinkContainer to="/setproductiondate/">
          <MenuItem>Production Scheduling</MenuItem>
        </LinkContainer>
        <LinkContainer to="/productioncount/download">
          <MenuItem>Daily Production Count</MenuItem>
        </LinkContainer>
        <LinkContainer exact to="/reports/allocation">
          <MenuItem>Packing Slips</MenuItem>
        </LinkContainer>
        <LinkContainer to="/reports/allocation/preview">
          <MenuItem>Packing Slip Preview</MenuItem>
        </LinkContainer>
        {contextData.features.productionAppAdminMgmt && (
          <LinkContainer to="/production-admin">
            <MenuItem>Prod App Admin Mgmt</MenuItem>
          </LinkContainer>
        )}
        {contextData.features['receiving-invoices'] && (
          <LinkContainer to="/receivinginvoices">
            <MenuItem>Receiving Invoices</MenuItem>
          </LinkContainer>
        )}
        {/* TODO: capacity work is in progress COMING SOON */}
        {/* <LinkContainer to="/capacity/upload">
          <MenuItem>Upload Capacities</MenuItem>
        </LinkContainer>
        <LinkContainer to="/capacity/view">
          <MenuItem>View Capacities</MenuItem>
        </LinkContainer> */}
      </NavDropdown>
    </>
  );
};
NavItems.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default NavItems;
