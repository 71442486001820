import React, { Component } from 'react';
import LoaderButton from '../LoaderButton';
import { Button, Grid, Modal, Row, Table, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { yyyymmdd } from '../../libs/date_convert';
import { CSVLink } from 'react-csv';
import { Items, Order } from '../../libs/GraphQL.js';

export default class DownloadDeliveryCount extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: true,
      forecastData: {},
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      searchStartDate: yyyymmdd(null, '-'),
      searchEndDate: yyyymmdd(null, '-'),
      showPicker: true,
      shelfLife: {},
      countDeliveryProd: {},
      alertModal: {
        mode: false,
        text: '',
      },
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.getForecastData = this.getForecastData.bind(this);
    this.getLiveVendItems = this.getLiveVendItems.bind(this);
  }
  showDatePicker() {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }
  handleChange(e) {
    console.log(e);
    this.setState({ Modal_quant: e.target.value });
  }

  async componentDidMount() {
    this.getLiveVendItems();
  }

  async getLiveVendItems() {
    try {
      var shelfLife = {};
      let itemsRaw = await Items();
      if ('data' in itemsRaw && 'items' in itemsRaw['data']) {
        let items = itemsRaw.data.items.filter(
          (item) => !item.Categories || !item.Categories.includes('Drinks'),
        );
        for (var idx in items) {
          shelfLife[items[idx]['id']] = items[idx]['shelfLife'];
        }

        this.setState({ shelfLife: shelfLife });
      } else {
        let alertModal = this.state.alertModal;
        alertModal['mode'] = true;
        alertModal['text'] = ['Unable to load Vend items'];
        this.setState({ alertModal: alertModal });
      }
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      alertModal['text'] = [
        'Unable to load Vend items, Please check your internet and your permissions',
      ];
      this.setState({ alertModal: alertModal });
    }
  }

  async getForecastData() {
    try {
      //---------------
      var forecastData = [];
      var countDeliveryProd = {};
      var start = new Date(this.state.currentStartDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.state.currentEndDate);
      end.setHours(0, 0, 0, 0);
      if (start > end) {
        let alertModal = this.state.alertModal;
        alertModal['mode'] = true;
        alertModal['text'] = 'Start Date should be in the past of end date';
        this.setState({ alertModal: alertModal });
        return;
      }
      for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
        var toSearch = yyyymmdd(d, '-');
        let order = await Order(null, null, toSearch);
        let orderState = order.data.ordersByDay.orders;
        while (order.data.ordersByDay.nextToken !== null) {
          order = await Order(order.data.ordersByDay.nextToken, null, toSearch);
          orderState = orderState.concat(order.data.ordersByDay.orders);
        }
        var finalCount = {};
        var productionDateUnavailable = {};
        for (var item in orderState) {
          for (var iCount in orderState[item]['itemsCount']) {
            var ic = orderState[item]['itemsCount'][iCount];
            if (!finalCount.hasOwnProperty(ic['itemName'])) {
              finalCount[ic['itemName']] = 0;
            }
            if (!productionDateUnavailable.hasOwnProperty(ic['itemName'])) {
              productionDateUnavailable[ic['itemName']] = 0;
            }
            finalCount[ic['itemName']] += ic['count'];
            if ('productionDate' in ic && ic['productionDate'] !== null) {
              productionDateUnavailable[ic['itemName']] += ic['count'];
              if (
                !(
                  toSearch +
                    '#' +
                    ic['productionDate'] +
                    '#' +
                    ic['itemName'] in
                  countDeliveryProd
                )
              ) {
                countDeliveryProd[
                  toSearch + '#' + ic['productionDate'] + '#' + ic['itemName']
                ] = 0;
              }
              countDeliveryProd[
                toSearch + '#' + ic['productionDate'] + '#' + ic['itemName']
              ] += ic['count'];
            }
          }
        }
        for (item in finalCount) {
          var temp = {};
          temp['count'] = finalCount[item];
          temp['item'] = item;
          temp['deliveryDate'] = toSearch;
          temp['productionDate'] = '';
          temp['productionDateUnavailable'] =
            finalCount[item] - productionDateUnavailable[item];
          forecastData.push(temp);
        }
      }
      var arrayDeliveryProd = [];
      for (var k in countDeliveryProd) {
        var t = {};
        var splitArray = k.split('#');
        t['DeliveryDate'] = splitArray[0];
        t['ProductionDate'] = splitArray[1];
        t['Item'] = splitArray[2];
        t['Count'] = countDeliveryProd[k];
        arrayDeliveryProd.push(t);
      }
      forecastData.sort(function (a, b) {
        if (a['deliveryDate'] < b['deliveryDate']) {
          return -1;
        } else if (a['deliveryDate'] > b['deliveryDate']) {
          return 1;
        } else {
          if (a['item'] < b['item']) {
            return -1;
          } else if (a['item'] > b['item']) {
            return 1;
          } else {
            return 1;
          }
        }
      });
      //---------------------
      this.setState({
        isLoading: false,
        forecastData: forecastData,
        downloadData: JSON.parse(JSON.stringify(forecastData)),
        countDeliveryProd: arrayDeliveryProd,
        showPicker: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleStartDateChange(value) {
    this.setState({
      searchStartDate: yyyymmdd(value, '-'),
      currentStartDate: value,
    });
  }

  handleEndDateChange(value) {
    this.setState({
      searchEndDate: yyyymmdd(value),
      currentEndDate: value,
    });
  }

  render() {
    let headers = [];
    let rows = [];
    headers = [
      <tr key={'header-table'}>
        <th key={'deliveryDate'}>CMS Delivery Date</th>
        <th key={'item'}>Item name</th>
        <th key={'count'}>Count</th>
        <th key={'productionDateUnavailable'}>Production date unavailable</th>
      </tr>,
    ];

    if (this.state.forecastData.length > 0) {
      rows = this.state.forecastData.map((header) => (
        <tr key={header['item'] + header['deliveryDate']}>
          <td>{header['deliveryDate']}</td>
          <td>{header['item']}</td>
          <td>{header['count']}</td>
          <td>{header['productionDateUnavailable']}</td>
        </tr>
      ));
    } else {
      rows = (
        <tr key={'NO-Data'}>
          <td key={'NO-Data'}> No Data for this date</td>
        </tr>
      );
    }
    return (
      <div>
        {this.state.alertModal.mode && (
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{this.state.alertModal.text}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  var alertModal = this.state.alertModal;
                  alertModal['mode'] = false;
                  this.setState({ alertModal: alertModal });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {!this.state.showPicker && (
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            onClick={this.showDatePicker}
            type="submit"
            isLoading={this.state.isLoading}
            text="Choose Another Date"
            loadingText="Loading Locations…"
          />
        )}
        {this.state.showPicker && (
          <Grid style={{ minHeight: '100vh' }}>
            <Row className="text-center" style={{ marginBottom: '15px' }}>
              <Col sm={6}>
                <h3>Start CMS Delivery Date</h3>
                <DatePicker
                  onChange={this.handleStartDateChange}
                  value={this.state.currentStartDate}
                />
              </Col>
              <Col>
                <h3>End CMS Delivery Date</h3>
                <DatePicker
                  onChange={this.handleEndDateChange}
                  value={this.state.currentEndDate}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={this.getForecastData}
              >
                {' '}
                Search{' '}
              </Button>
            </Row>
          </Grid>
        )}
        {!this.state.isLoading && (
          <div>
            <div>
              <Table bordered responsive>
                <thead>{headers}</thead>
                <tbody>{rows}</tbody>
              </Table>
            </div>
            <div>
              <Row className="text-center">
                <Col xs={6}>
                  <h2>
                    <CSVLink
                      data={this.state.downloadData.map(function (item) {
                        delete item.productionDateUnavailable;
                        return item;
                      })}
                      filename={
                        this.state.searchStartDate +
                        ' to ' +
                        this.state.searchEndDate
                      }
                    >
                      Download Delivery Count
                    </CSVLink>
                  </h2>
                </Col>
                <Col xs={6}>
                  <h2>
                    <CSVLink
                      data={this.state.countDeliveryProd}
                      filename={
                        this.state.searchStartDate +
                        ' to ' +
                        this.state.searchEndDate
                      }
                    >
                      Download production dates
                    </CSVLink>
                  </h2>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
