/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../../utils/styledComponent';

const StyledDiv = styledComponent('div', {
  '& input': {
    marginRight: '8px',
  },
});

const Radio = ({ checked, value, handleChange, name, label }) => {
  return (
    <StyledDiv className="radioWrapper">
      <label>
        <input
          defaultChecked={checked || ''}
          onChange={handleChange}
          type="radio"
          name={name}
          value={value}
        />
        {label}
      </label>
    </StyledDiv>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Radio.defaultProps = {
  checked: '',
  value: '',
};

export default Radio;
