import React from 'react';

const Summary = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        fillRule="evenodd"
        d="M20.125 11.75v6.581H6.143V11.75h13.982zm-16.425 0v6.581H.874V11.75H3.7zm16.425-9.081V9.25H6.143V2.67h13.982zm-16.425 0V9.25H.874V2.67H3.7z"
      />
    </svg>
  );
};

export default Summary;
