import React, { useEffect } from 'react';
import styledComponent from '../../utils/styledComponent';
import { contextState, contextDispatch } from '../../ContextProvider';

const StyledSection = styledComponent('section', {});

const FinishedGoodRunDetails = (props) => {
  const { contextData } = contextState();
  const dispatch = contextDispatch();
  const currentRun = contextData.finishedGoods.activeRun;
  return (
    <StyledSection>
      <table id="RunDetailsTable">
        <tr>
          <th>Product</th>
          <th>Number of line workers</th>
          <th>Line Number</th>
          <th>Produced</th>
        </tr>
        <tr>
          <td>{currentRun.itemName}</td>
          <td>{currentRun.numberOfLineWorkers}</td>
          <td>{currentRun.lineNumber}</td>
          <td>{props.amountProduced}</td>
        </tr>
      </table>
    </StyledSection>
  );
};

export default FinishedGoodRunDetails;
