import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageNavItem from '../../atoms/PageNavItem';
import PageNav from '../../organisms/PageNav';
import styledComponent from '../../utils/styledComponent';
import { ReadPickPackLineItem } from '../../libs/GraphQL';

const StyledSection = styledComponent('section', {
  '& header': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  '& h1': {
    color: '#457A23',
  },
  '& h2': {
    color: '#565656',
    fontSize: '20px',
    margin: 0,
  },
});

const OLEDashboard = ({ history }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log(data);
    ReadPickPackLineItem()
      .then((res) => {
        setData(res.data.readPickPackLineItem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data]);

  return (
    <StyledSection>
      <h1>Dashboard</h1>
      <PageNav>
        <PageNavItem href="/ole/dashboard" isActive>
          Dashboard
        </PageNavItem>
        <PageNavItem href="/ole/">Data Entry</PageNavItem>
      </PageNav>
    </StyledSection>
  );
};

OLEDashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OLEDashboard;
