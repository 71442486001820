import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import { Alert, Button } from 'react-bootstrap';
import { yyyymmdd } from '../libs/date_convert';
import API, { graphqlOperation } from '@aws-amplify/api';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Auth } from 'aws-amplify';

const columns = [
  {
    dataField: 'displayName',
    text: 'Item',
    editable: false,
  },
  {
    dataField: 'quantity',
    text: 'Quantity',
    validator: (newValue, row, column) => {
      if (newValue === '') {
        return {
          valid: false,
          message: 'Quantity should not be empty',
        };
      }
      if (isNaN(newValue)) {
        return {
          valid: false,
          message: 'Quantity should be numeric',
        };
      }
      if (newValue < 0) {
        return {
          valid: false,
          message: 'Quantity should bigger than 0',
        };
      }
      return true;
    },
    editor: {
      type: Type.TEXTAREA,
    },
  },
];

export default class EditPackingSlip extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.getAllPackingSlips = this.getAllPackingSlips.bind(this);
    this.packingSlips = this.packingSlips.bind(this);
    this.cleanPackingSlips = this.cleanPackingSlips.bind(this);
    this.afterSaveCell = this.afterSaveCell.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.file = null;
    this.state = {
      locationList: [],
      filteredList: [],
      selectedLocation: '',
      isLoading: true,
      reportData: {},
      alertModal: {
        mode: false,
        text: '',
      },
    };
  }
  handleFilterChange(event) {
    var pList = [];
    for (var key in this.state.reportData) {
      if (this.state.reportData[key]['location'] === event.target.value) {
        pList.push(this.state.reportData[key]);
      }
    }
    this.setState({
      selectedLocation: event.target.value,
      filteredList: pList,
    });
    console.log(this.state);
  }
  async componentDidMount() {
    const currentSession = await Auth.currentAuthenticatedUser();
    this.setState({
      user: currentSession.signInUserSession.idToken.payload.email,
    });
    this.getAllPackingSlips();
  }
  packingSlips() {
    const packingSlips = API.graphql(
      graphqlOperation(
        `
    query GetPackingSlips($Date:AWSDate!){
      PackingSlips(Date:$Date){
        uniqueId
        date
        driver
        stop
        data{
          itemName
          value
        }
        location
      }
    }`,
        { Date: yyyymmdd(null, '-') },
      ),
    );
    console.log(packingSlips);
    return packingSlips;
  }

  cleanPackingSlips(pSlips) {
    var result = [];
    var locationList = [];
    for (var key in pSlips) {
      locationList.push(pSlips[key]['location']);
      var tempData = pSlips[key]['data'];
      var tempDataNew = {};
      for (var d in tempData) {
        tempDataNew[tempData[d].itemName] = {
          value: tempData[d].value,
          productionDate: tempData[d].productionDate,
        };
      }
      pSlips[key]['data_ids'] = tempDataNew;
      delete pSlips[key]['data'];
      result.push(pSlips[key]);
    }
    this.setState({ locationList: locationList.sort() });
    return pSlips;
  }

  afterSaveCell(oldValue, newValue, row, column) {
    if (parseInt(oldValue, 10) === parseInt(newValue, 10)) {
      return;
    }
    row['fullData'][row['id']] = parseInt(newValue, 10);
    var dataToWrite = [];
    for (var key in row['fullData']) {
      var temp = {
        itemName: key,
        value: row['fullData'][key],
      };
      dataToWrite.push(temp);
    }
    var obj = {
      uniqueId: row['uniqueId'],
      date: row['date'],
      modifiedBy: this.state.user,
      data: dataToWrite,
      location: row['location'],
    };
    this.submit(obj);
  }
  async submit(requestObject) {
    var uniqueId = requestObject['uniqueId'];
    var splitArray = uniqueId.split('#');
    splitArray[2] = parseInt(splitArray[2], 10) + 1;
    requestObject['uniqueId'] = splitArray.join('#');
    try {
      const response = await API.graphql(
        graphqlOperation(
          `
            mutation EditPackingSlip($input:PackingSlipInput!){
              editPackingSlip(input:$input){
                message
                code
              }
            }
      `,
          {
            input: requestObject,
          },
        ),
      );
      if (
        'data' in response &&
        'editPackingSlip' in response.data &&
        'code' in response.data.editPackingSlip &&
        'message' in response.data.editPackingSlip
      ) {
        if (response.data.editPackingSlip.code !== 200) {
          let alertModal = this.state.alertModal;
          alertModal['mode'] = true;
          alertModal['text'] = response.data.editPackingSlip.message;
          this.setState({ alertModal: alertModal });
        }
      } else {
        let alertModal = this.state.alertModal;
        alertModal['mode'] = true;
        alertModal['text'] = 'Unable to Edit, Please try again';
        this.setState({ alertModal: alertModal });
      }
      this.getAllPackingSlips();
    } catch (e) {
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      let errorString = JSON.stringify(e);
      let errorObject = JSON.parse(errorString);
      console.log(errorObject);
      if ('errors' in errorObject && 'message' in errorObject.errors) {
        alertModal['text'] = errorObject.errors.message;
      } else {
        alertModal['text'] =
          'Unable to Edit, Please check your internet and your permissions';
      }
      this.setState({ alertModal: alertModal });
    }
  }
  async getAllPackingSlips() {
    try {
      var packingSlips = await this.packingSlips();
      if (
        packingSlips.hasOwnProperty('data') &&
        packingSlips['data'].hasOwnProperty('PackingSlips')
      ) {
        packingSlips = this.cleanPackingSlips(
          packingSlips['data']['PackingSlips'],
        );
        this.setState({
          isLoading: false,
          reportData: packingSlips,
        });
      } else {
        let alertModal = this.state.alertModal;
        alertModal['mode'] = true;
        //let errorString = JSON.stringify(e);
        //var errorObject = JSON.parse(errorString);
        alertModal['text'] = packingSlips['errors'][0]['message'];
        this.setState({ alertModal: alertModal });
      }
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      //let errorString = JSON.stringify(e);
      //var errorObject = JSON.parse(errorString);
      alertModal['text'] =
        'Unable to load packing slips, Please check your internet and permissions';
      this.setState({ alertModal: alertModal });
    }
  }
  refreshData() {
    this.setState({ isLoading: true });
    this.getAllPackingSlips();
  }

  generatePackingSlipData(data) {
    return (
      <div>
        <BootstrapTable
          keyField="id"
          data={Object.keys(data['data_ids']).map((item) => {
            return {
              id: item,
              uniqueId: data['uniqueId'],
              displayName: item,
              quantity: data['data_ids'][item].value,
              location: data['location'],
              date: data['date'],
              fullData: data['data_ids'],
            };
          })}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: 'dbclick',
            autoSelectText: true,
            blurToSave: true,
            afterSaveCell: this.afterSaveCell,
          })}
        />
        <p style={{ pageBreakBefore: 'always' }}></p>
      </div>
    );
  }

  render() {
    let rows = [];
    let data_array = this.state.filteredList;
    if (Object.keys(data_array).length > 0) {
      rows = data_array.map((route_stop) => (
        <div key={route_stop['location']}>
          <div id="driver-title">
            <strong>{route_stop['location']}</strong>
          </div>
          <p></p>
          <div id="driver-name">
            <strong>{route_stop['driver']}</strong>
          </div>
          <p></p>
          {this.generatePackingSlipData(route_stop)}
        </div>
      ));
    } else if (this.state.reportData.length > 0) {
      rows = (
        <Alert bsStyle="info">
          <h2>Please select a location</h2>
          <div>
            Packing slips have been generated, please select a location for
            which you want to edit the packing slip.
          </div>
        </Alert>
      );
    } else {
      rows = (
        <Alert bsStyle="info">
          <h2>Oh snap! You are a bit early...</h2>
          <div>
            Packing slip has not been generated for today. Please check back
            after 7:30
          </div>
          <Button onClick={this.refreshData}>Refresh Data</Button>
        </Alert>
      );
    }
    return (
      <div>
        {this.state.isLoading && (
          <div>
            <LoaderButton
              block
              bsStyle="info"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text="Choose Another Date"
              loadingText="Loading Packing Slips…"
            />
            <hr></hr>
          </div>
        )}
        <p></p>
        <div>
          <select
            name="selectedLocation"
            value={this.state.selectedLocation}
            className="form-control"
            onChange={this.handleFilterChange}
            selected={this.state.selectedLocation}
          >
            <option value="">Select a location</option>
            {Object.keys(this.state.locationList).map((location) => (
              <option key={location} value={this.state.locationList[location]}>
                {this.state.locationList[location]}
              </option>
            ))}
          </select>
          <br />
        </div>
        <div key={'data'} style={{ fontSize: 'x-large' }}>
          {!this.state.isLoading && rows}
        </div>
      </div>
    );
  }
}
