/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import moment from 'moment';
import useInterval from '../../../utils/interval.hook';
import styledComponent from '../../../utils/styledComponent';

const StyledSection = styledComponent('section', {
  '.durationWrapper': {
    alignItems: 'center',
    display: 'flex',
  },
  '& span': {
    color: '#565656',
    display: 'inline-block',
    fontSize: '48px',
    marginLeft: '20px',
  },
  margin: '20px 0 0 0',
});

const Duration = ({ pickItem }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  useInterval(() => {
    setCurrentTime(moment());
  }, 1000);

  const then = moment
    .unix(parseInt(pickItem.sheetDeliveryTime))
    .format('DD/MM/YYYY HH:mm:ss');

  // NOTE: this will break after 24hrs, but we assume pick/pack will never take that long
  const duration = moment
    .utc(
      moment(currentTime, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(then, 'DD/MM/YYYY HH:mm:ss'),
      ),
    )
    .format('HH:mm:ss');

  return (
    <StyledSection data-testid="duration">
      <h3>Duration</h3>
      <div className="durationWrapper">
        <svg width="63" height="71" viewBox="0 0 63 71" fill="none">
          <path
            d="M51.4266 15.8861C53.0501 15.8723 49.7723 12.9297 49.7677 14.567C45.7166 11.5291 40.9137 9.46433 35.6834 8.69562V5.16572H38.274C38.4754 5.16572 38.6752 5.10729 38.8151 5.00583C38.9643 4.90436 39.0442 4.76445 39.0442 4.6184V0.545782C39.0442 0.401265 38.9597 0.262898 38.8182 0.159891C38.6752 0.0568845 38.4754 0 38.274 0H24.0114C23.8069 0 23.6116 0.0568845 23.4671 0.159891C23.3257 0.261361 23.2426 0.401265 23.2426 0.545782V4.6184C23.2426 4.76445 23.3272 4.90282 23.4686 5.00583C23.6116 5.10576 23.8069 5.16572 24.0114 5.16572H26.605V8.69562C11.5829 10.9064 0 23.8438 0 39.467C0 56.643 13.9736 70.6135 31.1465 70.6135C48.3179 70.6135 62.2884 56.643 62.2884 39.467C62.2853 30.0426 58.0605 21.6007 51.4266 15.8861ZM31.1434 65.8075C16.6164 65.8075 4.80135 53.991 4.80135 39.4655C4.80135 24.9446 16.6164 13.1265 31.1434 13.1265C45.6659 13.1265 57.4824 24.9446 57.4824 39.4655C57.4824 53.991 45.6659 65.8075 31.1434 65.8075Z"
            fill="#565656"
          />
          <path
            d="M43.7088 24.1466L29.6445 38.2109C28.902 38.9535 28.902 40.1588 29.6445 40.9045C30.3887 41.6455 31.5955 41.6455 32.3366 40.9045L46.4008 26.8402C47.1434 26.0961 47.1434 24.8892 46.4008 24.1482C45.6583 23.4025 44.4514 23.4025 43.7088 24.1466Z"
            fill="#565656"
          />
          <path
            d="M50.7825 17.6172C50.6349 17.7863 50.3781 17.8048 50.209 17.6572L47.906 15.6539C47.7368 15.5063 47.7184 15.2496 47.866 15.0805L51.5373 10.8572C51.6849 10.6881 51.9417 10.6696 52.1108 10.8172L54.4138 12.8205C54.5829 12.9681 54.6014 13.2248 54.4538 13.3939L50.7825 17.6172Z"
            fill="#565656"
          />
          <path
            d="M47.6846 10.8787C47.5646 10.7757 47.6292 10.5067 47.826 10.2807L50.149 7.60866C50.3458 7.38112 50.6026 7.28119 50.7225 7.38574L58.0052 13.7183C58.1251 13.8214 58.0606 14.0904 57.8638 14.3164L55.5407 16.9884C55.344 17.216 55.0872 17.3159 54.9673 17.2114L47.6846 10.8787Z"
            fill="#565656"
          />
        </svg>
        <span>{duration}</span>
      </div>
    </StyledSection>
  );
};

Duration.propTypes = {};

export default Duration;
