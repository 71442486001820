/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styledComponent from '../../utils/styledComponent';

const StyledDiv = styledComponent('div', {
  '& input': {
    '&:-webkit-autofill': {
      background: '#FFF',
    },
    appearance: 'textfield',
    background: '#FFF',
    border: `1px solid #CCC`,
    borderRadius: '4px',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: '20px',
    marginTop: '10px',
    padding: '10px 10px 10px 115px',
    width: '250px',
  },
  '& label': {
    color: '#000',
    display: 'block',
    fontSize: '18px',
    fontWeight: '800',
    position: 'relative',
    width: '250px',
  },
  '& .positive input': {
    border: '1px solid #457A23',
    background: '#bbe4a1',
  },
  '& .negative input': {
    border: '1px solid #e01f35',
    background: '#f29ea7',
  },
  '& .warning input': {
    border: '1px solid #e68d19',
    background: '#f1bf7e',
    fontStyle: 'italic',
  },
  '& .ticker': {
    '&.downButton': {
      '& svg': {
        margin: '15px 0 0 10px',
      },
      borderLeft: '1px solid #BCBCBC',
      borderRadius: '0 4px 4px 0',
      right: '1px',
    },
    '&.upButton': {
      '& svg': {
        margin: '15px 0 0 10px',
      },
      borderRight: '1px solid #BCBCBC',
      borderRadius: '4px 0 0 4px',
      left: '1px',
    },
    background: '#E2E2E2',
    bottom: '1px',
    height: '40px',
    position: 'absolute',
    width: '40px',
  },
});

const NumberTicker = ({
  allowNegativeNumbers,
  colorize,
  colorizeValue,
  defaultValue,
  label,
  name,
  handleSelection,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const determineClassname = () => {
    if (currentValue > 0 && currentValue <= colorizeValue) {
      return 'positive';
    }
    if (currentValue <= -1 && currentValue >= -colorizeValue) {
      return 'negative';
    }
    if (currentValue > colorizeValue || currentValue <= -colorizeValue) {
      return 'warning';
    }
    return '';
  };

  return (
    <StyledDiv>
      <label
        className={`${colorize ? determineClassname() : ''}`}
        htmlFor={name}
      >
        {label}
        <Field name={name}>
          {({ meta }) => (
            <div>
              <input
                id={name}
                name={name}
                type="number"
                value={currentValue}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setCurrentValue('0');
                    handleSelection('0');
                  }
                }}
                onFocus={(e) => {
                  if (e.target.value === '0') {
                    setCurrentValue('');
                    handleSelection('');
                  }
                }}
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                  handleSelection(e.target.value);
                }}
              />
              {meta.touched && meta.error && (
                <div className="error">{meta.error}</div>
              )}
            </div>
          )}
        </Field>
        <div
          className="ticker upButton"
          onClick={() => {
            setCurrentValue(parseFloat(currentValue) + 1);
            handleSelection(parseFloat(currentValue) + 1);
          }}
        >
          <svg width="17" height="10" viewBox="0 0 17 10" fill="none">
            <path
              d="M7.77499 0.763158C8.16924 0.348157 8.83074 0.348156 9.22499 0.763157L16.3957 8.31125C17.0008 8.94823 16.5493 10 15.6707 10H1.32931C0.450713 10 -0.000823345 8.94823 0.604309 8.31125L7.77499 0.763158Z"
              fill="#787878"
            />
          </svg>
        </div>
        <div
          className="ticker downButton"
          onClick={() => {
            if (currentValue > 0 || allowNegativeNumbers) {
              setCurrentValue(parseFloat(currentValue) - 1);
              handleSelection(parseFloat(currentValue) - 1);
            }
          }}
        >
          <svg width="17" height="10" viewBox="0 0 17 10" fill="none">
            <path
              d="M9.22501 9.23684C8.83076 9.65184 8.16926 9.65184 7.77501 9.23684L0.604314 1.68875C-0.00081957 1.05177 0.450716 0 1.32931 0L15.6707 0C16.5493 0 17.0008 1.05177 16.3957 1.68875L9.22501 9.23684Z"
              fill="#787878"
            />
          </svg>
        </div>
      </label>
    </StyledDiv>
  );
};

NumberTicker.propTypes = {
  allowNegativeNumbers: PropTypes.bool,
  colorize: PropTypes.bool,
  colorizeValue: PropTypes.number,
  defaultValue: PropTypes.number,
  handleSelection: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

NumberTicker.defaultProps = {
  allowNegativeNumbers: false,
  colorizeValue: 50,
  colorize: false,
  defaultValue: 1,
};

export default NumberTicker;
