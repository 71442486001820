import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderButton from '../LoaderButton';
import { Items, UploadProductionPlanMutation } from '../../libs/GraphQL';
import styledComponent from '../../utils/styledComponent';
import ErrorsBlock from '../../atoms/ErrorsBlock';
import FileUpload from '../../atoms/FileUpload';

const LogModalTitle = styledComponent('h4', {
  borderBottom: '1px solid #e5e5e5',
  padding: '15px',
  margin: '0px',
});

const allowedFileColumns = [
  'Production Date',
  'SKU',
  'Quantity',
  'Full Tote Count',
  'Partial Tote Units',
];
const notReqFileColumns = ['Facility'];
const allowedFacilities = ['CHICAGO_1'];

const clearData = (data) => {
  // remove empty properties (skip columns with blank names)
  const clearedData = data.map((obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key !== '') {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });

  // remove lines that haven't enough columns (skip empty lines)
  const filteredData = clearedData.filter((item) => {
    return Object.keys(item).length >= allowedFileColumns.length;
  });

  return filteredData;
};

const UploadProductionPlan = () => {
  const [fileContent, setFileContent] = useState([]);
  const [allowedItemsNames, setAllowedItemsNames] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [lineErrors, setLineErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertContent, setAlertContent] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasErrors = fileErrors.length > 0 || lineErrors.length > 0;

  const getVendItems = async () => {
    try {
      const itemsNames = [];
      const rawVendItems = await Items();
      const activeVendItems = rawVendItems.data.items.filter(
        (item) => item.status === 'LIVE',
      );
      activeVendItems.forEach((item) => {
        itemsNames.push(item.id);
      });
      setAllowedItemsNames(itemsNames);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getVendItems();
  }, []);

  const validateFile = (file) => {
    const errors = [];
    const fileHeaders = file.meta.fields.filter((col) => col !== '');
    setFileColumns(fileHeaders);

    const columnsToValidate = fileHeaders.filter(
      (item) => !notReqFileColumns.includes(item),
    );

    const missedColumns = allowedFileColumns.filter(
      (x) => !columnsToValidate.includes(x),
    );
    const unknownColumns = columnsToValidate.filter(
      (x) => !allowedFileColumns.includes(x),
    );

    if (missedColumns.length) {
      errors.push(`Missed columns: ${missedColumns.join(', ')}`);
    }

    if (unknownColumns.length) {
      errors.push(
        `Unknown columns: ${unknownColumns.join(
          ', ',
        )}. File should contains following columns: ${allowedFileColumns.join(
          ', ',
        )}`,
      );
    }

    const fileData = clearData(file.data);

    const uniqueValues = new Set();

    for (const item of fileData) {
      const uniqueKey = `${item['Production Date']}_${item.SKU}`;
      if (uniqueValues.has(uniqueKey)) {
        errors.push(
          `${item.SKU} is duplicated on ${item['Production Date']}. A SKU can only be used once per upload for a specific date.`,
        );
      }
      uniqueValues.add(uniqueKey);
    }

    return errors;
  };

  const validateLine = (line, fileColumnsList) => {
    const errors = [];
    const today = moment().format('YYYY-MM-DD');

    // Validate `Production Date` column
    const productionDateMoment = moment(
      line['Production Date'],
      'YYYY-MM-DD',
      true,
    );

    if (line['Production Date'] === '') {
      errors.push('Value required for `Production Date` column');
    } else if (!productionDateMoment.isValid()) {
      errors.push(
        '`Production Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (productionDateMoment.isBefore(today)) {
      errors.push('`Production Date` can not be before today');
    }

    // Validate `SKU` column
    if (line.SKU === '') {
      errors.push('Value required for `SKU` column');
    } else if (!allowedItemsNames.includes(line.SKU)) {
      errors.push('SKU column does not match active SKU list');
    }

    if (line.Quantity === '') {
      errors.push('Value required for `Quantity` column');
    } else if (!isNumber(line.Quantity)) {
      errors.push('`Quantity` column should be a number');
    } else if (line.Quantity < 0) {
      errors.push('`Quantity` column should be a positive number');
    }

    // Validate "Full Tote Count" column (not required)
    if (line['Full Tote Count'] === '') {
      errors.push('Value required for `Full Tote Count` column');
    } else if (!isNumber(line['Full Tote Count'])) {
      errors.push('`Full Tote Count` column should be a number');
    } else if (line['Full Tote Count'] < 0) {
      errors.push('`Full Tote Count` column should be a positive number');
    }

    // Validate "Partial Tote Units" column (not required)
    if (line['Partial Tote Units'] === '') {
      errors.push('Value required for `Partial Tote Units` column');
    } else if (!isNumber(line['Partial Tote Units'])) {
      errors.push('`Partial Tote Units` column should be a number');
    } else if (line['Partial Tote Units'] < 0) {
      errors.push('`Partial Tote Units` column should be a positive number');
    }

    // Validate "Facility" column (not required)
    if (fileColumnsList.includes('Facility')) {
      if (line.Facility === '') {
        errors.push(
          'Value required for `Facility` column if column is present',
        );
      } else if (!allowedFacilities.includes(line.Facility)) {
        errors.push(
          `Facility column should be one of the following: ${allowedFacilities.join(
            ', ',
          )}`,
        );
      }
    }

    return errors;
  };

  const handleFileChange = (file) => {
    setFileErrors([]);
    setLineErrors([]);
    setFileChosen(false);
    setFileContent([]);
    setFileName(file.name);

    if (file.type !== 'text/csv') {
      setFileErrors(['File must be a .csv']);
    } else {
      window.Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (fileData) => {
          const filteredData = clearData(fileData.data);
          const fileValidationErrors = validateFile(fileData);
          if (fileValidationErrors.length) {
            setFileErrors(fileValidationErrors);
          } else {
            const linesValidationErrors = [];
            filteredData.forEach((line, i) => {
              const lineValidationErrors = validateLine(
                line,
                fileData.meta.fields,
              );
              if (lineValidationErrors.length) {
                linesValidationErrors.push({
                  location: `[Line ${i + 2}]`,
                  data: lineValidationErrors,
                });
              }
            });

            if (!linesValidationErrors.length) {
              setFileContent(fileData);
            } else {
              setLineErrors(linesValidationErrors);
            }
          }
        },
      });
    }
    setFileChosen(true);
  };

  const formatFileContent = () => {
    const formattedFileContent = [];
    clearData(fileContent.data).forEach((line) => {
      const formattedLine = {
        sku: line.SKU,
        quantity: line.Quantity,
        productionDate: moment(line['Production Date']).format('YYYY-MM-DD'),
        fullToteCount: line['Full Tote Count'] || 0,
        partialToteUnits: line['Partial Tote Units'] || 0,
        facility: line.Facility || 'CHICAGO_1',
      };
      formattedFileContent.push(formattedLine);
    });
    return formattedFileContent;
  };

  const handleUpload = async () => {
    setLoading(true);
    const alertMessages = [];

    try {
      await UploadProductionPlanMutation(formatFileContent());
      alertMessages.push(
        `Production Plan Uploaded Successfully for ${
          clearData(fileContent.data).length
        } items`,
      );
    } catch (e) {
      console.error('Production Plan Uploading Error:', e);
      e.errors.forEach((error) => {
        alertMessages.push(`Server Error: ${error.message}`);
      });
    }

    setAlertContent(alertMessages);
    setFileContent([]);
    setFileChosen(false);
    setLoading(false);
  };

  return (
    <>
      {fileChosen ? (
        <Button
          block
          bsStyle="primary"
          bsSize="large"
          style={{ marginBottom: '40px' }}
          disabled={loading}
          onClick={() => {
            setFileErrors([]);
            setLineErrors([]);
            setFileChosen(false);
            setFileContent([]);
          }}
        >
          Upload another file
        </Button>
      ) : (
        <FileUpload onFileChange={handleFileChange} />
      )}

      {hasErrors && (
        <ErrorsBlock>
          <ul>
            {fileErrors.map((error) => {
              return <li key={error}>{error}</li>;
            })}
            {lineErrors.map((error) => {
              return (
                <li key={error.location}>
                  <b>{error.location} has an error</b> <br />
                  {error.data.map((msg) => {
                    return <div key={msg}> {msg} </div>;
                  })}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}

      {fileContent.length !== 0 && !hasErrors && (
        <>
          <span>{fileName}</span>
          <Table>
            <thead style={{ border: '1px solid #ddd' }}>
              <tr>
                {fileColumns.map((col) => {
                  return <th key={col}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {fileContent &&
                clearData(fileContent.data).map((line) => {
                  return (
                    <tr key={`${line.SKU}_${line['Production Date']}`}>
                      {fileColumns.map((col) => {
                        return (
                          <td key={col}>
                            {typeof line[col] === 'boolean'
                              ? line[col].toString()
                              : line[col]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="success"
            bsSize="large"
            type="submit"
            isLoading={loading}
            text="Upload Production Plan"
            loadingText="Production Plan Loading…"
            style={{ marginBottom: '40px' }}
            onClick={handleUpload}
          />
        </>
      )}

      <Modal show={alertContent.length}>
        <LogModalTitle>Production Plan Upload Log</LogModalTitle>
        <Modal.Body>
          {alertContent.map((msg) => (
            <div key={msg} style={{ marginBottom: '8px' }}>
              {msg}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            onClick={() => {
              setAlertContent([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadProductionPlan;
