import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import styledComponent from '../../utils/styledComponent';
import LoaderButton from '../LoaderButton';
import { GetProductionPlan } from '../../libs/GraphQL';

const StyledDiv = styledComponent('div', {
  paddingTop: '20px',
  '& .datePickerWrapper': {
    margin: '0 auto',
    width: 'fit-content',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .date-picker-container': {
      position: 'relative',
    },
    '& .react-date-picker__calendar': {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)',
    },
    '& .react-date-picker__button__input__year': {
      width: '40px !important',
    },
    '& .react-date-picker__button__input__month, & .react-date-picker__button__input__day':
      {
        width: '20px !important',
        textAlign: 'center',
      },
    '& .datePickerLabel': {
      textAlign: 'center',
      marginBottom: '2px',
      fontSize: '24px',
    },
  },
  '& .searchButton': {
    width: 'fit-content',
    margin: '0 auto',
  },
  '& .datesRange': {
    marginTop: '20px',
    fontStyle: 'italic',
  },
  '& .table': {
    marginBottom: '120px',
    '& thead': {
      borderTop: '1px solid #ddd',
    },
  },
  '& .fixedBar': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '70px',
    background: '#fff',
    borderTop: '1px solid #ddd',
    textAlign: 'center',
  },
  '& .datePickers': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flexStart',
  },
});

const ExportProductionPlan = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [productionPlan, setProductionPlan] = useState([]);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const formatDataToDownload = (data) => {
    const formattedData = data.map((row) => {
      const formattedRow = {
        'Production Date': row.productionDate,
        SKU: row.sku,
        Quantity: row.quantity,
        'Full Tote Count': row.fullToteCount,
        'Partial Tote Units': row.partialToteUnits,
        Facility: row.facility,
      };
      return formattedRow;
    });
    return formattedData;
  };

  const getFileName = () => {
    const startDate = moment(selectedStartDate).format('YYYY-MM-DD');
    const endDate = moment(selectedEndDate).format('YYYY-MM-DD');
    return `production-plan-${startDate}-${endDate}.csv`;
  };

  const handleSearch = async () => {
    if (moment(selectedStartDate).isAfter(selectedEndDate)) {
      alert('`Start Production Date` must be before `End Production Date`');
    } else {
      setIsLoading(true);

      const startDate = moment(selectedStartDate);
      const endDate = moment(selectedEndDate);
      const dates = [];

      while (startDate.isSameOrBefore(endDate, 'day')) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate.add(1, 'day');
      }

      try {
        const dataToShow = [];
        const fetchDataPromises = dates.map(async (date) => {
          const data = await GetProductionPlan({ productionDate: date });
          return { date, data };
        });

        const fetchedData = await Promise.all(fetchDataPromises);

        fetchedData.forEach(({ data }) => {
          dataToShow.push(...data.data.getProductionPlan);
        });
        setProductionPlan(dataToShow);
      } catch (e) {
        console.error(e);
      }

      setIsSearched(true);
      setIsLoading(false);
    }
  };

  return (
    <StyledDiv>
      {!isSearched && (
        <>
          <div className="datePickers">
            <div className="datePickerWrapper">
              <div className="datePickerLabel">Start Production Date</div>
              <DatePicker
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={isLoading}
              />
            </div>
            <div className="datePickerWrapper">
              <div className="datePickerLabel">End Production Date</div>
              <DatePicker
                value={selectedEndDate}
                onChange={handleEndDateChange}
                disabled={isLoading}
              />
            </div>
          </div>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            onClick={() => handleSearch()}
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            text="Search"
            loadingText="Loading…"
            className="searchButton"
          />
        </>
      )}
      {isSearched && (
        <>
          <Button
            block
            bsStyle="primary"
            bsSize="large"
            onClick={() => {
              setIsSearched(false);
              setProductionPlan([]);
            }}
          >
            Choose a different date
          </Button>
          {productionPlan.length !== 0 ? (
            <>
              <div className="datesRange">
                {moment(selectedStartDate).format('YYYY-MM-DD')} —{' '}
                {moment(selectedEndDate).format('YYYY-MM-DD')}
              </div>
              <Table className="table">
                <thead className="tableHead">
                  <tr>
                    <th>Production Date</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Full Tote Count</th>
                    <th>Partial Tote Units</th>
                    <th>Facility</th>
                  </tr>
                </thead>
                <tbody>
                  {productionPlan.map((row) => {
                    return (
                      <tr>
                        <td>{row.productionDate}</td>
                        <td>{row.sku}</td>
                        <td>{row.quantity}</td>
                        <td>{row.fullToteCount}</td>
                        <td>{row.partialToteUnits}</td>
                        <td>{row.facility}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="fixedBar">
                <h2>
                  <CSVLink
                    data={formatDataToDownload(productionPlan)}
                    filename={getFileName()}
                    target="_blank"
                  >
                    Download CSV
                  </CSVLink>
                </h2>
              </div>
            </>
          ) : (
            <h3>No data for this dates</h3>
          )}
        </>
      )}
    </StyledDiv>
  );
};

export default ExportProductionPlan;
