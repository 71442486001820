import React, { useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import styledComponent from '../../utils/styledComponent';
import LoaderButton from '../LoaderButton';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal';
import {
  GetHoldoverQuantities,
  UpdateHoldoverQuantity,
} from '../../libs/GraphQL';

const StyledDiv = styledComponent('div', {
  paddingTop: '20px',
  '& .datePickerWrapper': {
    margin: '0 auto',
    width: 'fit-content',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .date-picker-container': {
      position: 'relative',
    },
    '& .react-date-picker__calendar': {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)',
    },
    '& .react-date-picker__button__input__year': {
      width: '40px !important',
    },
    '& .react-date-picker__button__input__month, & .react-date-picker__button__input__day':
      {
        width: '20px !important',
        textAlign: 'center',
      },
    '& .datePickerLabel': {
      textAlign: 'center',
      marginBottom: '2px',
      fontSize: '24px',
    },
  },
  '& .searchBoxes': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
    flexGrowth: 1,
  },
  '& .searchBoxesLabel': {
    fontSize: '16px',
    width: '70px',
  },
  '& .searchBox': {
    display: 'flex',
    padding: '12px 16px',
    gap: '8px',
    width: '335px',
    height: '40px',
    background: '#eeeeee',
    borderRadius: '4px',
    flex: 'none',
    flexGrow: 0,
    border: 'none',
  },
  '& .searchButton': {
    width: 'fit-content',
    margin: '0 auto',
  },
  '& .datesRange': {
    marginTop: '20px',
    fontStyle: 'italic',
  },
  '& .table': {
    marginBottom: '120px',
    '& thead': {
      borderTop: '1px solid #ddd',
    },
  },
  '& .fixedBar': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '70px',
    background: '#fff',
    borderTop: '1px solid #ddd',
    textAlign: 'center',
  },
  '& .datePickers': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flexStart',
  },
  '& .warning': {
    color: 'red',
    fontSize: '12px',
  },
  '& .submitButton': {
    marginBottom: '100px',
  },
});

const UpdateHoldoverQuantities = () => {
  const [selectedProductionDate, setSelectedProductionDate] = useState(
    new Date(),
  );
  const [updatedQuantities, setUpdatedQuantities] = useState([]);
  const [currentQuantities, setCurrentQuantities] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const [warning, setWarning] = useState('');
  const [warned, setWarned] = useState(false);
  const [showData, setShowData] = useState(false);

  const toastDefaultOptions = {
    className: 'prettyToast',
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'light',
  };
  useEffect(() => {
    if (currentQuantities.length > 0) {
      setShowData(true);
    }
  }, [currentQuantities]);
  const reset = () => {
    setIsSearched(false);
    setCurrentQuantities([]);
    setUpdatedQuantities([]);
  };
  const handleProductionDateChange = (date) => {
    setSelectedProductionDate(date);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    let failedSkus = [];
    for (let [sku, quantity] of Object.entries(updatedQuantities)) {
      try {
        const fullData = {
          sku,
          quantity,
          productionDate: moment(selectedProductionDate).format('YYYY-MM-DD'),
          locationGroup: 'CHICAGO_1',
        };
        await UpdateHoldoverQuantity(fullData);
      } catch (e) {
        failedSkus.push(sku);
        console.error(e);
      }
    }
    setIsLoading(false);
    if (failedSkus.length > 0) {
      toast.error(
        `Failed to update quantities for the following SKUs: ${failedSkus.join(
          ', ',
        )}`,
        `Failed to update quantities for the following SKUs: ${failedSkus.join(
          ', ',
        )}`,
        toastDefaultOptions,
      );
    } else {
      toast.success(
        `Successfully updated quantities for all SKUs`,
        toastDefaultOptions,
      );
    }
    reset();
  };
  const handleQuantitySubmit = () => {
    const foundIndex = currentQuantities.findIndex(
      (item) => item.sku === openItem,
    );
    let dif =
      currentQuantities[foundIndex].holdoverQuantity <
      updatedQuantities[openItem]
        ? currentQuantities[foundIndex].holdoverQuantity /
          updatedQuantities[openItem]
        : updatedQuantities[openItem] /
          currentQuantities[foundIndex].holdoverQuantity;
    if (Math.abs(dif) < 0.9 && !warned) {
      setWarning(
        'Your new quantity is significantly different than the plan. If this intentional, please confirm again.',
      );
      setWarned(true);
    } else {
      setWarned(false);
      setWarning('');
      setCurrentQuantities((prev) => {
        const temp = [...prev];
        temp[foundIndex].holdoverQuantity = updatedQuantities[openItem];
        return temp;
      });
      setOpenItemModal(false);
    }
  };
  const handleQuantityChange = (e) => {
    e.persist();
    setUpdatedQuantities((prev) => ({
      ...prev,
      [openItem]: parseInt(e.target.value),
    }));
  };
  const handleCellSelect = (sku) => {
    setOpenItemModal(true);
    setOpenItem(sku);
  };

  const handleSearch = async () => {
    const formattedDate = moment(selectedProductionDate).format('YYYY-MM-DD');
    if (moment(formattedDate).isAfter(moment())) {
      alert('Production date cannot be greater than the current date');
    } else {
      setIsLoading(true);
      try {
        const data = await GetHoldoverQuantities({
          productionDate: formattedDate,
          locationGroup: 'CHICAGO_1',
        });
        setCurrentQuantities(data.data.getHoldoverQuantity);
      } catch (e) {
        console.error(e);
      }
      setIsSearched(true);
      setIsLoading(false);
    }
  };

  return (
    <StyledDiv>
      {!isSearched && (
        <>
          <div className="datePickers">
            <div className="datePickerWrapper">
              <div className="datePickerLabel">Production Date</div>
              <DatePicker
                value={selectedProductionDate}
                onChange={handleProductionDateChange}
                disabled={isLoading}
              />
            </div>
          </div>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            onClick={() => handleSearch()}
            type="submit"
            isLoading={isLoading}
            disabled={isLoading || !selectedProductionDate}
            text="Search"
            loadingText="Loading…"
            className="searchButton"
          />
        </>
      )}
      {isSearched && (
        <>
          <Button
            block
            bsStyle="primary"
            bsSize="large"
            onClick={() => {
              setIsSearched(false);
              setCurrentQuantities([]);
              setUpdatedQuantities([]);
            }}
          >
            Choose a different date
          </Button>

          {openItemModal && (
            <>
              <ConfirmationModal
                children={
                  <span className="searchBoxes">
                    <span className="searchBoxesLabel">
                      {`New Quantity for ${openItem}`}:{' '}
                    </span>
                    <input
                      type="number"
                      className="searchBox"
                      onChange={(e) => handleQuantityChange(e)}
                      value={updatedQuantities[openItem]}
                    />
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {warning ? warning : ''}{' '}
                    </div>
                  </span>
                }
                show={openItemModal}
                onConfirm={() => handleQuantitySubmit()}
                onHide={() => {
                  setOpenItemModal(false);
                  setUpdatedQuantities((prev) => {
                    const newQuantities = { ...prev };
                    delete newQuantities[openItem];
                    return newQuantities;
                  });
                }}
                title={'Update Holdover Quantity'}
              />
            </>
          )}
          {showData ? (
            <div className="submitButton">
              <div className="datesRange">
                {moment(selectedProductionDate).format('YYYY-MM-DD')}
              </div>
              <Table className="table">
                <thead className="tableHead">
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {currentQuantities.map((row) => {
                    return (
                      <tr
                        onClick={() => {
                          handleCellSelect(row.sku);
                        }}
                      >
                        <td>{row.sku}</td>
                        <td>{row.holdoverQuantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <LoaderButton
                bsStyle="warning"
                text="Submit Updated Quantities"
                loadingText={'Updating...'}
                isLoading={isLoading}
                bsSize="large"
                disabled={
                  isLoading || Object.keys(updatedQuantities).length === 0
                }
                onClick={() => handleSubmit(true)}
              ></LoaderButton>
            </div>
          ) : (
            <h3>No data for this dates</h3>
          )}
        </>
      )}
    </StyledDiv>
  );
};

export default UpdateHoldoverQuantities;
