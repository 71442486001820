/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import styledComponent from '../../utils/styledComponent';
import {
  ItemsV2,
  SaveFinishedGoodRun,
  GetFinishedGoodRun,
  CreateFinishedGood,
} from '../../libs/GraphQL.js';
import { sortArrayOfObjects } from '../../libs/utility';
import FinishedGoodScanningRun from './FinishedGoodScanningRun';
import FinishedGoodScanningPaused from './FinishedGoodScanningPaused';
import { contextState, contextDispatch } from '../../ContextProvider';

const StyledSection = styledComponent('section', {
  '.mainHeading': {
    color: '#000000',
    fontSize: '28px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    letterSpacing: '0.21px',
  },
  '.subHeading': {
    marginTop: '10px',
    width: '677px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    letterSpacing: '0.14px',
    color: '#000000',
  },
  '.runConfiguration': {
    marginTop: '72px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    alignItems: 'flex-start',
    fontSize: '20px',
    fontFamily: 'Roboto',
    lineHeight: '36px',
    letterSpacing: '0.63px',

    select: {
      width: '520px',
      height: '64px',
      border: '2px solid #000000',
      borderRadius: '8px',
    },
  },
  '.product': {
    float: 'left',
  },
  '.line': {
    float: 'right',
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    alignItems: 'flex-start',

    input: {
      width: '170px',
      height: '64px',
      border: '2px solid #000000',
      borderRadius: '8px',
      fontSize: '20px',
      fontFamily: 'Roboto',

      '::placeholder': {
        fontSize: '14px',
      },
    },

    '#NumberOfLineWorkers': {
      float: 'left',
    },

    '#LineNumber': {
      float: 'right',
      marginLeft: '12px',
    },
  },
  '.startButton': {
    float: 'right',
    marginLeft: '12px',

    button: {
      width: '300px',
      height: '52px',
      borderRadius: '8px',
      backgroundColor: '#818387',
      color: '#ffffff',
      fontSize: '20px',
      fontFamily: 'Roboto',
      textAlign: 'center',
      marginTop: '12px',
      opacity: '0.7',

      ':hover': {
        opacity: '1',
        cursor: 'pointer',
      },

      ':active': {
        boxShadow: '0 5px',
        transform: 'translateY(4px)',
      },
    },
  },
  marginBottom: '50px',
  '#PausedRunDetailsTable': {
    marginTop: '130px',
    width: '100%',
    borderSpacing: '50px 12px',
    border: '0px',
    'td, th': {
      border: '0px',
      fontFamily: 'Roboto',
      color: '#000000',
      textAlign: 'left',
    },
    th: {
      fontSize: '18px',
      opacity: '0.54',
    },
    td: {
      fontSize: '16px',
      opacity: '1',
      letterSpacing: '0.63px',
      overflowWrap: 'break-word',
      backgroundColor: '#eeeeee',
      height: '67px',
    },
    '#PausedRunsTitle': {
      fontSize: '24px',
    },
    '#ResumeButton': {
      border: '0px',
      color: '#368007',
      fontSize: '18px',
      textDecoration: 'underline',
    },
  },
});

const FinishedGoodScanning = () => {
  const [items, setItems] = useState([]);
  const formFields = {
    ITEM_ID: 'itemId',
    ITEM_NAME: 'itemName',
    NUMBER_OF_LINE_WORKERS: 'numberOfLineWorkers',
    LINE_NUMBER: 'lineNumber',
  };
  const initialFormValues = {
    itemId: null,
    itemName: null,
    numberOfLineWorkers: null,
    lineNumber: null,
  };
  const formValues = useRef(initialFormValues);
  const { contextData } = contextState();
  const dispatch = contextDispatch();
  const currentRun = contextData.finishedGoods.activeRun;

  const shouldCreateBatchedFinishedGoods = () => {
    try {
      let finishedGoods = localStorage.getItem('scannedFinishedGoods')
        ? JSON.parse(localStorage.getItem('scannedFinishedGoods'))
        : [];
      console.log(finishedGoods);
      if (finishedGoods.length > 0) {
        const response = CreateFinishedGood(finishedGoods);
        console.log(response);
        localStorage.setItem('scannedFinishedGoods', []);
      }
    } catch (e) {
      console.log('unable to create finished goods, will retry next time');
    }
  };

  const getLiveItems = async () => {
    try {
      let liveItems = await ItemsV2({ status: ['LIVE'] });
      setItems(sortArrayOfObjects(liveItems.data.itemsV2, 'id', 'asc'));
    } catch (e) {
      console.log(e);
      toast.error('Failed to load the Items. Please try again');
    }
  };

  const getPausedRuns = async () => {
    try {
      let finishedGoodRuns = await GetFinishedGoodRun({ status: 'PAUSED' });
      dispatch({
        contextData: {
          ...contextData,
          finishedGoods: {
            ...contextData.finishedGoods,
            pausedRuns: finishedGoodRuns.data.getFinishedGoodRun,
          },
        },
        type: 'set',
      });
    } catch (e) {
      console.log(e);
      toast.error('Failed to load PAUSED runs');
    }
  };

  useEffect(() => {
    if (items.length === 0) {
      getLiveItems();
      getPausedRuns();
      localStorage.setItem('activeRunTime', 0);
    }
    // if there were pending finished goods not created before, create them now
    shouldCreateBatchedFinishedGoods();
  }, []);

  useEffect(() => {
    // when the status changed for an active run to one of the below statuses,
    // we unmount the FG scanning run component and go back to the home page. Thus,
    // we need to refetch paused runs to not show stale data
    let status = contextData.finishedGoods.activeRun.status;
    if (
      status === 'CANCELED' ||
      status === 'COMPLETED' ||
      status === 'PAUSED'
    ) {
      getPausedRuns();
    }
  }, [contextData.finishedGoods.activeRun.status]);

  const startRun = async () => {
    // we are starting a new run
    try {
      const currentSession = await Auth.currentAuthenticatedUser();
      const finishedGoodRun = {
        itemId: formValues.current.itemId,
        lineWorkers: formValues.current.numberOfLineWorkers,
        lineNumber: formValues.current.lineNumber,
        owner:
          currentSession && currentSession.signInUserSession
            ? currentSession.signInUserSession.idToken.payload.email
            : 'guest@farmersfridge.com',
        productionFacility: 'CHICAGO_1',
      };
      const response = SaveFinishedGoodRun(finishedGoodRun);
      response.then((json) => {
        dispatch({
          contextData: {
            ...contextData,
            finishedGoods: {
              ...contextData.finishedGoods,
              activeRun: {
                runId: json.data.saveFinishedGoodRun.runId,
                itemId: formValues.current.itemId,
                itemName: formValues.current.itemName,
                numberOfLineWorkers: formValues.current.numberOfLineWorkers,
                lineNumber: formValues.current.lineNumber,
                status: 'IN_PROGRESS',
              },
            },
          },
          type: 'set',
        });
        localStorage.setItem('activeRunTime', 0);
        localStorage.setItem('scannedFinishedGoods', []);
      });
    } catch (e) {
      console.log(e);
      toast.error('Unable to create run. Please try again later');
    }
  };

  const validateRunConfiguration = (event) => {
    event.preventDefault();
    var valid = true;
    if (
      !formValues.current.numberOfLineWorkers ||
      formValues.current.numberOfLineWorkers <= 0
    ) {
      valid = false;
      toast.error(
        'Number of line workers has to be a value greater than 0 - please fix and resubmit',
      );
    }
    if (!formValues.current.lineNumber || formValues.current.lineNumber < 0) {
      valid = false;
      toast.error(
        'Line number has to be a value greater than or equal to 0 - please fix and resubmit',
      );
    }
    if (!formValues.current.itemName || !formValues.current.itemId) {
      valid = false;
      toast.error(
        'A valid product SKU has to be selected from the drop down - please fix and resubmit',
      );
    }
    if (valid) {
      startRun();
    }
  };

  const handleFormChange = (event, field) => {
    event.preventDefault();
    const { value } = event.target;
    switch (field) {
      case formFields.ITEM_ID:
      case formFields.ITEM_NAME: {
        const { options, selectedIndex } = event.target;
        formValues.current.itemId = value;
        formValues.current.itemName = options[selectedIndex].text;
        break;
      }
      case formFields.NUMBER_OF_LINE_WORKERS: {
        formValues.current.numberOfLineWorkers = value;
        break;
      }
      case formFields.LINE_NUMBER: {
        formValues.current.lineNumber = value;
        break;
      }
    }
  };

  if (!currentRun.runId) {
    return (
      <StyledSection>
        <div className="mainHeading">Finished Goods Scanning</div>
        <div className="subHeading">
          Select a product and enter the number of line workers to start a
          production run. When the run is finished, select end run.
        </div>
        <form onSubmit={(event) => validateRunConfiguration(event)}>
          <div className="runConfiguration d-flex">
            <div className="product">
              <select
                id="ProductSelectTag"
                defaultValue={''}
                required
                onChange={(event) =>
                  handleFormChange(event, formFields.ITEM_ID)
                }
              >
                <option value="" disabled>
                  Select Product
                </option>
                {items.map((item) => {
                  let newOption = new Option(item.prettyName, item.id);
                  var selectTag = document.getElementById('ProductSelectTag');
                  if (selectTag) {
                    selectTag.appendChild(newOption);
                  }
                })}
              </select>
            </div>
            <div className="line">
              <input
                id="NumberOfLineWorkers"
                placeholder="Number of Line Workers"
                type="number"
                required
                onChange={(event) =>
                  handleFormChange(event, formFields.NUMBER_OF_LINE_WORKERS)
                }
              ></input>
              <input
                id="LineNumber"
                placeholder="Line Number"
                type="number"
                required
                onChange={(event) =>
                  handleFormChange(event, formFields.LINE_NUMBER)
                }
              ></input>
            </div>
            <div className="startButton">
              <button id="StartRun">START RUN</button>
            </div>
          </div>
        </form>
        {contextData.finishedGoods.pausedRuns.length > 0 && (
          <FinishedGoodScanningPaused items={items} />
        )}
      </StyledSection>
    );
  }
  if (currentRun.runId) {
    // run is going on
    return <FinishedGoodScanningRun />;
  }
};

export default FinishedGoodScanning;
