import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Button,
  ButtonToolbar,
  Modal,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { diff } from 'deep-object-diff';
import {
  EditOrderMutation,
  DeleteOrderMutation,
  SpecificOrder,
} from '../../libs/GraphQL';

import ConfirmDeleteOrderModal from './ConfirmDeleteOrderModal';

const columns = [
  {
    dataField: 'displayName',
    text: 'Item',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'quantity',
    text: 'Quantity',
    editor: {
      type: Type.TEXTAREA,
    },
    sort: true,
  },
  {
    dataField: 'productionDate',
    text: 'Production Date',
    filter: textFilter(),
    sort: true,
  },
];

/* eslint-disable react/prop-types,react/no-access-state-in-setstate */

export default class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.afterSaveCell = this.afterSaveCell.bind(this);
    this.confirmDeleteOrderModal = this.confirmDeleteOrderModal.bind(this);
    this.openDeleteOrderModal = this.openDeleteOrderModal.bind(this);
    this.closeDeleteOrderModal = this.closeDeleteOrderModal.bind(this);
    this.state = {
      order: {},
      alertModal: {
        mode: false,
        text: '',
      },
      confirmDeleteOrderModal: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const order = await SpecificOrder(
      window.location.pathname.split('/').slice(-1)[0],
    );
    this.setState({ isLoading: false });
    this.setState({ order: order.data.order });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.items.length !== nextProps.items.length) {
      return true;
    }
    const obj = diff(this.state, nextState);
    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
      return false;
    }

    return true;
  }

  confirmDeleteOrderModal(e) {
    this.setState({
      confirmDeleteOrderModal: false,
    });
    this.deleteOrder(e);
  }

  openDeleteOrderModal() {
    this.setState({
      confirmDeleteOrderModal: true,
    });
  }

  closeDeleteOrderModal() {
    this.setState({
      confirmDeleteOrderModal: false,
    });
  }

  async editOrder(e) {
    e.preventDefault();
    const newObj = diff(this.props.order, this.state.order);
    if (typeof newObj.itemsCount !== 'undefined') {
      newObj.itemsCount = this.state.order.itemsCount;
    }

    newObj.uniqueId = this.state.order.uniqueId;
    newObj.modifiedBy = 'SINGLE_ORDER_UPDATE';
    try {
      const response = await EditOrderMutation(newObj);
      this.setState({
        alertModal: {
          mode: true,
          text: `${response.data.editOrder.message}! Please refresh the page.`,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({
        alertModal: { mode: true, text: 'Error! Failed to update order' },
      });
    }
  }

  async deleteOrder(e) {
    e.preventDefault();
    try {
      const response = await DeleteOrderMutation(this.state.order.uniqueId);
      this.setState({
        alertModal: {
          mode: true,
          text: `${response.data.deleteOrder.message}. Please refresh the page.`,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({
        alertModal: { mode: true, text: 'Error! Failed to delete order' },
      });
    }
  }

  afterSaveCell(oldValue, newValue, row, column) {
    const order = JSON.parse(JSON.stringify(this.state.order));
    const index = order.itemsCount.map((e) => e.itemName).indexOf(row.id);
    if (index === -1 && column.dataField === 'quantity') {
      order.itemsCount.push({
        itemName: row.id,
        count: parseInt(newValue, 10),
      });
    } else if (column.dataField === 'quantity') {
      order.itemsCount[index].count = parseInt(newValue, 10);
    } else if (index !== -1 && column.dataField === 'productionDate') {
      order.itemsCount[index].productionDate = newValue;
    }
    this.setState({ order });
  }

  handleChange(e) {
    const order = JSON.parse(JSON.stringify(this.state.order));
    order[e.target.id] = e.target.value;
    this.setState({ order });
  }

  getValidationState(type) {
    if (['itemsCount', 'billingCode', 'itemsName'].includes(type) > 0) {
      return 'success';
    }
    if (type === 'deliveryDate') {
      // check that deliveryDate is on or before dropOffDate
      return moment(this.state.order.deliveryDate).isSameOrBefore(
        moment(this.state.order.dropOffDate),
      )
        ? 'success'
        : 'error';
    }
    if (type === 'dropOffDate') {
      // check that dropOffDate is on or after deliveryDate
      return moment(this.state.order.dropOffDate).isSameOrAfter(
        moment(this.state.order.deliveryDate),
      )
        ? 'success'
        : 'error';
    }
    if (this.state.order[type] == null) {
      return null;
    }
    if (type === undefined) {
      return null;
    }

    return null;
  }

  updateProductionDates(date) {
    // update productionDate of each item to be 1 day before deliveryDate, as per request from business
    const newItemsCount = [];
    this.state.order.itemsCount.forEach((itemCount) => {
      itemCount.productionDate = moment(date)
        .subtract(1, 'd')
        .format('YYYY-MM-DD');
      newItemsCount.push(itemCount);
    });
    return newItemsCount;
  }

  handleDeliveryDateChange(newDeliveryDate) {
    const newItemsCount = this.updateProductionDates(newDeliveryDate);
    this.setState({
      order: {
        ...this.state.order,
        deliveryDate: moment(newDeliveryDate).format('YYYY-MM-DD'),
        itemsCount: newItemsCount,
      },
    });
  }

  handleDropOffDateChange(newDropOffDate) {
    const newItemsCount = this.updateProductionDates(
      this.state.order.deliveryDate,
    );
    this.setState({
      order: {
        ...this.state.order,
        dropOffDate: moment(newDropOffDate).format('YYYY-MM-DD'),
        itemsCount: newItemsCount,
      },
    });
  }

  render() {
    if (this.state.isLoading) {
      return <h3>Loading...</h3>;
    }
    if (
      Object.entries(this.state.order).length === 0 &&
      this.state.order.constructor === Object
    ) {
      return null;
    }

    return (
      <div>
        <Modal show={this.state.alertModal.mode}>
          <Modal.Body>
            <div className="form-group">
              {JSON.stringify(this.state.alertModal.text)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            &nbsp;
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                const alertModal = this.state.alertModal;
                alertModal.mode = false;
                this.setState({ alertModal: { mode: false } });
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <ConfirmDeleteOrderModal
          show={this.state.confirmDeleteOrderModal}
          onConfirm={this.confirmDeleteOrderModal}
          onClose={this.closeDeleteOrderModal}
        />

        <Col md={12}>
          <div>
            <Form horizontal>
              <FormGroup controlId="uniqueId">
                <Col componentClass={ControlLabel} sm={2}>
                  Order ID
                </Col>
                <Col sm={10} style={{ paddingTop: 7 }}>
                  {this.state.order.uniqueId}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="orderOwner"
                validationState={this.getValidationState('orderOwner')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  Owner
                </Col>
                <Col sm={10} style={{ paddingTop: 7 }}>
                  {this.state.order.orderOwner}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="orderType"
                validationState={this.getValidationState('orderType')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  Order Type
                </Col>
                <Col sm={10}>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    value={this.state.order.orderType}
                    onChange={this.handleChange}
                  >
                    <option value="CUSTOMER">Retail</option>
                    <option value="FRIDGE_OTHERS">Fridge</option>
                    <option value="INTERNAL">Internal</option>
                  </FormControl>
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              {['CUSTOMER'].includes(this.state.order.orderType) && (
                <FormGroup
                  controlId="clientCode"
                  validationState={this.getValidationState('clientCode')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Client
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      value={this.state.order.clientCode}
                      onChange={this.handleChange}
                    >
                      <option value="Catering">Catering</option>
                      <option value="Wholesale">Wholesale</option>
                      <option value="Sales">Sales</option>
                      <option value="Marketing">Marketing</option>
                      <option value="QA">QA</option>
                      <option value="MenuRD">MenuRD</option>
                      <option value="CommunityEvents">CommunityEvents</option>
                      <option value="ExecutiveOffice">ExecutiveOffice</option>
                      <option value="LogisticsRD">LogisticsRD</option>
                      <option value="Other">Other</option>
                    </FormControl>
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
              )}
              {['Other'].includes(this.state.order.clientCode) && (
                <FormGroup
                  controlId="clientDetails"
                  validationState={this.getValidationState('clientDetails')}
                >
                  <Col componentClass={ControlLabel} sm={4}>
                    Client Details
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type="text"
                      value={this.state.order.clientDetails}
                      placeholder="Enter Name of Client"
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
              )}
              {['CUSTOMER'].includes(this.state.order.orderType) && (
                <FormGroup controlId="billingType">
                  <Col componentClass={ControlLabel} sm={2}>
                    Billing Type
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      value={this.state.order.billingType || ''}
                      onChange={this.handleChange}
                    >
                      <option value="COMP">Comped</option>
                      <option value="BILL">Bill Customer</option>
                      <option value="SOLDIER">SOLDIER</option>
                    </FormControl>
                  </Col>
                </FormGroup>
              )}
              <FormGroup
                controlId="locationId"
                validationState={this.getValidationState('locationId')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  Location ID
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    placeholder="select"
                    value={this.state.order.locationId || ''}
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              <FormGroup
                controlId="addressForDelivery"
                validationState={this.getValidationState('addressForDelivery')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  Delivery Address
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={
                      this.state.order.addressForDelivery ||
                      'Enter Notes for Kitchen'
                    }
                    onChange={this.handleChange}
                    maxLength="180"
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>

              {!['QA', 'DELAYED'].includes(this.state.order.orderType) && (
                <FormGroup
                  controlId="deliverySpecialNotes"
                  validationState={this.getValidationState(
                    'deliverySpecialNotes',
                  )}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Delivery Notes
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      type="text"
                      value={
                        this.state.order.deliverySpecialNotes ||
                        'Enter Notes for Driver'
                      }
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
              )}
              {['CUSTOMER'].includes(
                this.state.order.orderType && this.state.order.market,
              ) && (
                <FormGroup
                  controlId="market"
                  validationState={this.getValidationState('market')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Market
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      value={this.state.order.market}
                      onChange={this.handleChange}
                    >
                      <option value="Chicago">Chicago</option>
                      <option value="Milwaukee">Milwaukee</option>
                      <option value="Indianapolis">Indiana</option>
                    </FormControl>
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
              )}
              <FormGroup
                controlId="deliveryDate"
                validationState={this.getValidationState('deliveryDate')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  CMS Delivery Date
                </Col>
                {/* exclude shopify order dates from being edited so we avoid syncing issues */}
                {this.state.order.addressForDelivery &&
                this.state.order.addressForDelivery.includes('SHOPIFY') ? (
                  <Col sm={10}>
                    <p>{this.state.order.deliveryDate}</p>
                  </Col>
                ) : (
                  <Col sm={10}>
                    <DatePicker
                      onChange={(value) => {
                        this.handleDeliveryDateChange(value);
                      }}
                      value={moment(this.state.order.deliveryDate).toDate()}
                    />
                  </Col>
                )}
              </FormGroup>
              <FormGroup
                controlId="dropOffDate"
                validationState={this.getValidationState('dropOffDate')}
              >
                <Col componentClass={ControlLabel} sm={2}>
                  Drop Off Date
                </Col>
                {/* exclude shopify order dates from being edited so we avoid syncing issues OR
                exclude the date picker if there is no drop off date on the order itself */}
                {(this.state.order.addressForDelivery &&
                  this.state.order.addressForDelivery.includes('SHOPIFY')) ||
                !this.state.order.dropOffDate ? (
                  <Col sm={10}>
                    <p>{this.state.order.dropOffDate}</p>
                  </Col>
                ) : (
                  <Col sm={10}>
                    <DatePicker
                      onChange={(value) => {
                        this.handleDropOffDateChange(value);
                      }}
                      value={moment(this.state.order.dropOffDate).toDate()}
                    />
                  </Col>
                )}
              </FormGroup>
              <FormGroup controlId="pickPackDate">
                <Col componentClass={ControlLabel} sm={2}>
                  Pick Pack Date
                </Col>
                <Col sm={10} style={{ paddingTop: 7 }}>
                  {moment(this.state.order.deliveryDate)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD')}
                </Col>
              </FormGroup>
              {this.state.order.productionDate && (
                <FormGroup
                  controlId="productionDate"
                  validationState={this.getValidationState('productionDate')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Production Date
                  </Col>
                  <Col sm={10}>
                    <p>{this.state.order.productionDate}</p>
                  </Col>
                </FormGroup>
              )}
            </Form>
          </div>
        </Col>
        <Col md={12}>
          <BootstrapTable
            keyField="id"
            data={this.props.items.map((item) => {
              return {
                id: item.id,
                displayName: item.id,
                quantity:
                  this.state.order.itemsCount.find(
                    (o) => o.itemName === item.id,
                  ) === undefined
                    ? 0
                    : this.state.order.itemsCount.find(
                        (o) => o.itemName === item.id,
                      ).count,
                productionDate:
                  // eslint-disable-next-line no-nested-ternary
                  this.state.order.itemsCount.find(
                    (o) => o.itemName === item.id,
                  ) === undefined
                    ? 'Not Set'
                    : this.state.order.itemsCount.find(
                        (o) => o.itemName === item.id,
                      ).productionDate === undefined
                    ? 'Not Set'
                    : this.state.order.itemsCount.find(
                        (o) => o.itemName === item.id,
                      ).productionDate,
              };
            })}
            columns={columns}
            filter={filterFactory()}
            cellEdit={cellEditFactory({
              mode: 'click',
              autoSelectText: true,
              blurToSave: true,
              afterSaveCell: this.afterSaveCell,
            })}
            pagination={paginationFactory()}
          />
        </Col>
        <Form>
          <FormGroup>
            <Col smOffset={2} sm={10}>
              <ButtonToolbar>
                {true && (
                  <Button
                    onClick={this.openDeleteOrderModal}
                    bsStyle="danger"
                    type="button"
                  >
                    Delete Order
                  </Button>
                )}
                <Button
                  onClick={this.editOrder}
                  bsStyle="primary"
                  type="button"
                >
                  Save Changes
                </Button>
              </ButtonToolbar>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
