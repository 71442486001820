import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../utils/styledComponent';

const StyledNav = styledComponent('nav', {
  '& li': {
    listStyle: 'none',
  },
  '& ul': {
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  backgroundColor: '#E9F6E0',
  borderRadius: '4px',
  border: '1px solid #d9ecd4',
  marginBottom: '20px',
});

const PageNav = ({ children, ...otherProps }) => {
  return (
    <StyledNav {...otherProps}>
      <ul>{children}</ul>
    </StyledNav>
  );
};
PageNav.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageNav;
