import React, { Component } from 'react';

export default class Error extends Component {
  render() {
    return (
      <div className="Home" style={{ padding: '80px 0', textAlign: 'center' }}>
        <div className="lander">
          <h1
            style={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center' }}
          >
            Error
          </h1>
          <p style={{ color: '#999' }}>
            An error occurred. Please report to the Technology team
          </p>
        </div>
      </div>
    );
  }
}
