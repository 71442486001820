import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { OrderMeta, DeleteOrderMutation } from '../../libs/GraphQL';
import {
  Button,
  Row,
  Form,
  FormGroup,
  ControlLabel,
  Col,
} from 'react-bootstrap';
import { contextState } from '../../ContextProvider';

const ListOrder = ({ history }) => {
  const [orders, setOrders] = useState([]);
  const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [loadDates, setLoadDates] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [results, setResults] = useState([]);
  const { contextData } = contextState();

  useEffect(() => {
    // Checking if the date is being changed
    let days = enumerateDaysBetweenDates(
      dateRange.startDate,
      dateRange.endDate,
    );
    // if a user only selects one day
    if (
      moment(dateRange.startDate).format('YYYY-MM-DD') ===
      moment(dateRange.endDate).format('YYYY-MM-DD')
    ) {
      days = [moment(dateRange.startDate).startOf('day').toDate()];
    }
    if (loadDates && days.length) {
      getRangeOfData(days);
    }
  }, [checkedOrders, dateRange, loadDates]);

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setCheckedOrders([...checkedOrders, row.uniqueId]);
    } else {
      const updatedList = checkedOrders;
      const itemIndex = updatedList.indexOf(row.uniqueId);
      if (itemIndex > -1) {
        updatedList.splice(itemIndex, 1);
      }
      setCheckedOrders([...updatedList]);
    }
  };

  const handleSelectAll = (isSelectAll, rows) => {
    if (isSelectAll) {
      let updatedList = [];
      rows.forEach((order) => {
        updatedList.push(order.uniqueId);
      });
      setCheckedOrders(updatedList);
    } else {
      setCheckedOrders([]);
    }
  };

  const renderUniqueLocationId = (cell, row) => {
    return (
      <Link target="_blank" to={'/orders/order_id/' + row.uniqueId}>
        {cell}
      </Link>
    );
  };

  const enumerateDaysBetweenDates = function (startDate, endDate) {
    let dates = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    dates.push(moment(dateRange.startDate).startOf('day').toDate());
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    dates.push(moment(dateRange.endDate).startOf('day').toDate());
    return dates;
  };

  const getRangeOfData = async (days) => {
    const daysCount = Object.keys(days).length;
    setIsOrdersLoading(true);
    let orderValues = [];
    const orderPromises = [];
    for (const day of days) {
      orderPromises.push(
        OrderMeta(null, null, moment(day).format('YYYY-MM-DD')),
      );
    }
    const fetchOrder = (nextToken, date) => {
      OrderMeta(nextToken, null, date).then((res) => {
        setOrders((orders) => [...orders, ...res.data.ordersByDay.orders]);
        setResults((orders) => [...orders, ...res.data.ordersByDay.orders]);
        if (res.data.ordersByDay.nextToken !== null) {
          fetchOrder(
            res.data.ordersByDay.nextToken,
            res.data.ordersByDay.orders[0].deliveryDate,
          );
        }
      });
    };

    Promise.all(orderPromises).then((values) => {
      values.forEach((val) => {
        if (val.data.ordersByDay.nextToken !== null) {
          fetchOrder(
            val.data.ordersByDay.nextToken,
            val.data.ordersByDay.orders[0].deliveryDate,
          );
        }
        orderValues.push(...val.data.ordersByDay.orders);
      });
      setIsOrdersLoading(false);
      setLoadDates(false);
      setOrders((orders) => [...orders, ...orderValues]);
      setResults((orders) => [...orders, ...orderValues]);
    });
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <br />
          <Form inline>
            <FormGroup controlId="searchBar">
              <ControlLabel>CMS Delivery Date Selection</ControlLabel> <br />
              <div data-testid="searchDate">
                <DateRangePicker
                  startDate={dateRange.startDate}
                  startDateId="dateRangeStart"
                  endDate={dateRange.endDate}
                  endDateId="dateRangeEnd"
                  isOutsideRange={() => false}
                  onDatesChange={({ startDate, endDate }) => {
                    setDateRange({ startDate, endDate });
                  }}
                  minimumNights={0}
                  focusedInput={dateRangeFocusedInput}
                  onFocusChange={(focusedInput) => {
                    setDateRangeFocusedInput(focusedInput);
                  }}
                />
                <button
                  disabled={isOrdersLoading}
                  data-testid="btnChangeDate"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoadDates(true);
                    setCheckedOrders([]);
                    setOrders([]);
                    setResults([]);
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  Load
                </button>
              </div>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      {isOrdersLoading && <div>Loading</div>}
      {orders && orders.length === 0 && !isOrdersLoading && (
        <div>No Results</div>
      )}
      {orders && orders.length > 0 && !isOrdersLoading && (
        <div>
          <div style={{ display: 'flex' }}>
            {checkedOrders.length > 0 &&
              contextData.features.canDeleteOrders === true && (
                <div>
                  <ControlLabel>Actions</ControlLabel>
                  <br />
                  <Button
                    bsStyle="warning"
                    data-testid="btnDeleteOrders"
                    onClick={() => {
                      const confirmRes = window.confirm(
                        "You're about to delete orders",
                      );
                      if (confirmRes) {
                        let deletionPromises = [];
                        for (const order of checkedOrders) {
                          try {
                            deletionPromises.push(DeleteOrderMutation(order));
                          } catch (error) {
                            toast.error(error.message);
                            console.error(error);
                          }
                        }
                        // This will attempt to wait until all requests complete, or an error occurs.
                        // If an error occurs completion of non-error requests is not guaranteed.
                        Promise.all(deletionPromises)
                          .catch((error) => {
                            console.log('caught error');
                            window.alert(
                              `Encountered "${error.message}" deleting one or more orders. Please retry the operation.`,
                            );
                          })
                          .then(() => {
                            setCheckedOrders([]);
                            history.go(0);
                            history.push(`/orders/list`);
                          });
                      }
                    }}
                  >
                    Delete Selected Orders
                  </Button>
                </div>
              )}
          </div>
          <br />
          <span style={{ fontWeight: 'bold' }}>
            Order ({checkedOrders.length} selected out of {results.length})
          </span>
          <Row style={{ overflowX: 'auto' }}>
            <Col sm={12}>
              <BootstrapTable
                data-testid="orderListTable"
                keyField="uniqueId"
                data={results.map((order) => {
                  let uniqueLocationId = order.deliveryDate + '-';
                  if (order.locationId) {
                    uniqueLocationId += order.locationId;
                  } else if (order.addressForDelivery) {
                    uniqueLocationId += order.addressForDelivery;
                  } else {
                    uniqueLocationId += order.orderType;
                  }
                  let newOrder = {
                    uniqueId: order.uniqueId,
                    owner: order.orderOwner || 'internal',
                    dropOffDate: order.dropOffDate,
                    uniqueLocationId: uniqueLocationId,
                    deliverySpecialNotes: order.deliverySpecialNotes,
                    orderUploadId: order.orderUploadId,
                  };
                  return newOrder;
                })}
                columns={[
                  {
                    text: 'Order',
                    filter: textFilter(),
                    dataField: 'uniqueLocationId',
                    formatter: renderUniqueLocationId,
                  },
                  {
                    text: 'Upload Id',
                    filter: textFilter(),
                    dataField: 'orderUploadId',
                  },
                  {
                    text: 'Owner',
                    filter: textFilter(),
                    dataField: 'owner',
                  },
                  {
                    text: 'Drop Off Date',
                    filter: textFilter(),
                    dataField: 'dropOffDate',
                    headerStyle: { width: '125px' },
                  },
                  {
                    text: 'Delivery Special Notes',
                    filter: textFilter(),
                    dataField: 'deliverySpecialNotes',
                  },
                ]}
                filter={filterFactory()}
                selectRow={{
                  mode: 'checkbox',
                  onSelect: handleSelect,
                  onSelectAll: handleSelectAll,
                  selected: checkedOrders,
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ListOrder;
