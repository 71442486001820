import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PackingSlipsV2 } from '../libs/GraphQL.js';
import { Table } from 'react-bootstrap';

let textInput = React.createRef();

const PickToLight = () => {
  const [packingSlipData, setPackingSlipData] = useState([]);

  const handlePickToLightClick = async () => {
    try {
      const packingSlipData = await PackingSlipsV2({
        qrCode: textInput.current.value,
        zone: '2', // default to zone 2 for testing purposes
      });
      setPackingSlipData(packingSlipData.data.packingSlipsV2.data);
    } catch (error) {
      console.log(error);
      alert('We had issue with sending the qr code.');
    }
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h3>Pick to Light Testing</h3>
      <div>
        <input type="text" ref={textInput} />
        <br />
        <Button onClick={handlePickToLightClick}> Send </Button>
      </div>
      {packingSlipData.length !== 0 && (
        <Table style={{ marginTop: '50px' }}>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Device ID</th>
              <th>Zone</th>
            </tr>
          </thead>
          <tbody>
            {packingSlipData.map((obj) => (
              <tr>
                <td>{obj.itemName}</td>
                <td>{obj.value}</td>
                <td>{obj.deviceId}</td>
                <td>{obj.zone}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PickToLight;
