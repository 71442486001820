import React from 'react';
import './ConfirmationModal.css';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({
  title = 'Confirm your action',
  children,
  show,
  onHide,
  onConfirm,
  loading = false,
}) => {
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
    >
      <Modal.Header closeButton onHide={loading ? undefined : onHide}>
        <Modal.Title id="contained-modal-title-vcenter" className="titleText">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="bodyText">{children}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} disabled={loading}>
          Close
        </Button>
        <Button onClick={onConfirm} className="btn-success" disabled={loading}>
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
