import React, { useEffect, useState, useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import styledComponent from '../../utils/styledComponent';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  ItemProductionCount,
  CreateFinishedGood,
  SaveFinishedGoodRun,
} from '../../libs/GraphQL.js';
import FinishedGoodConfirmModal from '../../atoms/FinishedGoodConfirmModal';
import FinishedGoodRunDetails from './FinishedGoodRunDetails';
import { contextState, contextDispatch } from '../../ContextProvider';

const StyledSection = styledComponent('section', {
  '.mainHeading': {
    color: '#000000',
    fontSize: '28px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    letterSpacing: '0.21px',
  },
  '.button': {
    ':hover': {
      cursor: 'pointer',
    },
    ':active': {
      boxShadow: '0 5px',
      transform: 'translateY(4px)',
    },
  },
  '#CancelButton': {
    fontFamily: 'Roboto',
    fontSize: '18px',
    letterSpacing: '1.69px',
    textDecoration: 'underline',
    color: '#d0021b',
    marginLeft: '900px',
    marginTop: '-24px',
    fontWeight: 'bold',
  },
  '#RunDetailsTable': {
    marginTop: '40px',
    width: '100%',
    borderSpacing: '50px 12px',
    border: '0px',
    'td, th': {
      border: '0px',
      fontFamily: 'Roboto',
      color: '#000000',
      maxWidth: '200px',
      textAlign: 'left',
      verticalAlign: 'top',
    },
    th: {
      fontSize: '16px',
      opacity: '0.54',
    },
    td: {
      fontSize: '20px',
      opacity: '1',
      letterSpacing: '0.63px',
      overflowWrap: 'break-word',
    },
  },
  '.itemDetails': {
    width: '450px',
    height: '198px',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    backgroundColor: '#eeeeee',
    textAlign: 'center',
  },
  '#Produced': {
    float: 'left',
    width: '225px',
    height: '198px',
    '.numberOfSkus': {
      color: '#368007',
      marginLeft: '36px',
    },
    '.title': {
      marginLeft: '36px',
    },
  },
  '#Planned': {
    float: 'right',
    width: '225px',
    height: '198px',
    '.numberOfSkus': {
      color: '#000000',
      opacity: '0.54',
      marginRight: '36px',
      marginLeft: '35px',
    },
    '.title': {
      marginRight: '36px',
      marginLeft: '35px',
    },
  },
  '#Separator': {
    width: '2px',
    height: '128px',
    backgroundColor: '#818387',
    opacity: '0.24',
    marginTop: '35px',
  },
  '.numberOfSkus': {
    height: '57px',
    width: '153px',
    marginTop: '59px',
    fontFamily: 'Roboto',
    fontSize: '48px',
  },
  '.title': {
    height: '16px',
    width: '153px',
    marginTop: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.54',
  },
  '.timer': {
    width: '450px',
    height: '45px',
    textAlign: 'center',
    paddingTop: '11px',
    fontFamily: 'Roboto',
    fontSize: '20px',
    backgroundColor: '#d8d8d8',
    color: '#000000',
    opacity: '0.54',
  },
  '.footer': {
    marginTop: '144px',
    marginLeft: '520px',
    width: '478px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  '#PauseButton': {
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '18px',
    letterSpacing: '1.69px',
    color: '#368007',
    marginTop: '35px',
    textDecoration: 'underline',
  },
  '#EndButton': {
    float: 'right',
    width: '307px',
    height: '52px',
    fontFamily: 'Roboto',
    fontSize: '20px',
    letterSpacing: '1.88px',
    backgroundColor: '#000000',
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: '52px',
  },
  '#ConfirmModal': {
    width: '100%',
    height: '900px',
  },
  marginBottom: '50px',
});

const FinishedGoodScanningRun = () => {
  const { contextData } = contextState();
  const dispatch = contextDispatch();
  const currentRun = contextData.finishedGoods.activeRun;
  const [amountPlanned, setAmountPlanned] = useState(0);
  const [amountProduced, setAmountProduced] = useState(0);
  const timer = useRef(null);
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');
  const [QRCode, setQRCode] = useState('');
  const scannedInput = useRef('');
  const scanErrors = useRef([]);
  const pauseKeyDownHandler = useRef(false);

  const loadProductionCounts = async (itemId) => {
    ItemProductionCount(itemId, moment().format('YYYY-MM-DD'))
      .then((res) => {
        // display response
        let productionCounts = res.data.getItemDailyProductionCount;
        setAmountProduced(productionCounts.amountProduced);
        setAmountPlanned(productionCounts.amountToBeProduced);
      })
      .catch((err) => {
        if (err.errors) {
          err.errors.forEach((error) => {
            toast.error(error.message);
          });
        } else {
          console.log(err);
          toast.error(
            'Unable to load production counts - Please try again later',
          );
        }
      });
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      let currentTime = parseInt(localStorage.getItem('activeRunTime'), 10);
      currentTime++;
      localStorage.setItem('activeRunTime', currentTime);
      let minutes = Math.floor(currentTime / 60);
      let seconds = currentTime - minutes * 60;
      seconds < 10 ? setTimerSeconds('0' + seconds) : setTimerSeconds(seconds);
      minutes < 10 ? setTimerMinutes('0' + minutes) : setTimerMinutes(minutes);
    }, 1000);
  };

  const keyDownHandler = (event) => {
    // ignore control keys other than 'Enter'
    const ignoreKeys = ['Shift', 'Tab', 'Meta', 'Backspace']; // only 'Shift' may occur in scanned QR code
    const targetKey = 'Enter';
    if (!pauseKeyDownHandler.current && !ignoreKeys.includes(event.key)) {
      if (event.key === targetKey) {
        setQRCode(scannedInput.current);
        scannedInput.current = '';
      } else {
        scannedInput.current += event.key;
      }
    }
  };

  window.onpopstate = function (event) {
    // on back click - let user know that their run was automatically paused
    // TODO: can we intercept this event and preemptively show a warning modal?
    toast.info(
      'Your run was automatically paused and you will be able to resume later',
    );
  };

  useEffect(() => {
    console.log('FG Run component loaded');
    // get production count of items
    loadProductionCounts(currentRun.itemId);
    // add event listener for scanning
    document.addEventListener('keydown', keyDownHandler, true);
    // make sure scans are not paused
    pauseKeyDownHandler.current = false;
    // start run timer
    startTimer();

    return () => {
      console.log('FG Run component unmounted');
      clearInterval(timer.current);
      timer.current = null;
      document.removeEventListener('keydown', keyDownHandler, true);
      try {
        // create pending finished goods
        CreateFinishedGood(
          JSON.parse(localStorage.getItem('scannedFinishedGoods')),
        );
        localStorage.setItem('scannedFinishedGoods', []);
        // automatically pause run
        SaveFinishedGoodRun({
          runId: contextData.finishedGoods.activeRun.runId,
          activeRunTime: parseInt(localStorage.getItem('activeRunTime'), 10),
          status: 'PAUSED',
        });
      } catch (e) {
        console.log(e);
      }
      // set run details in context to default
      dispatch({
        contextData: {
          ...contextData,
          finishedGoods: {
            ...contextData.finishedGoods,
            activeRun: {
              runId: null,
              itemId: '',
              itemName: '',
              numberOfLineWorkers: 0,
              lineNumber: 0,
              status: 'IN_PROGRESS',
            },
          },
        },
        type: 'set',
      });
      localStorage.setItem('activeRunTime', 0);
    };
  }, []);

  useBeforeunload((event) => {
    event.preventDefault();
    // NOTE: any scan here is same as a key press and will be treated as confirmation to reload a page)
    // we cannot pause keyboard inputs at this point since UI call stack is blocked

    try {
      // create pending finished goods
      CreateFinishedGood(
        JSON.parse(localStorage.getItem('scannedFinishedGoods')),
      );
      localStorage.setItem('scannedFinishedGoods', []);
      // pause run until user confirms
      SaveFinishedGoodRun({
        runId: contextData.finishedGoods.activeRun.runId,
        activeRunTime: parseInt(localStorage.getItem('activeRunTime'), 10),
        status: 'PAUSED',
      });
    } catch (e) {
      console.log(e);
    }

    /* There is no straightforward way to determine whether user clicked cancel or reload on the confirmation dialog box.
      A trick adopted is to use setTimeout functions within beforeunload handlers.

      Explanation from a stack-overflow thread:
      "
        The code within the first setTimeout method has a delay of 1ms.
        This is just to add the function into the UI queue.
        Since setTimeout runs asynchronously the Javascript interpreter will continue directly by triggering the browsers modal dialog.
        This will block the UI queue and the code from the first setTimeout is not executed, until the modal is closed.
        If the user pressed cancel, it will trigger another setTimeout which fires in about one second.
        If the user confirmed with ok, the user will redirect and the second setTimeout is never fired.
      "
     */
    setTimeout(function () {
      setTimeout(function () {
        console.log('stayed on this page');
        // resume run
        SaveFinishedGoodRun({
          runId: contextData.finishedGoods.activeRun.runId,
          status: 'IN_PROGRESS',
        });
      }, 1000);
    }, 1);
  });

  const handleEnd = (event) => {
    shouldCreateBatchedFinishedGoods();
    dispatch({
      contextData: {
        ...contextData,
        finishedGoods: {
          ...contextData.finishedGoods,
          activeRun: {
            ...contextData.finishedGoods.activeRun,
            status: 'SHOW_END_MODAL',
          },
        },
      },
      type: 'set',
    });
  };

  const handleCancel = (event) => {
    // in any case, create batched finished goods
    shouldCreateBatchedFinishedGoods();
    dispatch({
      contextData: {
        ...contextData,
        finishedGoods: {
          ...contextData.finishedGoods,
          activeRun: {
            ...contextData.finishedGoods.activeRun,
            status: 'SHOW_CANCEL_MODAL',
          },
        },
      },
      type: 'set',
    });
  };

  const handlePause = (event) => {
    // in any case, create batched finished goods
    shouldCreateBatchedFinishedGoods();
    dispatch({
      contextData: {
        ...contextData,
        finishedGoods: {
          ...contextData.finishedGoods,
          activeRun: {
            ...contextData.finishedGoods.activeRun,
            status: 'SHOW_PAUSE_MODAL',
          },
        },
      },
      type: 'set',
    });
  };

  useEffect(() => {
    // adding this if condition to prevent first render and accidentally showing
    // amount produced to be 1 for a brief second
    if (QRCode !== '') {
      // the scanned QR code will be of the format https://www.farmersfridge.com/myproduct?id=UUID
      // we need the UUID
      if (QRCode.split('/p/').length !== 2) {
        scanErrors.current.push(QRCode);
      } else {
        var tsFormat = 'YYYY-MM-DDTHH:mm:ss';
        let finishedGoods = localStorage.getItem('scannedFinishedGoods')
          ? JSON.parse(localStorage.getItem('scannedFinishedGoods'))
          : [];
        finishedGoods.push({
          uniqueId: QRCode.split('/p/')[1],
          itemId: currentRun.itemId,
          scannedTimestamp: moment(new Date()).format(tsFormat) + 'Z',
          runId: currentRun.runId,
        });
        localStorage.setItem(
          'scannedFinishedGoods',
          JSON.stringify(finishedGoods),
        );
        // caching up to 9 finished goods
        if (finishedGoods.length > 9) {
          shouldCreateBatchedFinishedGoods();
        }
        // each complete QR code scanned indicates a finished good produced
        // update count on the UI
        setAmountProduced(amountProduced + 1);
      }
    }
  }, [QRCode]);

  const shouldCreateBatchedFinishedGoods = () => {
    try {
      let finishedGoods = localStorage.getItem('scannedFinishedGoods')
        ? JSON.parse(localStorage.getItem('scannedFinishedGoods'))
        : [];
      console.log(finishedGoods);
      if (finishedGoods.length > 0) {
        const response = CreateFinishedGood(finishedGoods);
        console.log(response);
        localStorage.setItem('scannedFinishedGoods', []);
      }
    } catch (e) {
      console.log('unable to create finished goods, will retry next time');
    }
  };

  return (
    <StyledSection>
      {currentRun.status === 'SHOW_PAUSE_MODAL' && (
        <div id="ConfirmModal">
          <FinishedGoodConfirmModal
            action="pause"
            title="Are you sure you want to pause the run?"
            details="All data will be saved and you will be able to resume your run."
            cancel="No, go back"
            proceed="Yes, pause run"
          />
        </div>
      )}
      {currentRun.status === 'SHOW_END_MODAL' && (
        <div id="ConfirmModal">
          <FinishedGoodConfirmModal
            action="end"
            title="Are you sure you want to end the run?"
            details="Ending the run will log the following line data:"
            extraDetails={
              <FinishedGoodRunDetails amountProduced={amountProduced} />
            }
            cancel="No, go back"
            proceed="Yes, end run"
          />
        </div>
      )}
      {currentRun.status === 'SHOW_CANCEL_MODAL' && (
        <div id="ConfirmModal">
          <FinishedGoodConfirmModal
            action="cancel"
            title="Are you sure you want to cancel the run?"
            details="This cancellation cannot be reverted and all data will be lost."
            cancel="No, go back"
            proceed="Yes, cancel run"
          />
        </div>
      )}
      {currentRun.status === 'IN_PROGRESS' && (
        <div>
          <div className="mainHeading">Finished Goods Scanning</div>
          <div
            id="CancelButton"
            className="button"
            onClick={(event) => handleCancel(event)}
          >
            Cancel Run
          </div>
          <table id="RunDetailsTable">
            <tr>
              <th>Product</th>
              <th>Number of line workers</th>
              <th>Line number</th>
            </tr>
            <tr>
              <td>{currentRun.itemName}</td>
              <td>{currentRun.numberOfLineWorkers}</td>
              <td>{currentRun.lineNumber}</td>
            </tr>
          </table>
          <div className="itemDetails">
            <div id="Produced">
              <div className="numberOfSkus" id="NumberProduced">
                {amountProduced}
              </div>
              <div className="title">Produced</div>
            </div>
            <div id="Separator" />
            <div id="Planned">
              <div className="numberOfSkus" id="NumberPlanned">
                {amountPlanned}
              </div>
              <div className="title">Planned</div>
            </div>
          </div>
          <div className="timer">
            Time Active: {timerMinutes}:{timerSeconds}
          </div>
          <div className="footer">
            <div
              id="PauseButton"
              className="button"
              onClick={(event) => handlePause(event)}
            >
              Pause Run
            </div>
            <div
              id="EndButton"
              className="button"
              onClick={(event) => handleEnd(event)}
            >
              END RUN
            </div>
          </div>
        </div>
      )}
    </StyledSection>
  );
};

export default FinishedGoodScanningRun;
