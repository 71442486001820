import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import './SingleStopTable.css';

// eslint-disable react/prop-types

export default class SingleStopTable extends Component {
  constructor(props) {
    super(props);
    this.zoneitems = this.zoneitems.bind(this);
    this.getZoneData = this.getZoneData.bind(this);
  }

  zoneitems(data, zone) {
    return Object.keys(data[zone]).reduce(
      (acc, currentValue) => acc + Math.max(1, data[zone][currentValue].length),
      0,
    );
  }

  getZoneData(LAYOUT_loc) {
    const zoneData = {};
    for (var zone in LAYOUT_loc) {
      zoneData[zone] = {};
      zoneData[zone].bays = [];
      zoneData[zone].rowSpan = 0;
    }
    for (zone in LAYOUT_loc) {
      let zoneEmpty = true;
      for (const bay in LAYOUT_loc[zone]) {
        if (LAYOUT_loc[zone][bay].length > 0) {
          // zone has at least one bay with items in it
          zoneEmpty = false;
          const bayData = {};
          bayData[bay] = LAYOUT_loc[zone][bay];
          zoneData[zone].rowSpan =
            zoneData[zone].rowSpan + LAYOUT_loc[zone][bay].length;
          zoneData[zone].bays.push(bayData);
        }
      }
      if (zoneEmpty) {
        delete zoneData[zone];
      }
    }

    return zoneData;
  }

  render() {
    let LAYOUT_loc = this.props.layout;
    const newItems = Object.keys(this.props.data).filter(
      (x) => this.props.data[x].value > 0,
    );
    const itemLocation = Object.keys(LAYOUT_loc).reduce(function (
      accumulator,
      currentValue,
    ) {
      if (!accumulator[currentValue]) {
        accumulator[currentValue] = {};
      }
      accumulator[currentValue].A = LAYOUT_loc[currentValue].A.filter((x) =>
        newItems.includes(x),
      );
      accumulator[currentValue].B = LAYOUT_loc[currentValue].B.filter((x) =>
        newItems.includes(x),
      );
      return accumulator;
    },
    {});
    const allItems = Object.keys(LAYOUT_loc).reduce(function (
      accumulator,
      currentValue,
    ) {
      return accumulator
        .concat(LAYOUT_loc[currentValue].A)
        .concat(LAYOUT_loc[currentValue].B);
    },
    []);
    const diff = Object.keys(this.props.data)
      .filter((x) => !allItems.includes(x))
      .filter((x) => !allItems.includes(x));
    LAYOUT_loc = JSON.parse(JSON.stringify(itemLocation));
    if (diff.length > 0) {
      LAYOUT_loc.other = { A: [], B: diff };
    }

    const headers = [
      <tr key="header-table">
        <th key="Zone">Zone</th>
        <th key="Bay">Bay</th>
        <th key="Item">Item</th>
        <th key="Need">Need</th>
      </tr>,
    ];
    let rows = [];
    const zoneData = this.getZoneData(LAYOUT_loc);
    if (Object.keys(zoneData).length > 0) {
      rows = Object.keys(zoneData).map((zone) => (
        <tbody
          style={{ borderBottomWidth: 'thick', borderBottomColor: 'black' }}
          key={zone}
        >
          {zoneData[zone].bays.map((bayData, bayIndex) =>
            Object.keys(bayData).map((bay) => {
              return bayData[bay].map((sku, index) => (
                <tr key={sku}>
                  {bayIndex === 0 && index === 0 && (
                    <td
                      style={{ verticalAlign: 'middle' }}
                      className="text-center zoneCell"
                      rowSpan={zoneData[zone].rowSpan}
                    >
                      {zone}
                    </td>
                  )}
                  {index === 0 && (
                    <td
                      style={{ verticalAlign: 'middle' }}
                      className="text-center bayCell"
                      rowSpan={bayData[bay].length}
                    >
                      {bay}
                    </td>
                  )}
                  <td style={{ verticalAlign: 'middle' }} className="nameCell">
                    {this.props.items.hasOwnProperty(sku)
                      ? this.props.items[sku].id
                      : sku}
                  </td>
                  <td className="bigger quantityCell">
                    <div>{this.props.data[sku].value}</div>
                    {this.props.data[sku].unitsPerPack > 1 && (
                      <div className="small">
                        (2PK:{' '}
                        {this.props.data[sku].value /
                          this.props.data[sku].unitsPerPack}
                        )
                      </div>
                    )}
                  </td>
                </tr>
              ));
            }),
          )}
        </tbody>
      ));
    } else {
      rows = (
        <tr key="NO-Data">
          <td key="NO-Data"> No Data for this date</td>
        </tr>
      );
    }

    return (
      <div className="packingSlip" id={this.props.psId}>
        <Table responsive condensed>
          <thead>{headers}</thead>
          {rows}
          <tbody>
            <tr>
              {this.props.forks || this.props.spoons ? (
                <>
                  <td
                    rowSpan="2"
                    style={{ verticalAlign: 'middle' }}
                    className="text-center"
                  />
                  <td
                    rowSpan="2"
                    style={{ verticalAlign: 'middle' }}
                    className="text-center"
                  />
                </>
              ) : (
                ''
              )}
              {this.props.forks && (
                <>
                  <td>FORKS</td>
                  <td>{this.props.forks || 0}</td>
                </>
              )}
            </tr>
            <tr>
              {this.props.spoons && (
                <>
                  <td>SPOONS</td>
                  <td>{this.props.spoons || 0}</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
        <p style={{ pageBreakBefore: 'always' }} />
      </div>
    );
  }
}
