import React, { Component } from 'react';
import LoaderButton from '../LoaderButton';
import { EditOrderMutation, Items, Order } from '../../libs/GraphQL';
import { yyyymmdd } from '../../libs/date_convert';
import { FormGroup, FormControl, Grid, Modal, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BootstrapTable from 'react-bootstrap-table-next';
import { uploadFileToS3 } from '../../libs/uploadToS3';

const columns = [
  {
    dataField: 'itemName',
    text: 'Item Name',
  },
  {
    dataField: 'prodDate',
    text: 'Production Date',
  },
  {
    dataField: 'deliveryDate',
    text: 'Delivery Date',
  },
  {
    dataField: 'diff',
    text: 'Days Between',
  },
];
function dateDiff(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24),
  );
}

export default class UploadProductionDate extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      currentDelStartDate: new Date(),
      currentDelEndDate: new Date(),
      searchDelStartDate: yyyymmdd(null, '-'),
      searchDelEndDate: yyyymmdd(null, '-'),
      items: [],
      locations: [],
      isLoading: false,
      alertModal: {
        text: '',
        mode: false,
      },
      editedOrders: [],
      toDisplay: [],
      showPicker: true,
      content: '',
      uploadURL: '',
      file: '',
      fileContents: '',
    };
    this.handleDelStartDateChange = this.handleDelStartDateChange.bind(this);
    this.handleDelEndDateChange = this.handleDelEndDateChange.bind(this);
    this.setProductionDates = this.setProductionDates.bind(this);
    this.editOrders = this.editOrders.bind(this);
    this.validateline = this.validateline.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.getProdDate = this.getProdDate.bind(this);
  }
  showDatePicker() {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }
  handleDelStartDateChange(value) {
    this.setState({
      searchDelStartDate: yyyymmdd(value, '-'),
      currentDelStartDate: value,
    });
  }

  handleDelEndDateChange(value) {
    this.setState({
      searchDelEndDate: yyyymmdd(value),
      currentDelEndDate: value,
    });
  }
  getProdDate(groupedRecords, itemName, delDate) {
    if (itemName in groupedRecords) {
      var prodDates = groupedRecords[itemName];
      var prev = '';
      for (var p in prodDates) {
        if (prodDates[p] >= delDate) {
          break;
        }
        prev = prodDates[p];
      }
      return prev;
    } else {
      return '';
    }
  }
  uploadFile = async () => {
    try {
      const uploadResult = await uploadFileToS3(
        this.state.file,
        'set-production-dates',
      );
      // Handle successful upload (e.g., updating state, showing message)
    } catch (error) {
      // Handle any errors during the upload
    }
  };
  async editOrders(event) {
    this.setState({
      isLoading: true,
    });
    let storeToS3 = true;
    var orderState = this.state.editedOrders;
    var count = 0;
    var errorCount = 0;
    for (var o in orderState) {
      try {
        const response = await EditOrderMutation({
          uniqueId: orderState[o]['uniqueId'],
          itemsCount: orderState[o]['itemsCount'],
        });
        count++;
        console.log(response);
      } catch (error) {
        storeToS3 = false;
        errorCount++;
        this.setState({
          alertModal: {
            mode: true,
            text: 'At least 1 Production date update failed',
          },
        });
      }
    }
    if (errorCount === 0) {
      this.setState({
        alertModal: {
          mode: true,
          text: count + ' orders have been edited. Please refresh page',
        },
      });
    }
    if (storeToS3) {
      this.uploadFile();
    }
    this.setState({
      isLoading: false,
    });
  }
  async setProductionDates(event) {
    let localFileContents = this.state.fileContents.data;
    let remainingItems = [];
    var groupedRecords = {};
    for (let i = 0; i < localFileContents.length; i++) {
      let obj = localFileContents[i];
      if (this.validateline(obj) === 1) {
        groupedRecords[obj['item']] = [];
        for (var key in obj) {
          if (key !== 'item' && obj[key] > 0) {
            groupedRecords[obj['item']].push(key);
          }
        }
        groupedRecords[obj['item']].sort();
      } else {
        remainingItems.push(obj);
      }
    }
    var dateArray = [];
    var loop = new Date(this.state.currentDelStartDate);
    while (loop <= this.state.currentDelEndDate) {
      loop.setHours(0, 0, 0, 0);
      dateArray.push(yyyymmdd(loop, '-'));
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
    var toEdit = [];
    var toDisplay = [];
    for (var d in dateArray) {
      var delDate = dateArray[d];
      let order = await Order(null, null, delDate);
      let orderState = order.data.ordersByDay.orders;
      while (order.data.ordersByDay.nextToken !== null) {
        order = await Order(order.data.ordersByDay.nextToken, null, delDate);
        orderState = orderState.concat(order.data.ordersByDay.orders);
      }
      for (var o in orderState) {
        var edited = false;
        for (var idx in orderState[o]['itemsCount']) {
          var ic = orderState[o]['itemsCount'][idx];
          if (ic['productionDate'] !== null) {
            continue;
          }
          if (ic['itemName'] in groupedRecords) {
            var prodDate = this.getProdDate(
              groupedRecords,
              ic['itemName'],
              delDate,
            );
            toDisplay.push({
              prodDate: prodDate,
              itemName: ic['itemName'],
              deliveryDate: delDate,
              diff: dateDiff(prodDate, delDate),
            });
            if (prodDate !== '') {
              edited = true;
              ic['productionDate'] = prodDate;
            }
          }
        }
        if (edited) {
          toEdit.push(orderState[o]);
        }
      }
    }
    this.setState({ editedOrders: toEdit, toDisplay: toDisplay });
  }

  componentDidMount() {
    this.getLiveVendItems();
  }
  async getLiveVendItems() {
    try {
      let items = await Items();
      var tempItems = [];
      for (var idx in items['data']['items']) {
        tempItems.push(items['data']['items'][idx]['id']);
      }
      this.setState({ items: tempItems });
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      alertModal['text'] = [
        'Unable to load Vend items, Please check your internet and your permissions',
      ];
      this.setState({ alertModal: alertModal });
    }
  }

  handleFileChange = (event) => {
    this.setState({
      fileContents: '',
    });
    window.Papa.parse(event.target.files[0], {
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        results.data = results.data.map((item) => {
          item['item'] = item['item'].toString();
          return item;
        }); //.filter(obj=>this.validateline(obj) > 0)
        this.setState(
          {
            fileContents: results,
          },
          () => {
            this.setProductionDates();
          },
        );
      }.bind(this),
    });
    this.setState({
      file: event.target.files[0],
    });
  };

  validateline(item) {
    let item_keys = Object.keys(item);
    for (let i = 0; i < item_keys.length; i++) {
      let key = item_keys[i];
      if (!['item', 'status'].includes(key) && isNaN(item[key])) {
        return -1;
      }
    }
    return 1;
  }
  validateFile() {
    if (this.state.fileContents === '') {
      return true;
    }
    return false;
  }
  render() {
    return (
      <div>
        {this.state.showPicker && (
          <Grid>
            <Row>
              <ol>
                <li>
                  The orders between the delivery start date and end date will
                  only be queried for setting production dates
                </li>
              </ol>
            </Row>
            <Row className="text-center" style={{ marginBottom: '15px' }}>
              <Col sm={6}>
                <h3>Delivery Start Date</h3>
                <DatePicker
                  onChange={this.handleDelStartDateChange}
                  value={this.state.currentDelStartDate}
                />
              </Col>
              <Col>
                <h3>Delivery End Date</h3>
                <DatePicker
                  onChange={this.handleDelEndDateChange}
                  value={this.state.currentDelEndDate}
                />
              </Col>
            </Row>
          </Grid>
        )}
        <div className="Locations">
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{this.state.alertModal.text}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  var alertModal = this.state.alertModal;
                  alertModal['mode'] = false;
                  this.setState({ alertModal: alertModal });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <FormGroup controlId="file">
            <FormControl
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={this.handleFileChange}
              type="file"
            />
          </FormGroup>

          <BootstrapTable
            keyField="itemName"
            data={this.state.toDisplay}
            columns={columns}
          />

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={this.validateFile()}
            onClick={this.editOrders}
            type="submit"
            isLoading={this.state.isLoading}
            text="Upload ProductionDates"
            loadingText="Uploading ProductionDates…"
          />
        </div>
      </div>
    );
  }
}
