/* eslint-disable  jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../../../utils/styledComponent';
import Radio from '../../../atoms/forms/Radio';
import { contextState } from '../../../ContextProvider';

const StyledDiv = styledComponent('div', {
  '& .labelLike': {
    display: 'inline-block',
    marginBottom: '16px',
  },
  '& label': {
    fontWeight: 'normal',
  },
});

const CreateOptions = ({ defaultValue = 'bulk', history }) => {
  return (
    <StyledDiv>
      <h1>Create Order</h1>
      <p>All fields are required</p>
      <hr />
      <div>
        <span className="labelLike">Upload Type</span>
        <Radio
          checked={defaultValue === 'bulk'}
          label="Bulk Upload"
          name="upload"
          handleChange={() => {
            history.push('/orders-v2/bulk-create');
          }}
          value="bulk"
        />
      </div>
    </StyledDiv>
  );
};

CreateOptions.propTypes = {
  defaultValue: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CreateOptions;
