const configProd = {
  cognito: {
    USER_POOL_ID: 'us-east-1_TVgSAzQjd',
    APP_CLIENT_ID: '671smbkt0tqv8b52dc9cbm6qto',
    REGION: 'us-east-1',
    IDENTITY_POOL_ID: 'us-east-1:36b5e569-1c15-4f67-b7b4-ae525193f64b',
  },

  apiGateway: {
    URL: 'https://f1ydjea2th.execute-api.us-west-2.amazonaws.com',
    REGION: 'us-west-2',
  },
  analytics: {
    APP_ID: 'f00d727362ca4d63a01313ad19cd7ea7',
    REGION: 'us-east-1',
  },
  appsync: {
    PROD: {
      REGION: 'us-east-2',
      ENDPOINT:
        'https://jl4yjfr45rejjahmachltgntqy.appsync-api.us-east-2.amazonaws.com/graphql',
    },
    DEV: {
      API_KEY: 'da2-oyngaczrl5ekpn2nizq45jij24',
      REGION: 'us-west-2',
      ENDPOINT:
        'https://64vpaww2qbd4rlkd6tsvvj7qae.appsync-api.us-west-2.amazonaws.com/graphql',
    },
  },
  AWSS3: {
    BUCKET: 'ff-cms-internal-tools-uploads',
    REGION: 'us-east-2',
  },
};
export default configProd;
