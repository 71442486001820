import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { CreateOrderMutation } from '../../libs/GraphQL.js';
import {
  ToggleButton,
  ToggleButtonGroup,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Button,
} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { diff } from 'deep-object-diff';
import { Modal } from 'react-bootstrap';

const columns = [
  {
    dataField: 'displayName',
    text: 'Item',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'channel',
    text: 'Sales Channel',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'quantity',
    text: 'Quantity',
    editor: {
      type: Type.TEXTAREA,
    },
    sort: true,
  },
];

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(
    (key) => (obj[key] === null || obj[key] === '') && delete obj[key],
  );
};

export default class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.afterSaveCell = this.afterSaveCell.bind(this);
    this.state = {
      address: '',
      recurringDelivery: [1],
      order: {
        clientCode: '',
        clientDetails: '',
        invoiceNumber: '',
        orderNumber: '',
        billingType: '',
        locationId: '',
        orderOwner: '',
        orderType: 'FRIDGE',
        deliverySpecialNotes: '',
        kitchenSpecialNotes: '',
        addressForDelivery: '',
        market: '',
        itemsCount: [],
        deliveryDate: '',
        productionDate: '',
        dropOffDate: '',
      },
      alertModal: {
        mode: false,
        text: '',
      },
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    //debugger;
    let propDiff =
      Object.keys(diff(nextProps, this.props)).length === 0 ? false : true;
    let stateDiff =
      Object.keys(diff(nextState, this.state)).length === 0 ? false : true;
    return !(stateDiff && propDiff);
    //return true
  }
  async componentDidMount() {
    const currentSession = await Auth.currentAuthenticatedUser();
    let order = this.state.order;
    order.orderOwner = currentSession.signInUserSession.idToken.payload.email;
    this.setState({ order: order });
  }
  handleDateChange(type, value) {
    let order = this.state.order;
    order[type] = value;
    this.setState({ order: order });
  }
  async submit(e) {
    e.preventDefault();
    let order = JSON.parse(JSON.stringify(this.state.order));

    order['deliveryDate'] = order['deliveryDate'].substring(0, 10);
    if (order['dropOffDate'] !== undefined) {
      order['dropOffDate'] = order['dropOffDate'].substring(0, 10);
    }
    var prodDate = order['productionDate'].substring(0, 10);
    if (prodDate !== '') {
      for (var idx in order['itemsCount']) {
        order['itemsCount'][idx]['productionDate'] = prodDate;
      }
    }
    order['productionDate'] = '';
    if (order['orderType'] === 'FARMSTAND') {
      order['locationId'] = 'REVIVAL_FARMSTAND';
    }
    if (
      !['CUSTOMER'].includes(this.state.order.orderType) ||
      (this.state.recurringDelivery[0] === 2 &&
        ['CUSTOMER'].includes(this.state.order.orderType))
    ) {
      order['addressForDelivery'] = order['locationId'];
    }
    if (order['orderType'] === 'INTERNAL') {
      order['addressForDelivery'] = 'KITCHEN';
    }
    removeEmpty(order);
    try {
      const response = await CreateOrderMutation(order);
      console.log(response);
      this.setState({
        alertModal: {
          mode: true,
          text:
            response['data']['createOrder']['message'] +
            '! Please refresh the page.',
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({
        alertModal: { mode: true, text: 'Order Creation Failed!' },
      });
      return null;
    }
  }

  afterSaveCell(oldValue, newValue, row, column) {
    let order = this.state.order;
    const index = order['itemsCount'].map((e) => e.itemName).indexOf(row['id']);
    if (index === -1 && column['dataField'] === 'quantity') {
      order['itemsCount'].push({
        itemName: row['id'],
        count: parseInt(newValue, 10),
      });
    } else if (column['dataField'] === 'quantity') {
      order['itemsCount'][index]['count'] = parseInt(newValue, 10);
    }
    this.setState({ order: order });
  }
  handleCheckbox(e) {
    this.setState({
      recurringDelivery: e.filter(
        (x) => !this.state.recurringDelivery.includes(x),
      ),
    });
  }
  handleChange(e) {
    let order = this.state.order;
    if (e.target.id.startsWith('ITEM--')) {
      let id = e.target.id.split('ITEM--')[1];
      order.items[id] = e.target.value;
    } else {
      order[e.target.id] = e.target.value;
    }

    this.setState({ order: order });
  }
  getValidationState(type) {
    if (['itemsCount', 'type', 'itemsName'].includes(type) > 0) {
      return 'success';
    }
    const length = this.state.order[type].length;
    if (length > 10) return 'success';
    else if (length > 5) return 'warning';
    else if (length > 0) return 'error';
    return null;
  }

  render() {
    return (
      <div>
        <Modal show={this.state.alertModal.mode}>
          <Modal.Body>
            <div className="form-group">{this.state.alertModal.text}</div>
          </Modal.Body>
          <Modal.Footer>
            &nbsp;
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh Page
            </button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col md={7}>
            <div>
              <Form horizontal>
                <FormGroup
                  controlId="orderOwner"
                  validationState={this.getValidationState('orderOwner')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Owner
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      type="text"
                      value={this.state.order.orderOwner}
                      placeholder="Email Address for internal owner"
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
                <FormGroup
                  controlId="orderType"
                  validationState={this.getValidationState('orderType')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Order Type
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      value={this.state.order.orderType}
                      onChange={this.handleChange}
                    >
                      <option value="FRIDGE">Fridge</option>
                      <option value="QA">QA</option>
                      <option value="FARMSTAND">Revival</option>
                      <option value="INTERNAL">Level Loading</option>
                      <option value="CUSTOMER">Customer Facing</option>
                      <option value="FRIDGE_OTHERS">
                        Second stocking & Weekend stocking
                      </option>
                    </FormControl>
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
                {['CUSTOMER'].includes(this.state.order.orderType) && (
                  <FormGroup
                    controlId="clientCode"
                    validationState={this.getValidationState('clientCode')}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Client
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        value={this.state.order.clientCode}
                        onChange={this.handleChange}
                      >
                        <option value="Catering">Catering</option>
                        <option value="AmazonGo">AmazonGo</option>
                        <option value="Wholesale">Wholesale</option>
                        <option value="Sales">Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="QA">QA</option>
                        <option value="MenuRD">MenuRD</option>
                        <option value="CommunityEvents">CommunityEvents</option>
                        <option value="ExecutiveOffice">ExecutiveOffice</option>
                        <option value="LogisticsRD">LogisticsRD</option>
                        <option value="Other">Other</option>
                      </FormControl>
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                {['Other'].includes(this.state.order.clientCode) && (
                  <FormGroup
                    controlId="clientDetails"
                    validationState={this.getValidationState('clientDetails')}
                  >
                    <Col componentClass={ControlLabel} sm={4}>
                      Client Details
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        type="text"
                        value={this.state.order.clientDetails}
                        placeholder="Enter Name of Client"
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}

                {['Wholesale'].includes(this.state.order.clientCode) && (
                  <FormGroup controlId="invoiceNumber">
                    <Col componentClass={ControlLabel} sm={2}>
                      Invoice Number
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        type="text"
                        value={this.state.order.invoiceNumber}
                        placeholder="Enter Invoice Number"
                        onChange={this.handleChange}
                        maxLength="180"
                      />
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                {['Wholesale'].includes(this.state.order.clientCode) && (
                  <FormGroup controlId="orderNumber">
                    <Col componentClass={ControlLabel} sm={2}>
                      Order Number
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        type="text"
                        value={this.state.order.orderNumber}
                        placeholder="Enter Order Number"
                        onChange={this.handleChange}
                        maxLength="180"
                      />
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                {['CUSTOMER'].includes(this.state.order.orderType) && (
                  <FormGroup
                    controlId="billingType"
                    validationState={this.getValidationState('billingType')}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Billing Type
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        value={this.state.order.billingType}
                        onChange={this.handleChange}
                      >
                        <option value="COMP">Comped</option>
                        <option value="BILL">Bill Customer</option>
                        <option value="SOLDIER">SOLDIER</option>
                      </FormControl>
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                {['CUSTOMER'].includes(this.state.order.orderType) && (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      Delivery Type
                    </Col>
                    <Col sm={10}>
                      <div>
                        <ToggleButtonGroup
                          type="checkbox"
                          value={this.state.recurringDelivery}
                          onChange={this.handleCheckbox}
                        >
                          <ToggleButton value={1}>One Time</ToggleButton>
                          <ToggleButton value={2}>Recurring</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Col>
                  </FormGroup>
                )}
                {(['FRIDGE', 'FRIDGE_OTHERS'].includes(
                  this.state.order.orderType,
                ) ||
                  (this.state.recurringDelivery[0] === 2 &&
                    ['CUSTOMER'].includes(this.state.order.orderType))) && (
                  <FormGroup
                    controlId="locationId"
                    validationState={this.getValidationState('locationId')}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Location ID
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        value={this.state.order.locationId}
                        onChange={this.handleChange}
                      >
                        {this.props.locations
                          .map((location) => location.id)
                          .sort()
                          .map((location) => (
                            <option key={location} value={location}>
                              {location}
                            </option>
                          ))}
                      </FormControl>
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                {this.state.recurringDelivery[0] === 1 &&
                  ['CUSTOMER'].includes(this.state.order.orderType) && (
                    <FormGroup
                      controlId="addressForDelivery"
                      validationState={this.getValidationState(
                        'addressForDelivery',
                      )}
                    >
                      <Col componentClass={ControlLabel} sm={2}>
                        Delivery Address
                      </Col>
                      <Col sm={10}>
                        <FormControl
                          type="text"
                          value={this.state.order.addressForDelivery}
                          placeholder="Enter Address"
                          onChange={this.handleChange}
                        />
                        <FormControl.Feedback />
                      </Col>
                    </FormGroup>
                  )}
                {!['QA', 'INTERNAL'].includes(this.state.order.orderType) && (
                  <FormGroup
                    controlId="deliverySpecialNotes"
                    validationState={this.getValidationState(
                      'deliverySpecialNotes',
                    )}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Delivery Notes
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        type="text"
                        value={this.state.order.deliverySpecialNotes}
                        placeholder="Enter Notes for Driver"
                        onChange={this.handleChange}
                        maxLength="180"
                      />
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup
                  controlId="kitchenSpecialNotes"
                  validationState={this.getValidationState(
                    'kitchenSpecialNotes',
                  )}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Kitchen Notes
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      type="text"
                      value={this.state.order.kitchenSpecialNotes}
                      placeholder="Enter Notes for Kitchen"
                      onChange={this.handleChange}
                      maxLength="180"
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
                {['CUSTOMER'].includes(this.state.order.orderType) && (
                  <FormGroup
                    controlId="market"
                    validationState={this.getValidationState('market')}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Market
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        value={this.state.order.market}
                        onChange={this.handleChange}
                      >
                        <option value="Chicago">Chicago</option>
                        <option value="Milwaukee">Milwaukee</option>
                        <option value="Indianapolis">Indiana</option>
                      </FormControl>
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup
                  controlId="deliveryDate"
                  validationState={this.getValidationState('deliveryDate')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Delivery Date
                  </Col>
                  <Col sm={10} id="deliveryDatePicker">
                    <DatePicker
                      onChange={(e) => this.handleDateChange('deliveryDate', e)}
                      value={this.state.order.deliveryDate}
                      clearIcon={null}
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
                <FormGroup
                  controlId="dropOffDate"
                  validationState={this.getValidationState('dropOffDate')}
                >
                  <Col componentClass={ControlLabel} sm={2}>
                    Customer Drop Off Date
                  </Col>
                  <Col sm={10} id="dropOffDatePicker">
                    <DatePicker
                      onChange={(e) => this.handleDateChange('dropOffDate', e)}
                      value={this.state.order.dropOffDate}
                      clearIcon={null}
                    />
                    <FormControl.Feedback />
                  </Col>
                </FormGroup>
                {['INTERNAL'].includes(this.state.order.orderType) && (
                  <FormGroup
                    controlId="productionDate"
                    validationState={this.getValidationState('productionDate')}
                  >
                    <Col componentClass={ControlLabel} sm={2}>
                      Production Date
                    </Col>
                    <Col sm={10}>
                      <DatePicker
                        onChange={(e) =>
                          this.handleDateChange('productionDate', e)
                        }
                        value={this.state.order.productionDate}
                        clearIcon={null}
                      />
                      <FormControl.Feedback />
                    </Col>
                  </FormGroup>
                )}
              </Form>
            </div>
          </Col>
          <Col md={5}>
            <BootstrapTable
              keyField="id"
              data={this.props.items.map((item) => {
                return {
                  id: item['id'],
                  displayName: item['id'],
                  channel: item['salesChannel'].join(', '),
                  quantity:
                    this.state.order.itemsCount
                      .map((e) => e.itemName)
                      .indexOf(item.id) > -1
                      ? this.state.order.itemsCount[
                          this.state.order.itemsCount
                            .map((e) => e.itemName)
                            .indexOf(item.id)
                        ].count
                      : 0,
                };
              })}
              columns={columns}
              filter={filterFactory()}
              cellEdit={cellEditFactory({
                mode: 'click',
                autoSelectText: true,
                blurToSave: true,
                afterSaveCell: this.afterSaveCell,
              })}
              pagination={paginationFactory()}
            />
          </Col>
        </Row>
        <Form>
          <FormGroup>
            <Col smOffset={2} sm={10}>
              <Button onClick={this.submit} type="submit">
                Submit Order
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
