import React from 'react';

const Detail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        fillOpacity=".87"
        fillRule="evenodd"
        d="M20.124 15.352v2.976H5.391v-2.976h14.733zm-16.273-.01v2.977H.876v-2.977H3.85zm16.273-4.215v2.975H5.391v-2.975h14.733zM3.851 11.12v2.976H.876v-2.976H3.85zm16.273-4.216v2.975H5.391V6.903h14.733zm-16.273-.01V9.87H.876V6.893H3.85zm16.273-4.215v2.975H5.391V2.678h14.733zm-16.273-.01v2.977H.876V2.669H3.85z"
      />
    </svg>
  );
};

export default Detail;
