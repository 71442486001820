import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderButton from '../LoaderButton';
import { Items, EditOrderMutation } from '../../libs/GraphQL';
import styledComponent from '../../utils/styledComponent';
import ErrorsBlock from '../../atoms/ErrorsBlock';
import FileUpload from '../../atoms/FileUpload';

const LogModalTitle = styledComponent('h4', {
  borderBottom: '1px solid #e5e5e5',
  padding: '15px',
  margin: '0px',
});

const allowedFileColumns = [
  'orderId',
  'item',
  'count',
  'locationId',
  'deliveryDate',
  'deliverySpecialNotes',
  'region',
  'dropOffDate',
];

const notReqFileColumns = ['wholesaleLocationId', 'productionDate'];

const OrderFridgeMassUpdate = () => {
  const [fileContent, setFileContent] = useState([]);
  const [allowedItemsNames, setAllowedItemsNames] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [lineErrors, setLineErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertContent, setAlertContent] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasErrors = fileErrors.length > 0 || lineErrors.length > 0;

  const getVendItems = async () => {
    try {
      const itemsNames = [];
      const rawVendItems = await Items();
      const activeVendItems = rawVendItems.data.items.filter(
        (item) => item.status === 'LIVE',
      );
      activeVendItems.forEach((item) => {
        itemsNames.push(item.id);
      });
      setAllowedItemsNames(itemsNames);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getVendItems();
  }, []);

  const validateFile = (file) => {
    const errors = [];
    const fileHeaders = file.meta.fields;
    setFileColumns(fileHeaders);

    const columnsToValidate = fileHeaders.filter(
      (item) => !notReqFileColumns.includes(item),
    );

    const missedColumns = allowedFileColumns.filter(
      (x) => !columnsToValidate.includes(x),
    );
    const unknownColumns = columnsToValidate.filter(
      (x) => !allowedFileColumns.includes(x),
    );

    if (missedColumns.length) {
      errors.push(`Missed columns: ${missedColumns.join(', ')}`);
    }

    if (unknownColumns.length) {
      errors.push(
        `Unknown columns: ${unknownColumns.join(
          ', ',
        )}. File should contains following columns: ${allowedFileColumns.join(
          ', ',
        )}`,
      );
    }

    return errors;
  };

  const validateLine = (line) => {
    const errors = [];
    const today = moment().format('YYYY-MM-DD');

    // Validate `item` column
    if (line.item === '') {
      errors.push('Value required for `item` column');
    } else if (!allowedItemsNames.includes(line.item)) {
      errors.push('SKU in `item` column does not match active SKU list');
    }

    // Validate `count` column
    if (line.count === '') {
      errors.push('Value required for `count` column');
    } else if (!isNumber(line.count)) {
      errors.push('`count` must be a number');
    } else if (line.count < 1) {
      errors.push('`count` must have quantity greater than 0');
    }

    // Validate `locationId` column
    if (line.locationId === '') {
      errors.push('Value required for `locationId`');
    }

    // Validate `productionDate` column
    const productionDateMoment = moment(
      line.productionDate,
      'YYYY-MM-DD',
      true,
    );

    if (
      line.productionDate !== '' &&
      line.productionDate !== undefined &&
      !productionDateMoment.isValid()
    ) {
      errors.push(
        '`productionDate` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    }

    // Validate `deliveryDate` column
    const deliveryDateMoment = moment(line.deliveryDate, 'YYYY-MM-DD', true);

    if (line.deliveryDate === '') {
      errors.push('Value required for `deliveryDate` column');
    } else if (!deliveryDateMoment.isValid()) {
      errors.push(
        '`deliveryDate` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (deliveryDateMoment.isBefore(productionDateMoment)) {
      errors.push('`deliveryDate` must come after `productionDate`');
    } else if (deliveryDateMoment.isSameOrBefore(today)) {
      errors.push('`deliveryDate` can not be before today');
    }

    // Validate `region` column
    if (line.region === '') {
      errors.push('Value required for `region` column');
    }

    // Validate `deliverySpecialNotes` column
    if (line.deliverySpecialNotes === '') {
      errors.push('Value required for `deliverySpecialNotes` column');
    }

    return errors;
  };

  const handleFileChange = (file) => {
    setFileErrors([]);
    setLineErrors([]);
    setFileChosen(false);
    setFileContent([]);
    setFileName(file.name);

    if (file.type !== 'text/csv') {
      setFileErrors(['File must be a .csv']);
    } else {
      window.Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (fileData) => {
          const fileValidationErrors = validateFile(fileData);
          if (fileValidationErrors.length) {
            setFileErrors(fileValidationErrors);
          } else {
            const linesValidationErrors = [];
            fileData.data.forEach((line, i) => {
              const lineValidationErrors = validateLine(line);
              if (lineValidationErrors.length) {
                linesValidationErrors.push({
                  location: `[Line ${i + 2}]`,
                  data: lineValidationErrors,
                });
              }
            });

            if (!linesValidationErrors.length) {
              setFileContent(fileData);
            } else {
              setLineErrors(linesValidationErrors);
            }
          }
        },
      });
    }
    setFileChosen(true);
  };

  const handleUpload = async () => {
    setLoading(true);
    const alertMessages = [];
    const ordersToEdit = Object.values(
      fileContent.data.reduce((acc, curr) => {
        if (!acc[curr.orderId]) {
          acc[curr.orderId] = {
            uniqueId: curr.orderId,
            deliveryDate: curr.deliveryDate,
            itemsCount: [],
          };
        }

        acc[curr.orderId].itemsCount.push({
          itemName: curr.item,
          count: curr.count,
          productionDate: curr.productionDate,
        });

        return acc;
      }, {}),
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const item of ordersToEdit) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await EditOrderMutation({
          uniqueId: item.uniqueId,
          itemsCount: item.itemsCount,
          deliveryDate: item.deliveryDate,
          modifiedBy: 'ORDER_MASS_UPDATE',
        });
        if (response.data.editOrder.code === 500) {
          throw new Error(`Error: ${item.uniqueId} was NOT updated`);
        } else {
          alertMessages.push(`${item.uniqueId} successfully updated`);
        }
      } catch (e) {
        if (e.message !== '') {
          alertMessages.push(e.message);
        } else {
          alertMessages.push(
            `Error: ${item.uniqueId} Unable to update order, Please check your internet and your permissions.`,
          );
        }
      }
    }

    setAlertContent(alertMessages);
    setFileContent([]);
    setFileChosen(false);
    setLoading(false);
  };

  return (
    <>
      <h3>Wholesale</h3>

      {fileChosen ? (
        <Button
          block
          bsStyle="primary"
          bsSize="large"
          style={{ marginBottom: '40px' }}
          disabled={loading}
          onClick={() => {
            setFileErrors([]);
            setLineErrors([]);
            setFileChosen(false);
            setFileContent([]);
          }}
        >
          Upload another file
        </Button>
      ) : (
        <FileUpload onFileChange={handleFileChange} />
      )}

      {hasErrors && (
        <ErrorsBlock>
          <ul>
            {fileErrors.map((error) => {
              return <li key={error}>{error}</li>;
            })}
            {lineErrors.map((error) => {
              return (
                <li key={error.location}>
                  <b>{error.location} has an error</b> <br />
                  {error.data.map((msg) => {
                    return <div key={msg}> {msg} </div>;
                  })}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}

      {fileContent.length !== 0 && !hasErrors && (
        <>
          <span>{fileName}</span>
          <Table>
            <thead style={{ border: '1px solid #ddd' }}>
              <tr>
                {fileColumns.map((col) => {
                  return <th key={col}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {fileContent &&
                fileContent.data.map((line) => {
                  return (
                    <tr key={line.locationId + line.item}>
                      {fileColumns.map((col) => {
                        return (
                          <td key={col}>
                            {typeof line[col] === 'boolean'
                              ? line[col].toString()
                              : line[col]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="success"
            bsSize="large"
            type="submit"
            isLoading={loading}
            text="Run Order Mass Update"
            loadingText="Orders Updating…"
            style={{ marginBottom: '40px' }}
            onClick={handleUpload}
          />
        </>
      )}

      <Modal show={alertContent.length}>
        <LogModalTitle>Order Mass Update Log</LogModalTitle>
        <Modal.Body>
          {alertContent.map((msg) => (
            <div key={msg} style={{ marginBottom: '8px' }}>
              {msg}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            onClick={() => {
              setAlertContent([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderFridgeMassUpdate;
