/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useAsyncCallback } from 'react-async-hook';
import Button from '../../atoms/Button';
import Loading from '../../atoms/Loading';
import Input from '../../atoms/forms/Input';
import TimePicker from '../../atoms/forms/TimePicker';
import DatePicker from '../../atoms/forms/DatePicker';
import NumberTicker from '../../atoms/forms/NumberTicker';
import Breadcrumb from '../../atoms/Breadcrumb';
import Select from '../../atoms/forms/Select';
import Duration from './components/Duration';
import OversAndUnders from './components/OversAndUnders';
import styledComponent from '../../utils/styledComponent';
import 'react-toastify/dist/ReactToastify.css';
import {
  Items,
  ReadPickPackLineItem,
  CreatePickPackLineItemInputMutation,
} from '../../libs/GraphQL';

const StyledSection = styledComponent('section', {
  '& a': {
    color: '#5F8E40',
  },
  '& .advancedDisplay': {
    color: '#5F8E40',
    display: 'inline-block',
    marginBottom: '20px',
  },
  '& .closeForm': {
    display: 'inline-block',
    marginLeft: '20px',
  },
  '& dl': {
    '& dd': {
      marginBottom: '20px',
    },
    width: '250px',
  },
  '& header': {
    '& h2': {
      margin: 0,
    },
    '& button': {},
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  '& h1': {
    color: '#457A23',
  },
  '& h2': {
    color: '#565656',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  '& li': {
    listStyle: 'none',
  },
  '& .pageWrapper': {
    display: 'flex',
  },
  '& .readOnlyLabel': {
    color: '#000',
    fontSize: '18px',
    fontWeight: '800',
    width: '250px',
  },
  '& .readOnlyValue': {
    '& a': {
      color: '#5F8E40',
      marginLeft: '10px',
    },
    color: '#818181',
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '20px',
  },
  '& .stepsSection': {
    '& h3': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    marginLeft: '40px',
    width: '100%',
  },
  '& .steps': {
    '& h4': {
      fontSize: '14px',
      marginBottom: 0,
      marginTop: 0,
    },
    '& .inactive': {
      color: '#C4C4C4',
    },
    '& span': {
      display: 'inline-block',
      marginTop: '10px',
    },
    alignItems: 'center',
    borderBottom: '1px solid #e7e7e7',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },
  '& ul': {
    margin: 0,
    padding: 0,
  },
  marginBottom: '50px',
});

const OLEdit = ({ history, match }) => {
  const [overrideDate, setOverrideDate] = useState(false);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [isFormShown, setIsFormShown] = useState(false);
  const [itemsData, setItemsData] = useState({
    data: { items: [] },
  });
  const itemRes = useAsyncCallback(async () => {
    const result = await Items();
    setItemsData(result);
  });
  const [pickData, setPickData] = useState({
    data: { pickPackLineItemSummary: [] },
  });
  const pickRes = useAsyncCallback(async () => {
    const result = await ReadPickPackLineItem(match.params.id);
    setPickData(result);
  });

  useEffect(() => {
    if (itemRes.status === 'not-requested') {
      itemRes.execute();
    }
    if (pickRes.status === 'not-requested') {
      pickRes.execute();
    }
  }, [itemRes, pickRes]);

  const [pickItem, setPickItem] = useState({});

  if (itemRes.error || pickRes.error) {
    toast.error('Please try again later.');
  }

  const updateState = (updatedData) => {
    setPickItem({
      ...pickItem,
      ...updatedData,
    });
  };

  const sortItems = (items) => {
    return items.data.items.sort((a, b) => {
      const nameA = a.prettyName.toUpperCase();
      const nameB = b.prettyName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  useEffect(() => {
    if (pickRes.error) {
      toast.error('Please try again later.');
    }
    setPickItem(pickData.data.pickPackLineItemSummary[0]);
  }, [pickData, itemsData, pickRes.error, pickRes.loading, itemRes.loading]);

  const AddSchema = Yup.object().shape({
    date: Yup.date().required('Required'),
    destinationRegion: Yup.string().required('Required'),
    line: Yup.string().required('Required'),
    productionFacility: Yup.string().required('Required'),
  });

  return (
    <StyledSection>
      <h1>OLE</h1>
      <Breadcrumb
        items={[
          { link: '/ole/index', text: 'Data Entry' },
          { text: 'Edit OLE Entry' },
        ]}
      />
      <header>
        <h2>Edit OLE Entry</h2>
      </header>
      {pickRes.loading && <Loading />}
      {!pickRes.loading && pickItem && (
        <>
          <div className="pageWrapper">
            {!isFormShown && (
              <div>
                <dl>
                  <dt>Date</dt>
                  <dd>{pickItem.date}</dd>
                  <dt>Production Facility</dt>
                  <dd>{pickItem.productionFacility}</dd>
                  <dt>Destination</dt>
                  <dd>{pickItem.destinationRegion}</dd>
                  <dt>Line</dt>
                  <dd>{pickItem.line}</dd>
                  <dt>Pickers</dt>
                  <dd data-testid="linePopulation">
                    {pickItem.linePopulation}
                  </dd>
                  <dt>Stackers</dt>
                  <dd data-testid="stackPopulation">
                    {pickItem.stackPopulation}
                  </dd>
                  <dt>Notes</dt>
                  <dd>{pickItem.notes}</dd>
                </dl>
                <a
                  data-testid="EditOLEEntry"
                  href="#"
                  onClick={() => {
                    setIsFormShown(true);
                  }}
                >
                  Edit
                </a>
              </div>
            )}
            {isFormShown && (
              <Formik
                initialValues={{
                  date: pickItem.date,
                  productionFacility: pickItem.productionFacility,
                  destinationRegion: pickItem.destinationRegion,
                  line: pickItem.line,
                  linePopulation: pickItem.linePopulation,
                  stackPopulation: pickItem.stackPopulation,
                  notes: pickItem.notes,
                  sheetDeliveryTime: pickItem.sheetDeliveryTime
                    ? moment.unix(pickItem.sheetDeliveryTime).format('HH:mm:ss')
                    : '',
                  lineEndTime: pickItem.lineEndTime
                    ? moment.unix(pickItem.lineEndTime).format('HH:mm:ss')
                    : '',
                  lineStartTime: pickItem.lineStartTime
                    ? moment.unix(pickItem.lineStartTime).format('HH:mm:ss')
                    : '',
                }}
                validationSchema={AddSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  const updates = {
                    ...pickItem,
                    ...values,
                    lineStartTime: values.lineStartTime
                      ? moment(
                          `${values.date} ${values.lineStartTime}`,
                          'YYYY-M-D HH:mm:ss',
                        ).unix()
                      : null,
                    lineEndTime: values.lineEndTime
                      ? moment(
                          `${values.date} ${values.lineEndTime}`,
                          'YYYY-M-D HH:mm:ss',
                        ).unix()
                      : null,
                    sheetDeliveryTime: values.sheetDeliveryTime
                      ? moment(
                          `${values.date} ${values.sheetDeliveryTime}`,
                          'YYYY-M-D HH:mm:ss',
                        ).unix()
                      : null,
                  };
                  CreatePickPackLineItemInputMutation(updates)
                    .then(() => {
                      toast.success('Edited OLE Entry');
                      setIsFormShown(false);
                      setPickItem(updates);
                    })
                    .catch(() => {
                      toast.error('Please try again later');
                    });
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <ul>
                        <li>
                          {overrideDate && (
                            <DatePicker
                              currentDate={moment(pickItem.date)}
                              error={errors.date}
                              label="Date"
                              onChange={(v) => {
                                setFieldValue(
                                  'date',
                                  moment(v).format('YYYY-MM-DD'),
                                );
                              }}
                            />
                          )}
                          {!overrideDate && (
                            <div>
                              <div className="readOnlyLabel">Date</div>
                              <div className="readOnlyValue">
                                {pickItem.date}
                                <a
                                  href="#"
                                  onClick={() => {
                                    setOverrideDate(true);
                                  }}
                                >
                                  Change Date
                                </a>
                              </div>
                            </div>
                          )}
                        </li>
                        <li>
                          <Select
                            errors={errors}
                            touched={touched}
                            label="Production Facility"
                            id="productionFacility"
                            options={[{ value: 'CHICAGO_1', label: 'Chicago' }]}
                            onChange={(v) => {
                              setFieldTouched('productionFacility', true);
                              setFieldValue('productionFacility', v.value);
                              setPickItem({
                                ...pickItem,
                                ...{ productionFacility: v.value },
                              });
                            }}
                            value={pickItem.productionFacility}
                          />
                        </li>
                        <li>
                          <Select
                            errors={errors}
                            touched={touched}
                            label="Destination"
                            id="destinationRegion"
                            options={[
                              { value: 'MIDWEST', label: 'Midwest' },
                              { value: 'NORTHEAST', label: 'Northeast' },
                            ]}
                            onChange={(v) => {
                              setFieldTouched('destinationRegion', true);
                              setFieldValue('destinationRegion', v.value);
                              setPickItem({
                                ...pickItem,
                                ...{ destinationRegion: v.value },
                              });
                            }}
                            value={pickItem.destinationRegion}
                          />
                        </li>
                        <li>
                          <Select
                            errors={errors}
                            touched={touched}
                            label="Line"
                            id="line"
                            options={[
                              { value: '1', label: 'Line 1' },
                              { value: '2', label: 'Line 2' },
                            ]}
                            onChange={(v) => {
                              setFieldTouched('line', true);
                              setFieldValue('line', v.value);
                              setPickItem({
                                ...pickItem,
                                ...{ line: v.value },
                              });
                            }}
                            value={pickItem.line}
                          />
                        </li>
                        <li>
                          <NumberTicker
                            defaultValue={pickItem.linePopulation}
                            label="Pickers"
                            name="linePopulation"
                            handleSelection={(value) => {
                              setFieldValue('linePopulation', value);
                            }}
                          />
                        </li>
                        <li>
                          <NumberTicker
                            defaultValue={pickItem.stackPopulation}
                            label="Stackers"
                            name="stackPopulation"
                            handleSelection={(value) => {
                              setFieldValue('stackPopulation', value);
                            }}
                            setFieldValue={setFieldValue}
                          />
                        </li>
                        <li>
                          <Input
                            label="Notes"
                            id="notes"
                            type="text"
                            name="notes"
                            placeholder=""
                          />
                        </li>
                        <li>
                          <a
                            data-testid="advancedEditBtn"
                            className="advancedDisplay"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAdvancedShown(!isAdvancedShown);
                            }}
                          >
                            {isAdvancedShown
                              ? 'Hide Advanced'
                              : 'Show Advanced'}
                          </a>
                        </li>
                        {isAdvancedShown && (
                          <>
                            <li>
                              <TimePicker
                                label="Sheet Delivered Time"
                                id="sheetDeliveryTime"
                                type="text"
                                name="sheetDeliveryTime"
                                onChange={(value) => {
                                  setFieldValue('sheetDeliveryTime', value);
                                }}
                                placeholder=""
                                value={
                                  pickItem.sheetDeliveryTime
                                    ? moment
                                        .unix(pickItem.sheetDeliveryTime)
                                        .format('HH:mm:ss')
                                    : null
                                }
                              />
                            </li>
                            <li>
                              <TimePicker
                                label="Line Start Time"
                                id="lineStartTime"
                                type="text"
                                name="lineStartTime"
                                onChange={(value) => {
                                  setFieldValue('lineStartTime', value);
                                }}
                                placeholder=""
                                value={
                                  pickItem.lineStartTime
                                    ? moment
                                        .unix(pickItem.lineStartTime)
                                        .format('HH:mm:ss')
                                    : null
                                }
                              />
                            </li>
                            <li>
                              <TimePicker
                                label="Line End Time"
                                id="lineEndTime"
                                type="text"
                                name="lineEndTime"
                                onChange={(value) => {
                                  setFieldValue('lineEndTime', value);
                                }}
                                placeholder=""
                                value={
                                  pickItem.lineEndTime
                                    ? moment
                                        .unix(pickItem.lineEndTime)
                                        .format('HH:mm:ss')
                                    : null
                                }
                              />
                            </li>
                          </>
                        )}
                      </ul>
                      <Button type="submit" data-testid="OLEEdit-submit">
                        Edit Entry
                      </Button>
                      <a
                        className="closeForm"
                        href="#"
                        onClick={() => {
                          setIsFormShown(false);
                        }}
                      >
                        Close Edit
                      </a>
                    </form>
                  );
                }}
              </Formik>
            )}
            <section className="stepsSection">
              <h3>Steps</h3>
              <ul className="steps">
                <li>
                  {!pickItem.sheetDeliveryTime && (
                    <Button
                      data-testid="sheetDeliveredBtn"
                      onClick={() => {
                        const delivTime = moment().unix();
                        CreatePickPackLineItemInputMutation({
                          ...pickItem,
                          sheetDeliveryTime: delivTime,
                        })
                          .then(() => {
                            updateState({ sheetDeliveryTime: delivTime });
                          })
                          .catch(() => {
                            toast.error('Please try again later');
                          });
                      }}
                    >
                      Sheet Delivered
                    </Button>
                  )}
                  {pickItem.sheetDeliveryTime && (
                    <span>
                      <h4>Delivery Time</h4>
                      {moment
                        .unix(parseInt(pickItem.sheetDeliveryTime))
                        .format('HH:mm:ss')}
                    </span>
                  )}
                </li>
                <li>
                  <span className="dots">
                    <svg width="70" height="10" viewBox="0 0 70 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                      <circle cx="35" cy="5" r="5" fill="#C4C4C4" />
                      <circle cx="65" cy="5" r="5" fill="#C4C4C4" />
                    </svg>
                  </span>
                </li>
                <li>
                  {!pickItem.lineStartTime && pickItem.sheetDeliveryTime && (
                    <Button
                      data-testid="lineStartBtn"
                      onClick={() => {
                        const lineStartTime = moment().unix();
                        CreatePickPackLineItemInputMutation({
                          ...pickItem,
                          lineStartTime,
                        })
                          .then(() => {
                            updateState({ lineStartTime });
                          })
                          .catch(() => {
                            toast.error('Please try again later');
                          });
                      }}
                    >
                      Line Start
                    </Button>
                  )}
                  {pickItem.lineStartTime && (
                    <span>
                      <h4>Start Time</h4>
                      {moment.unix(pickItem.lineStartTime).format('HH:mm:ss')}
                    </span>
                  )}
                  {!pickItem.lineStartTime && !pickItem.sheetDeliveryTime && (
                    <span className="inactive">Line Start</span>
                  )}
                </li>
                <li>
                  <span className="dots">
                    <svg width="70" height="10" viewBox="0 0 70 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                      <circle cx="35" cy="5" r="5" fill="#C4C4C4" />
                      <circle cx="65" cy="5" r="5" fill="#C4C4C4" />
                    </svg>
                  </span>
                </li>
                <li>
                  {!pickItem.lineEndTime && pickItem.lineStartTime && (
                    <Button
                      data-testid="lineEndBtn"
                      onClick={() => {
                        const lineEndTime = moment().unix();
                        CreatePickPackLineItemInputMutation({
                          ...pickItem,
                          lineEndTime,
                        })
                          .then(() => {
                            updateState({ lineEndTime });
                          })
                          .catch(() => {
                            toast.error('Please try again later');
                          });
                      }}
                    >
                      Line End
                    </Button>
                  )}
                  {pickItem.lineEndTime && (
                    <span>
                      <h4>End Time</h4>
                      {moment.unix(pickItem.lineEndTime).format('HH:mm:ss')}
                    </span>
                  )}
                  {!pickItem.lineEndTime && !pickItem.lineStartTime && (
                    <span className="inactive">Line End</span>
                  )}
                </li>
              </ul>
              {!pickItem.lineEndTime && pickItem.sheetDeliveryTime && (
                <Duration pickItem={pickItem} />
              )}
              {pickItem.lineEndTime && !itemRes.loading && (
                <Formik>
                  <OversAndUnders
                    history={history}
                    itemsData={sortItems(itemsData)}
                    isItemsLoading={itemRes.loading}
                    pickItem={pickItem}
                  />
                </Formik>
              )}
            </section>
          </div>
        </>
      )}
    </StyledSection>
  );
};

OLEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OLEdit;
