import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styledComponent from '../utils/styledComponent';

const StyledNavItem = styledComponent('li', {
  a: {
    color: '#687754',
    display: 'inline-block',
    padding: '14px',
  },
  '&.active': {
    '& a': {
      color: '#5F8E40',
      fontWeight: 'bold',
    },
    borderBottom: '2px solid #5F8E40',
  },
});

const PageNavItem = ({ children, href, isActive, ...otherProps }) => {
  return (
    <StyledNavItem className={isActive ? 'active' : ''} {...otherProps}>
      <Link to={href}>{children}</Link>
    </StyledNavItem>
  );
};
PageNavItem.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

PageNavItem.defaultProps = {
  isActive: false,
};

export default PageNavItem;
