import React, { Component } from 'react';
import LoaderButton from '../LoaderButton';
import { Glyphicon, FormGroup, FormControl, Table } from 'react-bootstrap';
import { Items, LocationIDs, CreateOrderMutation } from '../../libs/GraphQL';
import { Auth } from 'aws-amplify';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

export default class UploadBulkOrders extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      items: [],
      locations: {},
      isLoading: false,
      alertModal: {
        text: [],
        mode: false,
      },
      content: '',
      uploadURL: '',
      file: '',
      fileContents: '',
    };
    this.createOrders = this.createOrders.bind(this);
    this.validateline = this.validateline.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.cleanData = this.cleanData.bind(this);
  }

  async createOrders(event) {
    this.setState({
      isLoading: true,
    });
    let localFileContents = this.state.fileContents.data;
    let remainingItems = [];
    var groupedOrders = {};
    for (let i = 0; i < localFileContents.length; i++) {
      let obj = localFileContents[i];
      if (this.validateline(obj).status === 1) {
        const currentSession = await Auth.currentAuthenticatedUser();
        var locId = obj['locationId'].split('#')[0];
        var delDate = obj['deliveryDate'];
        var dropDate = obj['dropOffDate'];
        var groupKey = delDate + '#' + dropDate;
        var completeObject = {
          locationId: locId,
          orderOwner: currentSession.signInUserSession.idToken.payload.email,
          orderType: this.state.locations[locId],
          addressForDelivery:
            obj['locationId'] === 'KITCHEN' ? 'KITCHEN' : locId,
          itemsCount: [
            {
              itemName: obj['item'],
              count: obj['count'],
              productionDate: obj['productionDate'].substring(0, 10),
            },
          ],
          deliveryDate: delDate.substring(0, 10),
          deliverySpecialNotes: obj['deliverySpecialNotes'],
        };
        if (dropDate) {
          completeObject.dropOffDate = dropDate.substring(0, 10);
        }
        if (groupKey in groupedOrders) {
          if (obj['locationId'] in groupedOrders[groupKey]) {
            groupedOrders[groupKey][obj['locationId']]['itemsCount'].push({
              itemName: obj['item'],
              count: obj['count'],
              productionDate: obj['productionDate'].substring(0, 10),
            });
          } else {
            groupedOrders[groupKey][obj['locationId']] = completeObject;
          }
        } else {
          groupedOrders[groupKey] = {};
          groupedOrders[groupKey][obj['locationId']] = completeObject;
        }
      } else {
        remainingItems.push(obj);
      }
    }
    var success = 0;
    var fail = 0;
    for (var date in groupedOrders) {
      for (var loc in groupedOrders[date]) {
        try {
          const response = await CreateOrderMutation(groupedOrders[date][loc]);
          success++;
          console.log(response);
        } catch (err) {
          fail++;
          console.log(err);
        }
      }
    }
    let alertModal = this.state.alertModal;
    alertModal['mode'] = true;
    alertModal['text'] = [
      'Number of orders created succesfully: ' + success,
      'Number of orders failed to create: ' + fail + '. Please refresh page',
    ];
    this.setState({ alertModal: alertModal });
    let fileContents = this.state.fileContents;
    fileContents.data = remainingItems;
    this.setState({
      fileContents: fileContents,
      isLoading: false,
    });
  }

  componentDidMount() {
    //TODO: Use list of items from props
    this.getLiveVendItems();
    //TODO: Use list of locationIds from props
    this.getLocationIds();
  }
  async getLiveVendItems() {
    try {
      let items = await Items();
      var tempItems = [];
      for (var idx in items['data']['items']) {
        tempItems.push(items['data']['items'][idx]['id']);
      }
      this.setState({ items: tempItems });
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      alertModal['text'] = [
        'Unable to load Vend items, Please check your internet and your permissions',
      ];
      this.setState({ alertModal: alertModal });
    }
  }

  async getLocationIds() {
    try {
      var locations = await LocationIDs();
      var locationIds = {};
      for (var idx in locations['data']['locations']['locations']) {
        locationIds[locations['data']['locations']['locations'][idx]['id']] =
          locations['data']['locations']['locations'][idx]['type'] ===
          'DELIVERY'
            ? 'CUSTOMER'
            : 'FRIDGE_OTHERS';
      }
      locationIds['KITCHEN'] = 'INTERNAL';
      this.setState({ locations: locationIds });
    } catch (e) {
      console.log(e);
      var alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      var errorString = JSON.stringify(e);
      console.error(errorString);
      alertModal['text'] =
        'Unable to load locations, Please check your internet and your permissions';
      this.setState({ alertModal: alertModal });
    }
  }
  cleanData(results) {
    var group = {};
    var toReturn = [];
    for (var rec in results['data']) {
      if (results['data'][rec].item === '') {
        continue;
      }
      var loc =
        results['data'][rec]['locationId'] +
        '#' +
        results['data'][rec]['deliverySpecialNotes'];
      var delDate = results['data'][rec]['deliveryDate'];
      var item = results['data'][rec]['item'];
      var count = results['data'][rec]['count'];
      var proDate = results['data'][rec]['productionDate'];
      var dropDate = results['data'][rec]['dropOffDate'];
      var groupKey = delDate + '#' + dropDate;
      if (!(groupKey in group)) {
        group[groupKey] = {};
      }
      if (!(loc in group[groupKey])) {
        group[groupKey][loc] = {};
      }
      if (!(item in group[groupKey][loc])) {
        group[groupKey][loc][item] = { count: 0, productionDate: proDate };
      }
      if (parseInt(count, 10) >= 0) {
        group[groupKey][loc][item]['count'] += parseInt(count, 10);
      }
    }
    for (var d in group) {
      for (var l in group[d]) {
        for (var i in group[d][l]) {
          let dates = d.split('#');
          toReturn.push({
            item: i,
            count: group[d][l][i]['count'],
            locationId: l,
            deliveryDate: dates[0],
            productionDate: group[d][l][i]['productionDate'],
            deliverySpecialNotes: l.split('#')[1],
            dropOffDate: dates[1],
          });
        }
      }
    }
    results['data'] = toReturn;
    return results;
  }
  handleFileChange = (event) => {
    this.setState({
      fileContents: '',
    });
    window.Papa.parse(event.target.files[0], {
      header: true,
      dynamicTyping: true,
      complete: function (rawResults) {
        const cleaned = this.cleanData(rawResults);
        let results = {};
        results.error = cleaned.data
          .map((item) => {
            item['item'] = item['item'].toString();
            return item;
          })
          .filter((obj) => this.validateline(obj).status < 1);
        results.data = cleaned.data
          .map((item) => {
            item['item'] = item['item'].toString();
            return item;
          })
          .filter((obj) => this.validateline(obj).status > 0);
        this.setState({
          fileContents: results,
        });
        if (results.error.length > 0) {
          this.setState({
            alertModal: {
              mode: true,
              text: cleaned.data
                .map((item) => {
                  item['item'] = item['item'].toString();
                  return item;
                })
                .filter((obj) => this.validateline(obj).status < 1)
                .map((obj) => this.validateline(obj).msg),
            },
          });
        }
      }.bind(this),
    });
  };

  validateline(item) {
    let item_keys = Object.keys(item);
    let keys = [
      'item',
      'count',
      'locationId',
      'deliveryDate',
      'productionDate',
      'deliverySpecialNotes',
    ];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (!item_keys.includes(key) || item[key] === '') {
        console.log(item_keys);
        console.log('Invalid');
        return { status: -1, msg: 'Invalid Column: ' + key };
      }
    }
    //Check to see if item is valid
    if (!this.state.items.includes(item['item'])) {
      return { status: -1, msg: 'Unknown Item: ' + item['item'] };
    }

    //Check to see if quantity of item is valud
    if (item['count'] < 0) {
      return {
        status: -1,
        msg: 'Item ' + item['item'] + ' must have quantity greater than 0',
      };
    }

    //Check to see if location is valid
    if (
      !this.state.locations.hasOwnProperty(item['locationId'].split('#')[0])
    ) {
      return {
        status: -1,
        msg: 'Unknown Location: ' + item['locationId'].split('#')[0],
      };
    }

    var regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    // Ensure valid Delivery Date
    if (!item['deliveryDate'].match(regEx)) {
      return {
        status: -1,
        msg: 'Invalid Delivery Date: ' + item['deliveryDate'],
      };
    }

    // Ensure valid drop Off Date
    if (item['dropOffDate']) {
      if (!item['dropOffDate'].match(regEx)) {
        return {
          status: -1,
          msg: 'Invalid Drop Off Date: ' + item['dropOffDate'],
        };
      }
      // Ensure drop off is after delivery
      var dropOffDate = moment(item['dropOffDate']);
      var deliveryDate = moment(item['deliveryDate']).subtract(1, 'day');
      if (dropOffDate.isBefore(deliveryDate)) {
        return {
          status: -1,
          msg:
            'Drop off Date (' +
            item['dropOffDate'] +
            ') must be equal or after the day before ' +
            'Delivery Date (' +
            item['deliveryDate'] +
            ')',
        };
      }
    }

    // Ensure valid Production Date
    if (!item['productionDate'].match(regEx)) {
      return {
        status: -1,
        msg: 'Invalid Production Date: ' + item['productionDate'],
      };
    }
    // Ensure Delivery is after production
    if (item['deliveryDate'] <= item['productionDate']) {
      return {
        status: -1,
        msg:
          'Production Date (' +
          item['productionDate'] +
          ') must be before Delivery Date(' +
          item['deliveryDate'] +
          ')',
      };
    }
    return { status: 1, msg: '' };
  }
  validateFile() {
    if (this.state.fileContents === '') {
      return true;
    }
    return false;
  }
  render() {
    let alertRows = [];
    let alertMessages = this.state.alertModal.text;
    alertRows = alertMessages.map((text) => <li key={text}>{text}</li>);
    let headers;
    let rowsValid, rowsError;
    if (this.state.fileContents !== '') {
      headers = [
        'item',
        'count',
        'locationId',
        'deliveryDate',
        'dropOffDate',
        'productionDate',
        'deliverySpecialNotes',
        'status',
      ].map((header) => <th key={header}>{header}</th>);
      rowsValid = this.state.fileContents.data.map(function (obj) {
        let tds = [
          'item',
          'count',
          'locationId',
          'deliveryDate',
          'dropOffDate',
          'productionDate',
          'deliverySpecialNotes',
        ].map((header) => (
          <td key={header}>
            {header === 'locationId' ? obj[header].split('#')[0] : obj[header]}
          </td>
        ));
        tds.push(
          <td key="status">
            {' '}
            <Glyphicon glyph="ok" />
          </td>,
        );
        return (
          <tr
            key={
              obj['item'] +
              obj['locationId'] +
              obj['count'] +
              obj['deliveryDate'] +
              obj['productionDate']
            }
          >
            {tds}
          </tr>
        );
      });
      rowsError = this.state.fileContents.error.map(function (obj) {
        let tds = [
          'item',
          'count',
          'locationId',
          'deliveryDate',
          'dropOffDate',
          'productionDate',
          'deliverySpecialNotes',
        ].map((header) => (
          <td key={header}>
            {header === 'locationId' ? obj[header].split('#')[0] : obj[header]}
          </td>
        ));
        tds.push(
          <td key="status">
            {' '}
            <Glyphicon glyph="exclamation-sign" />
          </td>,
        );
        return (
          <tr
            key={
              obj['item'] +
              obj['locationId'] +
              obj['count'] +
              obj['deliveryDate'] +
              obj['productionDate']
            }
          >
            {tds}
          </tr>
        );
      });
    }

    return (
      <div className="Locations">
        <Modal show={this.state.alertModal.mode}>
          <Modal.Body>
            <ol>{alertRows}</ol>
          </Modal.Body>
          <Modal.Footer>
            &nbsp;
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                var alertModal = this.state.alertModal;
                alertModal['mode'] = false;
                this.setState({ alertModal: alertModal });
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <FormGroup controlId="file">
          <FormControl
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.handleFileChange}
            type="file"
          />
        </FormGroup>

        {this.state.fileContents !== '' && (
          <Table bordered responsive>
            <thead>
              <tr>{headers}</tr>
            </thead>
            <tbody>
              {rowsError}
              {rowsValid}
            </tbody>
          </Table>
        )}

        <LoaderButton
          block
          bsStyle="primary"
          bsSize="large"
          disabled={this.validateFile()}
          onClick={this.createOrders}
          type="submit"
          isLoading={this.state.isLoading}
          text="Upload Orders"
          loadingText="Uploading Orders…"
        />
      </div>
    );
  }
}
