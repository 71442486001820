import React, { Component } from 'react';

export default class Home extends Component {
  render() {
    return (
      <div className="Home" style={{ padding: '80px 0', textAlign: 'center' }}>
        <div className="lander">
          <h1
            style={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center' }}
            data-testid="Welcome Banner"
          >
            FF Facility CMS
          </h1>
          <p style={{ color: '#999' }}>
            A simple insight into the Production Facility
          </p>
        </div>
      </div>
    );
  }
}
