import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styledComponent from '../utils/styledComponent';
import { SaveFinishedGoodRun, CreateFinishedGood } from '../libs/GraphQL';
import { contextState, contextDispatch } from '../ContextProvider';

const StyledSection = styledComponent('section', {
  width: '100%',
  height: '100%',
  fontFamily: 'Roboto',
  backgroundColor: 'rgba(0,0,0,0.2)',
  position: 'relative',
  '#Confirm': {
    width: '775px',
    height: '370px',
    backgroundColor: '#ffffff',
    opacity: '1',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '#Title': {
    width: '550px',
    fontSize: '34px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.87',
    marginLeft: '42px',
    marginTop: '36px',
  },
  '#Details': {
    fontSize: '18px',
    color: '#000000',
    opacity: '0.87',
    lineHeight: '1.5',
    letterSpacing: '0.32px',
    marginLeft: '42px',
    marginTop: '18px',
  },
  '#ExtraDetails': {
    fontSize: '18px',
    color: '#000000',
    opacity: '0.87',
    lineHeight: '1.5',
    letterSpacing: '0.32px',
    marginLeft: '42px',
    marginTop: '18px',
  },
  '#ConfirmButtons': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '472px',
    height: '45px',
    textTransform: 'uppercase',
    marginLeft: '265px',
    marginTop: '115px',
    '.button': {
      width: '223px',
      height: '45px',
      textAlign: 'center',
      lineHeight: '45px',
      fontSize: '20px',
      letterSpacing: '1.88px',
      borderRadius: '4px',
      ':hover': {
        cursor: 'pointer',
      },
      ':active': {
        boxShadow: '0 5px',
        transform: 'translateY(4px)',
      },
    },
  },
  '#Cancel': {
    backgroundColor: '#ffffff',
    color: '#000000',
    opacity: '0.87',
    border: '2px solid black',
  },
  '#Proceed': {
    backgroundColor: 'rgba(0,0,0,0.87)',
    color: '#ffffff',
  },
});

const FinishedGoodConfirmModal = (props) => {
  const { contextData } = contextState();
  const dispatch = contextDispatch();

  const handleConfirm = (option) => {
    const defaultRun = {
      runId: null,
      itemId: '',
      itemName: '',
      numberOfLineWorkers: 0,
      lineNumber: 0,
      status: 'IN_PROGRESS',
    };
    switch (option) {
      case false:
        dispatch({
          contextData: {
            ...contextData,
            finishedGoods: {
              ...contextData.finishedGoods,
              activeRun: {
                ...contextData.finishedGoods.activeRun,
                status: 'IN_PROGRESS',
              },
            },
          },
          type: 'set',
        });
        break;
      case true:
        try {
          let finishedGoods = localStorage.getItem('scannedFinishedGoods')
            ? JSON.parse(localStorage.getItem('scannedFinishedGoods'))
            : [];
          // if any scans happened while modal was open, we have to create those finished goods here
          if (finishedGoods.length > 0) {
            CreateFinishedGood(finishedGoods);
            localStorage.setItem('scannedFinishedGoods', []);
          }
          switch (props.action) {
            case 'pause':
              SaveFinishedGoodRun({
                runId: contextData.finishedGoods.activeRun.runId,
                activeRunTime: parseInt(
                  localStorage.getItem('activeRunTime'),
                  10,
                ),
                status: 'PAUSED',
              }).then(() => {
                dispatch({
                  contextData: {
                    ...contextData,
                    finishedGoods: {
                      ...contextData.finishedGoods,
                      activeRun: {
                        ...defaultRun,
                        status: 'PAUSED',
                      },
                    },
                  },
                  type: 'set',
                });
              });
              break;
            case 'cancel':
              SaveFinishedGoodRun({
                runId: contextData.finishedGoods.activeRun.runId,
                activeRunTime: parseInt(
                  localStorage.getItem('activeRunTime'),
                  10,
                ),
                status: 'CANCELED',
              }).then(() => {
                dispatch({
                  contextData: {
                    ...contextData,
                    finishedGoods: {
                      ...contextData.finishedGoods,
                      activeRun: {
                        ...defaultRun,
                        status: 'CANCELED',
                      },
                    },
                  },
                  type: 'set',
                });
              });
              break;
            case 'end':
              SaveFinishedGoodRun({
                runId: contextData.finishedGoods.activeRun.runId,
                activeRunTime: parseInt(
                  localStorage.getItem('activeRunTime'),
                  10,
                ),
                status: 'COMPLETED',
              })
                .then((res) => {
                  toast.info('The run has been successfully ended and logged.');
                  dispatch({
                    contextData: {
                      ...contextData,
                      finishedGoods: {
                        ...contextData.finishedGoods,
                        activeRun: {
                          ...defaultRun,
                          status: 'COMPLETED',
                        },
                      },
                    },
                    type: 'set',
                  });
                })
                .catch((err) => {
                  if (err.errors) {
                    err.errors.forEach((error) => {
                      toast.error(error.message);
                    });
                  }
                });
          }
        } catch (e) {
          console.log(e);
          toast.error('Unable to complete action, please try again later');
        }
    }
  };
  return (
    <StyledSection>
      <div id="Confirm">
        <div id="Title">{props.title}</div>
        <div id="Details">{props.details}</div>
        {props.extraDetails && (
          <div id="ExtraDetails">{props.extraDetails}</div>
        )}
        {/* TODO: if textInput is provided, display a text input box (to add notes when run is ended) */}
        {props.cancel && props.proceed && (
          <div id="ConfirmButtons">
            <div
              id="Cancel"
              className="button"
              onClick={(e) => handleConfirm(false)}
            >
              {props.cancel}
            </div>
            <div
              id="Proceed"
              className="button"
              onClick={(e) => handleConfirm(true)}
            >
              {props.proceed}
            </div>
          </div>
        )}
      </div>
    </StyledSection>
  );
};

FinishedGoodConfirmModal.propTypes = {
  /* action: action user is trying to take, can be pause, cancel, end */
  action: PropTypes.string.isRequired,
  /* title: the main headline to be displayed in the modal */
  title: PropTypes.string.isRequired,
  /* details: provides additional info to the user about consequences of their action */
  details: PropTypes.string.isRequired,
  /* extraDetails: provides another layer of additional info for the user; used for displaying
    line run data */
  extraDetails: PropTypes.string,
  /* cancel: the text to be displayed in the button that aborts current action */
  cancel: PropTypes.string,
  /* proceed: the text to be displayed in the button that confirms current action */
  proceed: PropTypes.string,
  /* textInput: the placeholder text to be displayed in the input field, if the modal requires one */
  textInput: PropTypes.string,
};

FinishedGoodConfirmModal.defaultProps = {
  cancel: null,
  proceed: null,
  textInput: null,
};

export default FinishedGoodConfirmModal;
