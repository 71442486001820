import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CreateProductionLineItem from '../components/ProductionLineItem/CreateProductionLineItem';
import ProductionLineItemSummary from '../components/ProductionLineItem/ProductionLineItemSummary';
import ItemSubstitutionRuleList from '../components/ProductionLineItem/ItemSubstitutionRuleList';
import FinishedGoodScanning from '../components/ProductionLineItem/FinishedGoodScanning';
import { contextState } from '../ContextProvider';
// import CheckpointBulkUpload from '../components/ProductionLineItem/CheckpointBulkUpload';

const ProductionLineItem = ({ history }) => {
  const { contextData } = contextState();
  return (
    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
      <Tab eventKey={1} title="Create a Checkpoint">
        <br />
        <CreateProductionLineItem />
      </Tab>
      {/* <Tab eventKey={2} title="Checkpoint Bulk Upload"> */}
      {/*  <br /> */}
      {/*  <CheckpointBulkUpload /> */}
      {/* </Tab> */}
      <Tab eventKey={3} title="View Production Line Summary">
        <br />
        <ProductionLineItemSummary
          allowEditProductionLine={contextData.features.editProductionLine}
        />
      </Tab>
      {contextData.features.itemSubstitutions && (
        <Tab eventKey={4} title="Item Substitution Rules">
          <br />
          <ItemSubstitutionRuleList />
        </Tab>
      )}
      {contextData.features.finishedGoodTracking && (
        <Tab eventKey={5} title="Finished Good Scanning">
          <br />
          <FinishedGoodScanning />
        </Tab>
      )}
    </Tabs>
  );
};
ProductionLineItem.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProductionLineItem;
