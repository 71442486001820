/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Button from '../../atoms/Button';
import Input from '../../atoms/forms/Input';
import NumberTicker from '../../atoms/forms/NumberTicker';
import Breadcrumb from '../../atoms/Breadcrumb';
import Select from '../../atoms/forms/Select';
import styledComponent from '../../utils/styledComponent';
import { CreatePickPackLineItemInputMutation } from '../../libs/GraphQL.js';
import { toast } from 'react-toastify';

const StyledSection = styledComponent('section', {
  '& h1': {
    color: '#457A23',
  },
  '& h2': {
    color: '#565656',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  '& li': {
    listStyle: 'none',
  },
  '& .readOnlyLabel': {
    color: '#000',
    fontSize: '18px',
    fontWeight: '800',
    width: '250px',
  },
  '& .readOnlyValue': {
    '& a': {
      color: '#5F8E40',
      marginLeft: '10px',
    },
    color: '#818181',
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '20px',
  },
  '& ul': {
    margin: 0,
    padding: 0,
  },
  marginBottom: '50px',
});

const OLEAdd = ({ history }) => {
  const data = {};
  const [overrideDate, setOverrideDate] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  const AddSchema = Yup.object().shape({
    date: Yup.date().required('Required'),
    destinationRegion: Yup.string().required('Required'),
    line: Yup.string().required('Required'),
    productionFacility: Yup.string().required('Required'),
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((data) => {
      setCurrentUser(data.signInUserSession.idToken.payload.email);
    });
  }, []);

  return (
    <StyledSection>
      <h1>OLE</h1>
      <Breadcrumb
        items={[
          { link: '/ole/index', text: 'Data Entry' },
          { text: 'Add OLE Entry' },
        ]}
      />
      <h2>Adding OLE Entry</h2>
      <Formik
        initialValues={{
          date: data.date || moment().format('YYYY-MM-DD'),
          productionFacility: data.productionFacility || 'CHICAGO_1',
          destinationRegion: data.destinationRegion || 'MIDWEST',
          line: data.line || '1',
          linePopulation: data.linePopulation || 1,
          stackPopulation: data.stackPopulation || 1,
          notes: data.notes || '',
        }}
        validationSchema={AddSchema}
        onSubmit={async (values, { setSubmitting }) => {
          CreatePickPackLineItemInputMutation({
            owner: currentUser,
            ...values,
          })
            .then((res) => {
              const id = res.data.createPickPackLineItem.uniqueId;
              toast.success('OLE Entry Created');
              history.push(`/ole/edit/${id}`);
            })
            .catch((err) => {
              toast.error('Please try again later');
            });
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <form data-testid="locomotiveAdd-form" onSubmit={handleSubmit}>
              <ul>
                <li>
                  {overrideDate && (
                    <Input
                      errors={errors}
                      label="Date"
                      id="date"
                      type="text"
                      name="date"
                      placeholder="2019-11-14"
                    />
                  )}
                  {!overrideDate && (
                    <div>
                      <div className="readOnlyLabel">Date</div>
                      <div className="readOnlyValue">
                        {moment().format('YYYY-MM-DD')}
                        <a
                          data-testid="changeDate"
                          href="#"
                          onClick={() => {
                            setOverrideDate(true);
                          }}
                        >
                          Change Date
                        </a>
                      </div>
                    </div>
                  )}
                </li>
                <li>
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Production Facility"
                    id="productionFacility"
                    options={[{ value: 'CHICAGO_1', label: 'Chicago' }]}
                    onChange={(v) => {
                      setFieldTouched('productionFacility', true);
                      setFieldValue('productionFacility', v.value);
                    }}
                    value="CHICAGO_1"
                  />
                </li>
                <li>
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Destination"
                    id="destinationRegion"
                    options={[
                      { value: 'MIDWEST', label: 'Midwest' },
                      { value: 'NORTHEAST', label: 'Northeast' },
                    ]}
                    onChange={(v) => {
                      setFieldTouched('destinationRegion', true);
                      setFieldValue('destinationRegion', v.value);
                    }}
                    value="MIDWEST"
                  />
                </li>
                <li>
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Line"
                    id="line"
                    options={[
                      { value: '1', label: 'Line 1' },
                      { value: '2', label: 'Line 2' },
                    ]}
                    onChange={(v) => {
                      setFieldTouched('line', true);
                      setFieldValue('line', v.value);
                    }}
                    value="1"
                  />
                </li>
                <li>
                  <NumberTicker
                    label="Pickers"
                    name="linePopulation"
                    handleSelection={(value) => {
                      setFieldValue('linePopulation', value);
                    }}
                  />
                </li>
                <li>
                  <NumberTicker
                    label="Stackers"
                    name="stackPopulation"
                    handleSelection={(value) => {
                      setFieldValue('stackPopulation', value);
                    }}
                    setFieldValue={setFieldValue}
                  />
                </li>
                <li>
                  <Input
                    label="Notes"
                    id="notes"
                    type="text"
                    name="notes"
                    placeholder=""
                  />
                </li>
              </ul>
              <Button type="submit" data-testid="OLEAdd-submit">
                Add Entry
              </Button>
            </form>
          );
        }}
      </Formik>
    </StyledSection>
  );
};

OLEAdd.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OLEAdd;
