import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import Error from './containers/Error';
import NotFound from './containers/NotFound';
import AppliedRoute from './components/AppliedRoute';

import OrdersCreate from './components/ordersV2/OrdersCreate';
import OrdersBulkCreate from './components/ordersV2/OrdersBulkCreate';

import OLEDashboard from './components/OLE/OLEDashboard';
import OLEIndex from './components/OLE/OLEIndex';
import OLEAdd from './components/OLE/OLEAdd';
import OLEEdit from './components/OLE/OLEEdit';

import ViewProdReport from './containers/ViewPackingSlips';
import EditPackingSlip from './containers/EditPackingSlip';
import RunAllocation from './containers/RunAllocation';
import PickToLight from './containers/PickToLight';
import ProductionAdmin from './containers/ProductionAdmin';
import ReceivingInvoices from './containers/ReceivingInvoices';

import Orders from './containers/OrdersRouter';
import ProductionLineItem from './containers/ProductionLineItem';
import SetProductionDate from './containers/SetProductionDate';
import DownloadProductionCount from './containers/DownloadProductionCount';

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/home" exact component={Home} props={childProps} />

    <AppliedRoute
      path="/orders-v2/create"
      exact
      component={OrdersCreate}
      props={childProps}
    />
    <AppliedRoute
      path="/orders-v2/bulk-create"
      exact
      component={OrdersBulkCreate}
      props={childProps}
    />

    <AppliedRoute path="/ole/add" exact component={OLEAdd} props={childProps} />
    <AppliedRoute
      path="/ole/edit/:id"
      exact
      component={OLEEdit}
      props={childProps}
    />
    <AppliedRoute
      path="/ole/dashboard"
      exact
      component={OLEDashboard}
      props={childProps}
    />
    <AppliedRoute path="/ole/*" exact component={OLEIndex} props={childProps} />

    <AppliedRoute
      path="/orders/*"
      exact
      component={Orders}
      props={childProps}
    />
    <AppliedRoute
      path="/setproductiondate/*"
      exact
      component={SetProductionDate}
      props={childProps}
    />
    <AppliedRoute
      path="/productioncount/download"
      exact
      component={DownloadProductionCount}
      props={childProps}
    />

    <AppliedRoute
      path="/productionline"
      exact
      component={ProductionLineItem}
      props={childProps}
    />
    <AppliedRoute
      path="/productionline/*"
      exact
      component={ProductionLineItem}
      props={childProps}
    />
    <AppliedRoute
      path="/reports/allocation"
      exact
      component={ViewProdReport}
      props={childProps}
    />
    <AppliedRoute
      path="/reports/allocation/preview"
      exact
      key="preview"
      component={ViewProdReport}
      props={childProps}
    />
    <AppliedRoute
      path="/reports/editpackingslip"
      exact
      component={EditPackingSlip}
      props={childProps}
    />
    <AppliedRoute
      path="/allocation"
      exact
      component={RunAllocation}
      props={childProps}
    />
    <AppliedRoute
      path="/picktolight"
      exact
      component={PickToLight}
      props={childProps}
    />
    <AppliedRoute
      path="/production-admin"
      exact
      component={ProductionAdmin}
      props={childProps}
    />
    <AppliedRoute
      path="/receivinginvoices"
      exact
      component={ReceivingInvoices}
      props={childProps}
    />

    <AppliedRoute path="/Error" exact component={Error} props={childProps} />
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
