import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import AppWithAuth from './AppWithAuth';
import RobotoRegular from './assets/fonts/Roboto/Roboto-Regular.ttf';
//import registerServiceWorker from "./registerServiceWorker";
import './index.css';

const styles = css`
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(${RobotoRegular}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`;
ReactDOM.render(
  <Router>
    <Global styles={styles} />
    <AppWithAuth />
  </Router>,
  document.getElementById('root'),
);
//registerServiceWorker();
