import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import styledComponent from '../../utils/styledComponent';
import { UpdateProductionLineCoCMutation } from '../../libs/GraphQL';
import { lineOptions, lineLeadsOptions } from './constants';

const BodyWrapper = styledComponent('div', {
  '&': {
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  '& .row': {
    display: 'flex',
    padding: '10px 0',
    borderBottom: '1px solid #e6e6e6',
  },
  '& .rowTitle': {
    fontWeight: 'bold',
    paddingRight: '12px',
    width: '35%',
  },
  '& .rowContent': {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
    wordWrap: 'break-word',
    '& input': {
      width: '100%',
    },
    '& select': {
      width: '100%',
      height: '26px',
    },
    '& .error': {
      width: '100%',
      background: '#f2dede',
      color: '#a94442',
      fontWeight: 'bold',
      borderRadius: '4px',
      marginTop: '6px',
      padding: '6px 12px',
    },
  },
});

export default function EditProductionLineItemModal({
  item,
  onConfirm,
  onClose,
}) {
  const [formData, setFormData] = useState({ ...item });
  const [errorsObj, setErrorsObj] = useState({});
  const [loading, setLoading] = useState(false);

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasError = () => {
    return Object.values(errorsObj).some((value) => value !== '');
  };

  const isLineLeadMatchList = (value) => {
    const foundLineLead = lineLeadsOptions.find((lead) => lead.value === value);
    return !!foundLineLead;
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    validateForm();
  }, [formData.line, formData.lineLead]);

  const validateForm = () => {
    const errors = {};
    // `quantity` validation
    if (formData.quantity === '') {
      errors.quantity = 'Quantity can not be empty';
    } else if (!isNumber(formData.quantity)) {
      errors.quantity = 'Quantity must be a number';
    } else if (formData.quantity < 0) {
      errors.quantity = 'Quantity can not be less then 0';
    } else {
      errors.quantity = '';
    }

    // `line` validation
    if (formData.line === 'None') {
      errors.line = 'Line can not be empty';
    } else {
      errors.line = '';
    }

    // `lineLead` validation
    if (formData.lineLead === 'None' || formData.lineLead === '') {
      errors.lineLead = 'Line can not be empty';
    } else if (!isLineLeadMatchList(formData.lineLead)) {
      errors.lineLead = `Line Lead value '${formData.lineLead}' does not match the list`;
    } else {
      errors.lineLead = '';
    }

    // `linePopulation` validation
    if (formData.linePopulation === '') {
      errors.linePopulation = 'Line Population can not be empty';
    } else if (!isNumber(formData.linePopulation)) {
      errors.linePopulation = 'Line Population must be a number';
    } else if (formData.linePopulation < 0) {
      errors.linePopulation = 'Line Population can not be less then 0';
    } else {
      errors.linePopulation = '';
    }

    // `startTime` validation
    const startTimeMoment = moment(formData.startTime, 'HH:mm');
    const endTimeMoment = moment(formData.endTime, 'HH:mm');
    const startTimeRegMatch = formData.startTime.match(
      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
    );

    if (formData.startTime === '') {
      errors.startTime = 'Start Time can not be empty';
    } else if (!startTimeRegMatch || !startTimeMoment._isValid) {
      errors.startTime = 'Only HH:MM:SS format allowed';
    } else if (startTimeMoment.isAfter(endTimeMoment)) {
      errors.startTime = 'Start Time can not be after End Time';
    } else {
      errors.startTime = '';
    }

    // `endTime` validation
    const endTimeRegMatch = formData.endTime.match(
      /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
    );

    if (formData.endTime === '') {
      errors.endTime = 'End Time can not be empty';
    } else if (!endTimeRegMatch || !endTimeMoment._isValid) {
      errors.endTime = 'Only HH:MM:SS format allowed';
    } else if (endTimeMoment.isBefore(startTimeMoment)) {
      errors.endTime = 'End Time can not be before Start Time';
    } else {
      errors.endTime = '';
    }

    setErrorsObj(errors);
  };

  const handleConfirmEdit = async () => {
    setLoading(true);

    try {
      const response = await UpdateProductionLineCoCMutation([
        {
          uniqueId: formData.uniqueId,
          quantity: Number(formData.quantity),
          line: formData.line,
          lineLead: formData.lineLead,
          linePopulation: Number(formData.linePopulation),
          startTime: formData.startTime,
          endTime: formData.endTime,
        },
      ]);

      if (response.data.updateProductionLineCoC[0].success) {
        toast.success(response.data.updateProductionLineCoC[0].message, {
          className: 'prettyToast',
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'light',
        });
      } else {
        toast.error(response.data.updateProductionLineCoC[0].message, {
          className: 'prettyToast',
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'light',
        });
      }
    } catch (e) {
      console.error('UpdateProductionLineCoCMutation Error:', e);
    }

    setLoading(false);

    onConfirm();
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Edit Production Line Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BodyWrapper>
          <div className="row">
            <div className="rowTitle">Unique ID</div>
            <div className="rowContent">{item.uniqueId || '-'}</div>
          </div>
          <div className="row">
            <div className="rowTitle">Quantity</div>
            <div className="rowContent">
              <input
                type="number"
                name="quantity"
                key="quantityInput"
                value={formData.quantity}
                onChange={onFormChange}
                onBlur={validateForm}
              />
              {errorsObj.quantity && errorsObj.quantity !== '' && (
                <div className="error">{errorsObj.quantity}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="rowTitle">Line</div>
            <div className="rowContent">
              <select value={formData.line} name="line" onChange={onFormChange}>
                {lineOptions.map((line) => (
                  <option value={line.value} key={item.label}>
                    {line.label}
                  </option>
                ))}
              </select>
              {errorsObj.line && errorsObj.line !== '' && (
                <div className="error">{errorsObj.line}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="rowTitle">Line Lead</div>
            <div className="rowContent">
              <select
                value={formData.lineLead}
                name="lineLead"
                onChange={onFormChange}
              >
                {lineLeadsOptions.map((option) => (
                  <option value={option.value} key={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>

              {errorsObj.lineLead && errorsObj.lineLead !== '' && (
                <div className="error">{errorsObj.lineLead}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="rowTitle">Line Population</div>
            <div className="rowContent">
              <input
                type="number"
                name="linePopulation"
                key="linePopulationInput"
                value={formData.linePopulation}
                onChange={onFormChange}
                onBlur={validateForm}
              />
              {errorsObj.linePopulation && errorsObj.linePopulation !== '' && (
                <div className="error">{errorsObj.linePopulation}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="rowTitle">Start Time</div>
            <div className="rowContent">
              <input
                type="text"
                name="startTime"
                key="startTimeInput"
                value={formData.startTime}
                onChange={onFormChange}
                onBlur={validateForm}
              />
              {errorsObj.startTime && errorsObj.startTime !== '' && (
                <div className="error">{errorsObj.startTime}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="rowTitle">End Time</div>
            <div className="rowContent">
              <input
                type="text"
                name="endTime"
                key="endTimeInput"
                value={formData.endTime}
                onChange={onFormChange}
                onBlur={validateForm}
              />
              {errorsObj.endTime && errorsObj.endTime !== '' && (
                <div className="error">{errorsObj.endTime}</div>
              )}
            </div>
          </div>
        </BodyWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => handleConfirmEdit()}
          disabled={hasError() || loading}
        >
          {loading ? 'Loading...' : 'Confirm'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

EditProductionLineItemModal.propTypes = {
  item: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditProductionLineItemModal.defaultProps = {
  item: {},
};
