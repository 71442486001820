import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BaseTable, { Column } from 'react-base-table';
import 'react-base-table/styles.css';
import { ToastContainer } from 'react-toastify';
import { useAsyncCallback } from 'react-async-hook';
import Button from '../../atoms/Button';
import Loading from '../../atoms/Loading';
import styledComponent from '../../utils/styledComponent';
import { ReadPickPackLineItem } from '../../libs/GraphQL';

const StyledSection = styledComponent('section', {
  '& a': {
    color: '#5F8E40',
  },
  '& header': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  '& h1': {
    color: '#457A23',
  },
  '& h2': {
    color: '#565656',
    fontSize: '20px',
    margin: 0,
  },
});

const sortItems = (items) => {
  return items.sort((a, b) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  });
};

const OLEIndex = ({ history }) => {
  const [data, setData] = useState();
  const [sortBy, setSortBy] = useState({ key: 'date', order: 'desc' });
  const [pickPackData, setPickPackData] = useState({
    data: { pickPackLineItemSummary: [] },
  });
  const pickPackDataCollection = useAsyncCallback(async (filteredItem) => {
    const result = await ReadPickPackLineItem('');
    setData(sortItems(result.data.pickPackLineItemSummary));
    setPickPackData(result);
  });

  const sortArrayOfObjects = (arr, key, order) => {
    return arr.sort((a, b) => {
      if (order === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
  };

  const onColumnSort = (sortByVal) => {
    const sortedData = sortArrayOfObjects(data, sortByVal.key, sortByVal.order);
    setSortBy(sortByVal);
    setData(sortedData);
  };

  useEffect(() => {
    if (pickPackDataCollection.status === 'not-requested') {
      pickPackDataCollection.execute();
    }
  }, [pickPackDataCollection, pickPackData]);

  const linkFormatter = ({ rowData }) => (
    <Link data-testid={rowData.uniqueId} to={`/ole/edit/${rowData.uniqueId}`}>
      {rowData.date}
    </Link>
  );
  return (
    <StyledSection>
      <ToastContainer />
      <h1>OLE</h1>
      {/* <PageNav>
        <PageNavItem href="/ole/dashboard">Dashboard</PageNavItem>
        <PageNavItem href="/ole/" isActive={true}>
          Data Entry
        </PageNavItem>
      </PageNav> */}
      <header>
        <h2>OLE Entries</h2>
        <Button
          data-testid="oleAddBtn"
          onClick={() => history.push('/ole/add')}
        >
          Add Entry
        </Button>
      </header>
      {pickPackDataCollection.loading && <Loading />}
      {pickPackDataCollection.error && (
        <p>An error has occured, please try again later.</p>
      )}
      {!pickPackDataCollection.loading && (
        <BaseTable
          onColumnSort={onColumnSort}
          sortBy={sortBy}
          data={data}
          width={1138}
          height={600}
          rowKey="uniqueId"
        >
          <Column
            title="Date"
            key="date"
            dataKey="date"
            cellRenderer={linkFormatter}
            sortable
            width={120}
          />
          <Column title="Line" key="line" dataKey="line" sortable width={100} />
          <Column
            title="Destination"
            key="destinationRegion"
            dataKey="destinationRegion"
            sortable
            width={120}
          />
          <Column
            title="Pickers"
            key="linePopulation"
            dataKey="linePopulation"
            sortable
            width={100}
          />
          <Column
            title="Stackers"
            key="stackPopulation"
            dataKey="stackPopulation"
            sortable
            width={100}
          />
          <Column
            title="Owner"
            key="owner"
            dataKey="owner"
            sortable
            width={200}
          />
        </BaseTable>
      )}
    </StyledSection>
  );
};

OLEIndex.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OLEIndex;
