import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import DownloadDeliveryCount from '../components/ProductionScheduling/DownloadDeliveryCount';
import UploadProductionDate from '../components/ProductionScheduling/UploadProductionDate';
import UploadProductionPlan from '../components/ProductionScheduling/UploadProductionPlan';
import ExportProductionPlan from '../components/ProductionScheduling/ExportProductionPlan';
import UpdateHoldoverQuantities from '../components/ProductionScheduling/UpdateHoldoverQuantities';
import { contextState } from '../ContextProvider';

const SetProductionDate = () => {
  const { contextData } = contextState();
  return (
    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
      <Tab eventKey={1} title="Download Delivery Count">
        <br />
        <DownloadDeliveryCount />
      </Tab>
      <Tab eventKey={2} title="Upload Production Dates">
        <br />
        <UploadProductionDate />
      </Tab>
      <Tab eventKey={3} title="Upload Production Plan">
        <br />
        <UploadProductionPlan />
      </Tab>
      <Tab eventKey={4} title="Export Production Plan">
        <br />
        <ExportProductionPlan />
      </Tab>
      {contextData.features.updateHoldoverQuantity && (
        <Tab eventKey={5} title="Update Holdover Quantities">
          <br />
          <UpdateHoldoverQuantities />
        </Tab>
      )}
    </Tabs>
  );
};

export default SetProductionDate;
