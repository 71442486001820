import React, { Component } from 'react';
import { Grid, Modal, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { yyyymmdd } from '../../libs/date_convert';
import { Order } from '../../libs/GraphQL';
import LoaderButton from '../LoaderButton';

const columns = [
  {
    dataField: 'globalId',
    text: 'Global Id',
  },
  {
    dataField: 'uniqueId',
    text: 'Order ID',
  },
  {
    dataField: 'orderUploadId',
    text: 'Upload Id',
    filter: textFilter(),
  },
  {
    dataField: 'orderOwner',
    text: 'Order owner',
  },
  {
    dataField: 'orderType',
    text: 'Order type',
  },
  {
    dataField: 'locationId',
    text: 'Location',
  },
  {
    dataField: 'addressForDelivery',
    text: 'Address',
    filter: textFilter(),
  },
  {
    dataField: 'itemName',
    text: 'Item Name',
  },
  {
    dataField: 'count',
    text: 'Count',
  },
  {
    dataField: 'productionDate',
    text: 'Production Date',
  },
  {
    dataField: 'deliveryDate',
    text: 'Delivery Date',
  },
  {
    dataField: 'dropOffDate',
    text: 'Drop Off Date',
  },
  {
    dataField: 'clientCode',
    text: 'Client Code',
  },
  {
    dataField: 'clientDetails',
    text: 'Client Details',
  },
  {
    dataField: 'billingType',
    text: 'Billing Type',
  },
  {
    dataField: 'market',
    text: 'Market',
  },
  {
    dataField: 'deliverySpecialNotes',
    text: 'Delivery Special Notes',
  },
  {
    dataField: 'jitEligible',
    text: 'JIT Eligible',
  },
  {
    dataField: 'rebalanceEligible',
    text: 'Rebalance Eligible',
  },
];

const fileColumns = columns.map((col) => ({
  label: col.text,
  key: col.dataField,
}));

export default class OrderByDateRange extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      currentDelStartDate: new Date(),
      currentDelEndDate: new Date(),
      searchDelStartDate: yyyymmdd(null, '-'),
      searchDelEndDate: yyyymmdd(null, '-'),
      isLoading: false,
      alertModal: {
        text: '',
        mode: false,
      },
      toDownload: [],
      showPicker: true,
    };
    this.handleDelStartDateChange = this.handleDelStartDateChange.bind(this);
    this.handleDelEndDateChange = this.handleDelEndDateChange.bind(this);
    this.getOrdersByDate = this.getOrdersByDate.bind(this);
    this.flattenOrders = this.flattenOrders.bind(this);
  }

  handleDelStartDateChange(value) {
    this.setState({
      searchDelStartDate: yyyymmdd(value, '-'),
      currentDelStartDate: value,
    });
  }

  handleDelEndDateChange(value) {
    this.setState({
      searchDelEndDate: yyyymmdd(value),
      currentDelEndDate: value,
    });
  }

  flattenOrders(orderState) {
    const toReturn = [];
    for (const idx in orderState) {
      if (
        orderState[idx].orderType === 'INTERNAL' &&
        orderState[idx].clientCode !== 'Sales' &&
        orderState[idx].clientCode !== 'Marketing'
      ) {
        continue;
      }
      const newItem = {
        uniqueId: orderState[idx].uniqueId,
        orderOwner: orderState[idx].orderOwner,
        orderType: orderState[idx].orderType,
        addressForDelivery: orderState[idx].addressForDelivery,
        deliveryDate: orderState[idx].deliveryDate,
        dropOffDate: orderState[idx].dropOffDate,
        clientCode: orderState[idx].clientCode,
        clientDetails: orderState[idx].clientDetails,
        billingType: orderState[idx].billingType,
        market: orderState[idx].market,
        deliverySpecialNotes: orderState[idx].deliverySpecialNotes,
        orderUploadId: orderState[idx].orderUploadId,
      };
      if (newItem.deliverySpecialNotes !== null) {
        newItem.deliverySpecialNotes = newItem.deliverySpecialNotes.replaceAll(
          ',',
          '',
        );
      }
      for (const itemIdx in orderState[idx].itemsCount) {
        console.log('itemIdx', itemIdx, orderState[idx]);
        const curItem = JSON.parse(JSON.stringify(newItem));
        const curItemName = orderState[idx].itemsCount[itemIdx].itemName;
        curItem.itemName = curItemName;
        curItem.count = orderState[idx].itemsCount[itemIdx].count;
        curItem.productionDate =
          orderState[idx].itemsCount[itemIdx].productionDate;
        curItem.globalId = `${orderState[idx].uniqueId}-${curItemName}`;
        curItem.jitEligible = String(
          orderState[idx].jitAllocationItems?.includes(curItemName) || false,
        );
        curItem.rebalanceEligible = String(
          orderState[idx].orderRebalanceItems?.includes(curItemName) || false,
        );

        toReturn.push(curItem);
      }
    }

    return toReturn;
  }

  async getOrdersByDate() {
    this.setState({ isLoading: true });
    const dateArray = [];
    let loop = new Date(this.state.currentDelStartDate);
    while (loop <= this.state.currentDelEndDate) {
      loop.setHours(0, 0, 0, 0);
      dateArray.push(yyyymmdd(loop, '-'));
      const newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
    let downloaded = [];
    for (const d in dateArray) {
      const delDate = dateArray[d];
      let order = await Order(null, null, delDate);
      downloaded = downloaded.concat(order.data.ordersByDay.orders);
      while (order.data.ordersByDay.nextToken !== null) {
        order = await Order(order.data.ordersByDay.nextToken, null, delDate);
        downloaded = downloaded.concat(order.data.ordersByDay.orders);
      }
    }
    this.setState({
      toDownload: this.flattenOrders(downloaded),
      isLoading: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.showPicker && (
          <Grid>
            <Row className="text-center" style={{ marginBottom: '15px' }}>
              <Col sm={6}>
                <h3>Delivery Start Date</h3>
                <DatePicker
                  onChange={this.handleDelStartDateChange}
                  value={this.state.currentDelStartDate}
                />
              </Col>
              <Col>
                <h3>Delivery End Date</h3>
                <DatePicker
                  onChange={this.handleDelEndDateChange}
                  value={this.state.currentDelEndDate}
                />
              </Col>
            </Row>
          </Grid>
        )}
        <div className="Locations">
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{this.state.alertModal.text}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  const alertModal = this.state.alertModal;
                  alertModal.mode = false;
                  this.setState({ alertModal });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <div style={{ overflowX: 'auto' }}>
            <BootstrapTable
              keyField="globalId"
              data={this.state.toDownload}
              columns={columns}
              filter={filterFactory()}
              pagination={paginationFactory()}
            />
          </div>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            onClick={this.getOrdersByDate}
            type="submit"
            isLoading={this.state.isLoading}
            text="Fetch Data"
            loadingText="Fetching Data..."
          />
          <div>
            <Row className="text-center">
              <Col>
                {this.state.toDownload.length > 0 && (
                  <h2>
                    <CSVLink
                      data={this.state.toDownload}
                      headers={fileColumns}
                      filename={`Orders_${this.state.searchDelStartDate} to ${this.state.searchDelEndDate}.csv`}
                    >
                      Download Orders
                    </CSVLink>
                  </h2>
                )}
                {this.state.toDownload.length === 0 && (
                  <h2>No Data fetched to download</h2>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
