import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import OrderFridgeMassUpdate from './OrderFridgeMassUpdate';
import styledComponent from '../../utils/styledComponent';
import OrderWholesaleMassUpdate from './OrderWholesaleMassUpdate';

const ToggleWrapper = styledComponent('h4', {
  '& label': {
    fontSize: '18px',
    padding: '8px 24px',
    marginBottom: '12px',
  },
});

const OrdersMassUpdate = () => {
  const [orderType, setOrderType] = useState('fridge');

  return (
    <>
      <h2>Order Mass Update</h2>

      <ToggleWrapper>
        <ToggleButtonGroup
          type="radio"
          name="options"
          value={orderType}
          onChange={(e) => setOrderType(e)}
        >
          <ToggleButton value="fridge">Fridge</ToggleButton>
          <ToggleButton value="wholesale">Wholesale</ToggleButton>
        </ToggleButtonGroup>
      </ToggleWrapper>

      {orderType === 'fridge' && <OrderFridgeMassUpdate />}
      {orderType === 'wholesale' && <OrderWholesaleMassUpdate />}
    </>
  );
};

export default OrdersMassUpdate;
