/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';
import styledComponent from '../../utils/styledComponent';
import ErrorMessage from './ErrorMessage';

const StyledDiv = styledComponent('div', {
  '& .SingleDatePickerInput__withBorder': {
    border: 'none',
  },
  '& .SingleDatePicker': {
    display: 'block',
  },
  '& input': {
    '&:-webkit-autofill': {
      background: '#FFF',
    },
    background: '#FFF',
    border: `1px solid #CCC`,
    borderRadius: '4px',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    marginTop: '10px',
    padding: '10px',
    width: '250px',
  },
  '& label': {
    color: '#000',
    display: 'block',
    fontSize: '18px',
    fontWeight: '800',
  },
  marginBottom: '20px',
});

const DatePicker = ({ currentDate, label, error, onChange, isDayBlocked }) => {
  const [date, setDate] = useState(currentDate);
  const [isDateFocused, setIsDateFocused] = useState(false);
  return (
    <StyledDiv className="dateWrapper">
      <label htmlFor={label}>
        {label}
        <SingleDatePicker
          date={date}
          isOutsideRange={() => false}
          onDateChange={(newDate) => {
            setDate(newDate);
            onChange(newDate);
          }}
          isDayBlocked={isDayBlocked}
          focused={isDateFocused}
          onFocusChange={({ focused }) => setIsDateFocused(focused)}
          id="dateSelection"
        />
      </label>
      {error && (
        <ErrorMessage message="Required and must be yyyy-mm-dd format" />
      )}
    </StyledDiv>
  );
};

DatePicker.propTypes = {
  currentDate: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDayBlocked: PropTypes.func,
};

DatePicker.defaultProps = {
  currentDate: moment(),
  error: null,
};

export default DatePicker;
