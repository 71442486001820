import React from 'react';
import styledComponent from '../../utils/styledComponent';
import { contextState, contextDispatch } from '../../ContextProvider';
import { SaveFinishedGoodRun } from '../../libs/GraphQL.js';

const StyledSection = styledComponent('section', {});

const FinishedGoodScanningPaused = (props) => {
  const { contextData } = contextState();
  const dispatch = contextDispatch();

  const getItemName = (itemId) => {
    let item = props.items.find((i) => i.id === itemId);
    if (item) {
      return item.prettyName;
    }
    return null;
  };

  const handleResumeRun = (runId) => {
    const response = SaveFinishedGoodRun({
      runId: runId,
      status: 'IN_PROGRESS',
    });
    response.then((json) => {
      dispatch({
        contextData: {
          ...contextData,
          finishedGoods: {
            ...contextData.finishedGoods,
            activeRun: {
              runId: json.data.saveFinishedGoodRun.runId,
              itemId: json.data.saveFinishedGoodRun.itemId,
              itemName: getItemName(json.data.saveFinishedGoodRun.itemId),
              numberOfLineWorkers: json.data.saveFinishedGoodRun.lineWorkers,
              lineNumber: json.data.saveFinishedGoodRun.lineNumber,
              status: 'IN_PROGRESS',
            },
          },
        },
        type: 'set',
      });
      localStorage.setItem(
        'activeRunTime',
        json.data.saveFinishedGoodRun.activeRunTime,
      );
      localStorage.setItem('scannedFinishedGoods', []);
    });
  };

  return (
    <StyledSection>
      <table id="PausedRunDetailsTable">
        <h3 id="PausedRunsTitle">Paused Runs</h3>
        <tr>
          <th>Date</th>
          <th>Product</th>
          <th>Number of Line Workers</th>
          <th>Line Number</th>
          <th>Items Produced</th>
          <th></th>
        </tr>
        {contextData.finishedGoods.pausedRuns.map((run) => {
          return (
            <tr>
              <td>{run.productionDate}</td>
              <td>{getItemName(run.itemId)}</td>
              <td>{run.lineWorkers}</td>
              <td>{run.lineNumber}</td>
              <td>{run.amountProduced}</td>
              <td>
                <button
                  id="ResumeButton"
                  onClick={(e) => handleResumeRun(run.runId)}
                >
                  Resume Run
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </StyledSection>
  );
};

export default FinishedGoodScanningPaused;
