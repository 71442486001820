import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import UphPerSku from '../components/ProductionAdmin/UphPerSku';

import { GetProdItemWorkersUPH, UpdateItemWorkersUPH } from '../libs/GraphQL';
import { contextState } from '../ContextProvider';
import Select from '../atoms/forms/Select';

const ProductionAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [liveItems, setLiveItems] = useState([]);
  const { contextData } = contextState();
  const [selectedSku, setSelectedSku] = useState('');
  const [shouldUpdateValue, setShouldUpdateValue] = useState(false);
  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const promises = data.map((element) => {
        return UpdateItemWorkersUPH({
          itemName: selectedSku.value,
          lineWorkers: element.lineWorkers,
          unitsPerHour: element.unitsPerHour,
        });
      });

      await Promise.all(promises);
      toast.success('UPH Values Updated Successfully!');
    } catch (error) {
      toast.error('UPH Values Update FAILED!');
      console.error('Failed to update items:', error);
    }
    setShouldUpdateValue(true);
  };
  useEffect(() => {
    if (!contextData.features.productionAppAdminMgmt) {
      window.location.href = '/';
    }
    const getData = async () => {
      setShouldUpdateValue(false);
      setLoading(true);
      try {
        await GetProdItemWorkersUPH({}).then((res) => {
          setData(res?.data?.getItemWorkersUPH);
          setLoading(false);
        });
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    try {
      getData();
    } catch (e) {
      console.error(e);
    }
  }, [contextData.features.productionAppAdminMgmt, shouldUpdateValue]);

  useEffect(() => {
    if (data.length) {
      const items = data.map((item) => item.itemName);
      const noDupes = [...new Set(items)];
      setLiveItems(noDupes.map((item) => ({ value: item, label: item })));
    }
  }, [data]);

  return (
    <>
      <h1>Production App Admin Management </h1>
      <br />
      <Select
        errors=""
        touched="touched"
        label="Select SKU"
        id="itemsPerPage"
        options={liveItems}
        onChange={(v) => {
          setSelectedSku(v);
        }}
        value={10}
      />
      {selectedSku && (
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title="UPH Per SKU">
            <UphPerSku
              data={data}
              selectedSku={selectedSku}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={2} title="Optimal Population Per Sku" />
          <Tab eventKey={3} title="Line Position Count" />
        </Tabs>
      )}
    </>
  );
};

export default ProductionAdmin;
