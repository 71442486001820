import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../utils/styledComponent';

const ErrorsWrapper = styledComponent('section', {
  margin: '0 0 40px 0',
  fontSize: '16px',
  border: '1px solid #D0021A',
  '& ul': {
    '& li': {
      marginBottom: '16px',
    },
    margin: '8px',
  },
});

const Title = styledComponent('div', {
  background: '#D0021A',
  color: '#FFF',
  display: 'flex',
  fontSize: '22px',
  height: '50px',
  padding: '16px',
  width: '100%',
  lineHeight: '0.8',
});

const Description = styledComponent('div', {
  margin: '8px 16px',
  fontWeight: 'bold',
});

const ErrorsBlock = ({ title, description, children }) => {
  return (
    <ErrorsWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </ErrorsWrapper>
  );
};
ErrorsBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ErrorsBlock.defaultProps = {
  title: 'Validation Errors',
  description: 'Please fix the following errors and reupload your file:',
};

export default ErrorsBlock;
