/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import PropTypes from 'prop-types';

const ContextStateContext = React.createContext();
const ContextDispatchContext = React.createContext();

function contextReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return {
        contextData: action.contextData ? action.contextData : null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function ContextProvider({ children, defaultValues }) {
  const [state, dispatch] = React.useReducer(contextReducer, defaultValues);
  return (
    <ContextStateContext.Provider value={state}>
      <ContextDispatchContext.Provider value={dispatch}>
        {children}
      </ContextDispatchContext.Provider>
    </ContextStateContext.Provider>
  );
}
function contextState() {
  const context = React.useContext(ContextStateContext);
  if (context === undefined) {
    throw new Error('contextState must be used within a ContextProvider');
  }
  return context;
}
function contextDispatch() {
  const context = React.useContext(ContextDispatchContext);
  if (context === undefined) {
    throw new Error('contextDispatch must be used within a ContextProvider');
  }
  return context;
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.shape({}),
};

// provide enough default data to avoid errors, useful for local dev
ContextProvider.defaultProps = {
  defaultValues: {
    contextData: {
      errors: {
        fridgeTasks: [],
      },
      features: {},
      fridge: {},
      items: null,
      locations: { data: { locations: { locations: [] } } },
      stocking: {
        data: null,
        issueItems: {},
        sorted: [],
      },
      finishedGoods: {
        activeRun: {
          runId: null,
          itemId: '',
          itemName: '',
          numberOfLineWorkers: 0,
          lineNumber: 0,
          activeRunTime: 0,
          status: 'IN_PROGRESS',
        },
        pausedRuns: [],
      },
    },
  },
};

export { ContextProvider, contextState, contextDispatch };
