import React, { Component } from 'react';
import { Modal, Row, Form, FormGroup, Col, Button } from 'react-bootstrap';
import CreateItemSubstitutionRule from './CreateItemSubstitutionRule.js';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { ListActiveItemSubstitutionRules } from '../../libs/GraphQL.js';
import { sortArrayOfObjects } from '../../libs/utility';

export default class ItemSubstitutionRuleList extends Component {
  constructor(props) {
    super(props);
    this.openSubmitModal = this.openSubmitModal.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
    this.closeAndRefreshSubmitModal =
      this.closeAndRefreshSubmitModal.bind(this);
    this.state = {
      itemSubstitutionRules: [],
      newItemSubstitutionRule: null,
      submitModal: {
        show: false,
      },
      alertModal: {
        mode: false,
        text: [],
      },
    };
  }
  async componentDidMount() {
    this.getActiveItemSubstitutionRules();
  }

  async getActiveItemSubstitutionRules() {
    try {
      let itemSubstitutionRulesRaw = await ListActiveItemSubstitutionRules();

      this.setState({
        itemSubstitutionRules: sortArrayOfObjects(
          itemSubstitutionRulesRaw.data.listItemSubstitutionRules,
          'expirationDate',
          'asc',
        ),
      });
    } catch (e) {
      console.log(e);
      let alertModal = this.state.alertModal;
      alertModal['mode'] = true;
      alertModal['text'] = [
        'Unable to load the Item Substitution Rules, Please check your internet and your permissions',
      ];
      this.setState({ alertModal: alertModal });
    }
  }

  async openSubmitModal() {
    let subModal = this.state.submitModal;
    subModal['show'] = true;
    this.setState({ submitModal: subModal });
  }

  async closeSubmitModal() {
    let subModal = this.state.submitModal;
    subModal['show'] = false;
    this.setState({ submitModal: subModal });
  }

  async closeAndRefreshSubmitModal() {
    this.closeSubmitModal();
    this.getActiveItemSubstitutionRules();
  }

  render() {
    let alertRows = [];
    let alertMessages = this.state.alertModal.text;
    alertRows = alertMessages.map((text) => (
      <div key={text}>
        <p>{text}</p>
        <br />
      </div>
    ));

    return (
      <div>
        {this.state.submitModal.show && (
          <Modal show={true}>
            <Modal.Body>
              <CreateItemSubstitutionRule
                handleClose={this.closeAndRefreshSubmitModal}
              />
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  this.closeSubmitModal();
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.alertModal.mode && (
          <Modal show={this.state.alertModal.mode}>
            <Modal.Body>
              <div className="form-group">{alertRows}</div>
            </Modal.Body>
            <Modal.Footer>
              &nbsp;
              <Button
                bsStyle="warning"
                onClick={() => {
                  var alertModal = this.state.alertModal;
                  alertModal['mode'] = false;
                  this.setState({ alertModal: alertModal });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <h2>Active Item Substitution Rules</h2>
        <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>
        <Form>
          <FormGroup>
            <Col smOffset={10} sm={10}>
              <Button onClick={this.openSubmitModal}>Create New Rule</Button>
            </Col>
          </FormGroup>
        </Form>
        <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <BootstrapTable
              keyField="id"
              data={this.state['itemSubstitutionRules']}
              columns={[
                {
                  dataField: 'originalItemDisplayName',
                  text: 'Original Item',
                  editable: false,
                },
                {
                  dataField: 'substitutionItemDisplayName',
                  text: 'Substituted Item',
                  editable: false,
                },
                {
                  dataField: 'creatorName',
                  text: 'Created By',
                  editable: false,
                },
                {
                  dataField: 'expirationDate',
                  text: 'Rule Expiration Date',
                  editable: false,
                },
              ].filter((item) => {
                return true;
              })}
              filter={filterFactory()}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
