import React from 'react';

export function sortArrayOfObjects(arr, key, order) {
  return arr.sort((a, b) => {
    if (order === 'asc') {
      return a[key] > b[key] ? 1 : -1;
    }
    return a[key] < b[key] ? 1 : -1;
  });
}
