export function yyyymmdd(value, delimeter) {
  delimeter = delimeter || '';
  let now = value || new Date();

  function twoDigit(n) {
    return (n < 10 ? '0' : '') + n;
  }
  return (
    '' +
    now.getFullYear() +
    delimeter +
    twoDigit(now.getMonth() + 1) +
    delimeter +
    twoDigit(now.getDate())
  );
}
