import React, { Component } from 'react';
import queryString from 'query-string';
import { Switch } from 'react-router-dom';
import AppliedRoute from '../components/AppliedRoute';
import NotFound from './NotFound';
import SingularOrder from '../components/Orders/ViewOrder';
import CreateOrder from '../components/Orders/CreateOrder';
import ListOrders from '../components/Orders/ListOrders';
import UploadBulkOrders from '../components/Orders/UploadBulkOrders';
import OrdersMassUpdate from '../components/Orders/OrdersMassUpdate';
import OrdersByDateRange from '../components/Orders/OrdersByDateRange';
import { Order, OrderMeta, Options, Items, LocationIDs } from '../libs/GraphQL';

/* eslint-disable react/prop-types,react/no-unused-state */

class OrdersRouter extends Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    const userEmail =
      this.props.userEmail === undefined ? '' : this.props.userEmail;

    this.state = {
      allOrders: [],
      allItems: [],
      options: [],
      locations: [],
      key: 1,
      filterQuery: values.filter || '',
      searchQuery: values.search ? values.search : userEmail,
      orderType: 'META',
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.searchOrders = this.searchOrders.bind(this);
    this.downloadOrders = this.downloadOrders.bind(this);
  }

  handleSelect(key) {
    this.setState({ key });
  }

  // eslint-disable-next-line consistent-return
  async downloadOrders(order_type = 'META') {
    let orderFunc;

    if (order_type === 'META' && this.state.allOrders.length > 0) {
      return null;
    }
    if (this.state.orderType === 'FULL' && this.state.allOrders.length > 0) {
      return null;
    }

    if (order_type !== 'META') {
      orderFunc = Order;
    } else {
      orderFunc = OrderMeta;
    }

    let order = await orderFunc(null);
    let orderState = order.data.ordersByDay.orders;
    while (order.data.ordersByDay.nextToken !== null) {
      // eslint-disable-next-line no-await-in-loop
      order = await orderFunc(order.data.ordersByDay.nextToken);
      orderState = orderState.concat(order.data.ordersByDay.orders);
    }
    this.setState({
      orderType: order_type,
      allOrders: orderState.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.deliveryDate > b.deliveryDate
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          a.deliveryDate === b.deliveryDate
          ? a.deliveryDate > b.deliveryDate
            ? 1
            : -1
          : -1,
      ),
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.userEmail !== prevProps.userEmail) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchQuery: this.props.userEmail });
    }
  }

  async componentDidMount() {
    const items = await Items();
    this.setState({ allItems: items.data.items });
    const options = await Options();
    this.setState({ options });
    let locationIds = await LocationIDs();
    locationIds = locationIds.data.locations.locations;
    locationIds.push({ id: 'KITCHEN' });
    locationIds.push({ id: '' });
    this.setState({ locations: locationIds });
  }

  updateQuery(e) {
    this.setState({ searchQuery: e.target.value });
  }

  searchOrders(order) {
    const searchFields = [
      'clientCode',
      'locationId',
      'orderOwner',
      'deliveryDate',
      'addressForDelivery',
      'uniqueId',
    ];
    const queries = this.state.searchQuery.split(';');
    for (let j = 0; j < queries.length; j++) {
      const query = queries[j];
      for (let i = 0; i < searchFields.length; i++) {
        if (
          order[searchFields[i]] !== null &&
          order[searchFields[i]].toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
      }
    }

    return false;
  }

  render() {
    return (
      <Switch>
        <AppliedRoute
          path="/orders/order_id/*"
          component={SingularOrder}
          props={{
            items: this.state.allItems,
            locations: this.state.locationIds,
          }}
        />
        <AppliedRoute
          path="/orders/create"
          exact
          component={CreateOrder}
          props={{
            items: this.state.allItems,
            locations: this.state.locations,
          }}
        />
        <AppliedRoute
          path="/orders/list"
          exact
          component={ListOrders}
          props={{
            items: this.state.allItems,
            locations: this.state.locations,
            orders: this.state.allOrders.filter(this.searchOrders),
            downloadOrders: this.downloadOrders,
            updateQuery: this.updateQuery,
            searchQuery: this.state.searchQuery,
            filterQuery: this.state.filterQuery,
          }}
        />
        <AppliedRoute
          path="/orders/upload"
          exact
          component={UploadBulkOrders}
        />
        <AppliedRoute
          path="/orders/massupdate"
          exact
          component={OrdersMassUpdate}
        />
        {/* Route to UpdateBulkOrders component commented as it hidden from Menu */}

        {/* <AppliedRoute */}
        {/*  path="/orders/catering/update" */}
        {/*  exact */}
        {/*  component={UpdateBulkOrders} */}
        {/* /> */}
        {/* <AppliedRoute path="/orders/totals" exact component={TotalOrders} props={{
                  items: this.state.allItems,
                  downloadOrders:this.downloadOrders,
                  orders:this.state.allOrders,
                  orderType:this.state.orderType}}/> */}
        <AppliedRoute
          path="/orders/download"
          exact
          component={OrdersByDateRange}
        />
        <AppliedRoute path="/orders/*" exact component={NotFound} />
      </Switch>
    );
  }
}

export default OrdersRouter;
