/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styledComponent from '../../utils/styledComponent';
import ErrorMessage from './ErrorMessage';

const StyledDiv = styledComponent('div', {
  '& input': {
    '&:-webkit-autofill': {
      background: '#FFF',
    },
    background: '#FFF',
    border: `1px solid #CCC`,
    borderRadius: '4px',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    marginTop: '10px',
    padding: '10px',
    width: '250px',
  },
  '& label': {
    color: '#000',
    display: 'block',
    fontSize: '18px',
    fontWeight: '800',
  },
  marginBottom: '20px',
});

const validateTime = (value) => {
  return moment(`2020-01-01 ${value}`, 'YYYY-MM-DD HH:mm:ss', true).isValid();
};

const TimePicker = ({ label, name, onChange, value }) => {
  const [timeFormatError, setTimeFormatError] = useState(false);
  const [timeValue, setTimeValue] = useState(value);
  return (
    <StyledDiv>
      <label htmlFor={name}>
        {label}
        <Field
          autoComplete="off"
          id={name}
          type="text"
          name={name}
          onBlur={(e) => {
            if (e.target.value.length > 0) {
              setTimeFormatError(!validateTime(e.target.value));
            }
          }}
          onChange={(e) => {
            if (e.target.value.length >= 8) {
              setTimeFormatError(!validateTime(e.target.value));
            }
            onChange(e.target.value);
            setTimeValue(e.target.value);
          }}
          value={timeValue}
          placeholder=""
        />
      </label>
      {timeFormatError && (
        <ErrorMessage message="Invalid time. Proper format is 24hr: HH:MM:SS" />
      )}
    </StyledDiv>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

TimePicker.defaultProps = {
  value: null,
};

export default TimePicker;
