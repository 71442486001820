import React, { Component } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
const divStyle = {
  overflow: 'scroll',
};

export default class ConfirmationModal extends Component {
  render() {
    return (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>Checkpoint details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={divStyle}>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Production Facility</th>
                <td>{this.props.productionLineItem.productionFacility}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{this.props.productionLineItem.state}</td>
              </tr>
              <tr>
                <th>Owner</th>
                <td>{this.props.productionLineItem.owner}</td>
              </tr>
            </tbody>
          </Table>
          <br />
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th>{'Item Name'}</th>
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Line'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Line population'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Line Lead'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Line Start time'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Line End time'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Break Start time'}</th>
                )}
                {this.props.productionLineItem.state === 'PRODUCED' && (
                  <th>{'Break End time'}</th>
                )}
                <th>{'Quantity'}</th>
                <th>{'Issue Type'}</th>
                <th>{'Notes'}</th>
              </tr>
            </thead>
            <tbody>{this.props.submitData}</tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          &nbsp;
          <Button bsStyle="success" onClick={this.props.save}>
            Save
          </Button>
          <Button bsStyle="warning" onClick={this.props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
