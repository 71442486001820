/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styledComponent from '../../utils/styledComponent';
import ErrorMessage from './ErrorMessage';

const StyledDiv = styledComponent('div', {
  '& input': {
    '&:-webkit-autofill': {
      background: '#FFF',
    },
    background: '#FFF',
    border: `1px solid #CCC`,
    borderRadius: '4px',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    marginTop: '10px',
    padding: '10px',
    width: '250px',
  },
  '& label': {
    color: '#000',
    display: 'block',
    fontSize: '18px',
    fontWeight: '800',
  },
  marginBottom: '20px',
});

const Input = ({
  errors,
  touched,
  label,
  name,
  type,
  handleChange,
  value,
  placeholder,
}) => (
  <StyledDiv className="inputWrapper">
    <label htmlFor={name}>
      {label}
      <Field
        autoComplete="off"
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </label>
    {touched && errors && errors[name] && (
      <ErrorMessage message="Required and must be yyyy-mm-dd format" />
    )}
  </StyledDiv>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password']),
  handleChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
