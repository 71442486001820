import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ConfirmDeleteOrderModal({ show, onConfirm, onClose }) {
  return (
    <Modal show={show}>
      <Modal.Body>
        <div className="form-group">
          Are you sure you want to delete this order?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={onConfirm}>
          Yes
        </button>
        &nbsp;
        <button type="button" className="btn btn-info" onClick={onClose}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}
