import React from 'react';

const Moon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0288 20.5C9.65964 20.5 7.65072 19.6757 6.00205 18.027C4.35339 16.3782 3.52905 14.3692 3.52905 12C3.52905 10.027 4.13955 8.27317 5.36055 6.7385C6.58172 5.20383 8.2083 4.21475 10.2403 3.77125C10.4506 3.71858 10.6359 3.72367 10.7961 3.7865C10.9564 3.84933 11.0866 3.94292 11.1866 4.06725C11.2866 4.19175 11.3456 4.34275 11.3636 4.52025C11.3814 4.69775 11.3397 4.875 11.2386 5.052C11.0321 5.42767 10.8788 5.81867 10.7788 6.225C10.6788 6.6315 10.6288 7.0565 10.6288 7.5C10.6288 9.141 11.2019 10.5346 12.3481 11.6807C13.4942 12.8269 14.8878 13.4 16.5288 13.4C17.02 13.4 17.4892 13.3378 17.9366 13.2135C18.3841 13.0892 18.7757 12.9449 19.1116 12.7807C19.2757 12.7091 19.436 12.6837 19.5923 12.7047C19.7488 12.7259 19.8828 12.7782 19.9943 12.8615C20.1161 12.9448 20.2068 13.0587 20.2663 13.203C20.326 13.3472 20.3327 13.5167 20.2866 13.7115C19.9314 15.6782 18.9746 17.3012 17.4163 18.5807C15.858 19.8602 14.0621 20.5 12.0288 20.5Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default Moon;
