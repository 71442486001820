import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button';
import styledComponent from '../../utils/styledComponent';

const TableStyles = styledComponent('div', {
  '& .table>tbody>tr:nth-child(odd)>td': {
    backgroundColor: 'white',
  },
  '& input': {
    width: '100%',
    borderWidth: '0px',
    border: 'none',
    outline: 'none',
  },
});

const UphPerSku = ({ data, loading, selectedSku, handleSubmit }) => {
  const [updateMap, setUpdateMap] = useState([]);

  useEffect(() => {
    setUpdateMap([]);
  }, [selectedSku]);

  const filteredData = data.filter(
    (item) => item.itemName === selectedSku.value,
  );
  const handleChange = (item) => (e) => {
    const val = e.target.value;
    const curItem = item;
    const alreadyExists = updateMap.find(
      (el) => el.lineWorkers === curItem.lineWorkers,
    );
    if (alreadyExists) {
      const newMap = updateMap.map((el) => {
        if (el.lineWorkers === curItem.lineWorkers) {
          return {
            lineWorkers: curItem.lineWorkers,
            unitsPerHour: Number(val),
          };
        }
        return el;
      });
      setUpdateMap(newMap);
      return;
    }
    setUpdateMap((prevState) => [
      ...prevState,
      {
        lineWorkers: curItem.lineWorkers,
        unitsPerHour: val,
      },
    ]);
  };
  return (
    <>
      {loading && <h2>Loading...</h2>}

      {!loading &&
        (filteredData.length ? (
          <TableStyles>
            <Table bordered responsive hover>
              <thead>
                <tr>
                  <th>Population</th>
                  <th>UPH</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr
                    key={`${item.itemName}-${item.lineWorkers}-${item.unitsPerHour}`}
                  >
                    <td>{item.lineWorkers}</td>
                    <td>{item.unitsPerHour}</td>
                    <td>
                      <input type="number" onChange={handleChange(item)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button onClick={() => handleSubmit(updateMap)} type="submit">
              Submit
            </Button>
          </TableStyles>
        ) : (
          <h4>No Data to show</h4>
        ))}
    </>
  );
};

Button.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UphPerSku;
