export function generateLayout(items) {
  let returnObj = {};
  if (Array.isArray(items)) {
    // When an array of items is passed in, convert it to a map:
    // {
    //    '<vendItemName>': {...Details}
    // }
    items = items.reduce((obj, item) => {
      return {
        ...obj,
        [item['id']]: item,
      };
    }, {});
  }

  Object.keys(items)
    .filter((itemName) => {
      if (items[itemName].lineOrder == null) {
        return false;
      }
      let bay = items[itemName].lineOrder.bay;
      let sort = items[itemName].lineOrder.sort;
      let zone = items[itemName].lineOrder.zone;
      if (bay == null || sort == null || zone == null) {
        return false;
      }
      return true;
    })
    .sort(function (a, b) {
      return (
        items[a].lineOrder.zone - items[b].lineOrder.zone ||
        items[a].lineOrder.bay - items[b].lineOrder.bay ||
        items[a].lineOrder.sort - items[b].lineOrder.sort
      );
    })
    .forEach((itemName) => {
      let bay = items[itemName].lineOrder.bay;
      let zone = items[itemName].lineOrder.zone;
      if (!(zone in returnObj)) {
        returnObj[zone] = {};
      }
      if (!(bay in returnObj[zone])) {
        returnObj[zone][bay] = [];
      }
      returnObj[zone][bay].push(itemName);
    });
  return returnObj;
}
