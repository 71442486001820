import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../../utils/styledComponent';

const StyledDiv = styledComponent('div', {
  color: '#d32c60',
  padding: '5px 0',
});

const ErrorMessage = ({ message, ...otherProps }) => {
  return <StyledDiv {...otherProps}>{message}</StyledDiv>;
};
ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: 'Field is Required',
};

export default ErrorMessage;
