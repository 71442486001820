/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styledComponent from '../../utils/styledComponent';
import ErrorMessage from './ErrorMessage';

const StyledDiv = styledComponent('div', {
  '& label': {
    color: '#000',
    display: 'block',
    fontSize: '18px',
    fontWeight: '800',
    marginBottom: '10px',
  },
  '& select': {
    '&:-webkit-autofill': {
      background: '#FFF',
    },
    background: '#FFF',
    border: `1px solid #CCC`,
    borderRadius: '4px',
    display: 'block',
    fontSize: '16px',
    height: '50px',
    marginTop: '10px',
    padding: '15px 30px',
  },
  color: '#000',
  marginBottom: '20px',
  width: '250px',
});

const SearchableSelect = ({
  required,
  errors,
  touched,
  id,
  label,
  value,
  options,
  onChange,
}) => (
  <StyledDiv className="selectWrapper">
    <label htmlFor={label}>{label}</label>
    <Select
      defaultValue={options.filter((option) => option.value === value)}
      onChange={(value) => onChange(value)}
      options={options}
    />
    {required && touched[id] && errors && errors[id] && <ErrorMessage />}
  </StyledDiv>
);

SearchableSelect.propTypes = {
  errors: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

SearchableSelect.defaultProps = {
  value: null,
  errors: {},
  required: true,
};

export default SearchableSelect;
