// eslint-disable-next-line import/no-extraneous-dependencies
import API, { graphqlOperation } from '@aws-amplify/api';

export async function Items() {
  const response = await API.graphql(
    graphqlOperation(`
    query GetVendItems{
      items(itemStatus: LIVE){
        id
        prettyName
        categories
        salesChannel
        shelfLife
        sundryType
        status
        jarSize
        tags
        packagingType
        unitsPerPack
        lineOrder {
          zone
          bay
          sort
        }
      }
    }
  `),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function ItemsV2(filter) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query ItemsV2($filter:FilterItems!) {
        itemsV2(filter:$filter) {
            id
            prettyName
        }
      }
    `,
      {
        filter,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function locationDetails() {
  const response = await API.graphql(
    graphqlOperation(`
      query GetLocations{
        locations{
          Id
          OperationConfigs {
            Route
            Stop
            Status{
              Ops
              Public
            }
          }
          LocationConfigs{
            PrettyName
          }
        }
      }
    `),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
export async function LocationIDs() {
  const response = await API.graphql(
    graphqlOperation(`
      query GetLocations{
        locations{
          locations {
            id
            type
            operationConfigs {
              address
              guaranteed
            }
          }
        }
      }
    `),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function Options() {
  const options = await API.graphql(
    graphqlOperation(`
    query IntrospectionQuery {
      __schema {
        types {
          ...FullType
        }
      }
    }
    fragment FullType on __Type {
      kind
      name
      enumValues(includeDeprecated: true) {
        name
        description
        isDeprecated
        deprecationReason
      }
    }`),
  );
  if (options.error) {
    throw options.error[0];
  }
  return options.data.__schema.types.filter((item) => item.kind === 'ENUM');
}

export async function GetOrderLocationID(id) {
  const response = await API.graphql(
    graphqlOperation(
      `
    query Order($id: ID!){
      order(id:$id){
          locationId
          uniqueId
      }
    }
  `,
      {
        id,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function SpecificOrder(id) {
  const response = await API.graphql(
    graphqlOperation(
      `
    query Order($id: ID!){
      order(id:$id){
          clientCode
          clientDetails
          billingType
          market
          orderOwner
          orderType
          locationId
          uniqueId
          deliverySpecialNotes
          kitchenSpecialNotes
          addressForDelivery
          itemsCount {
            itemName
            count
            productionDate
          }
          deliveryDate
          dropOffDate
          productionDate
          driverDeliveryWindow
      }
    }
  `,
      {
        id,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function Order(
  nextToken,
  productionDate = null,
  deliveryDate = null,
) {
  const response = await API.graphql(
    graphqlOperation(
      `
    query ListOrders($nextToken: String, $productionDate: AWSDate, $deliveryDate: AWSDate){
      ordersByDay(productionDate:$productionDate,nextToken:$nextToken,deliveryDate:$deliveryDate,count:800){
        nextToken
        orders {
          clientCode
          clientDetails
          billingType
          market
          orderOwner
          orderType
          locationId
          uniqueId
          deliverySpecialNotes
          kitchenSpecialNotes
          addressForDelivery
          itemsCount {
            itemName
            count
            productionDate
          }
          deliveryDate
          productionDate
          dropOffDate
          orderUploadId
          jitAllocationItems
          orderRebalanceItems
        }
      }
    }
  `,
      {
        nextToken,
        productionDate: productionDate !== undefined ? productionDate : null,
        deliveryDate: deliveryDate !== undefined ? deliveryDate : null,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function OrderMeta(
  nextToken,
  productionDate = null,
  deliveryDate = null,
) {
  const response = await API.graphql(
    graphqlOperation(
      `
    query ListOrders($nextToken: String, $productionDate: AWSDate, $deliveryDate: AWSDate){
      ordersByDay(productionDate:$productionDate,nextToken:$nextToken,deliveryDate:$deliveryDate,count:1000){
        nextToken
        orders {
          clientCode
          orderOwner
          orderType
          locationId
          uniqueId
          addressForDelivery
          deliveryDate
          dropOffDate
          deliverySpecialNotes
          orderUploadId
          stockingIndex
        }
      }
    }
  `,
      {
        nextToken,
        productionDate: productionDate !== undefined ? productionDate : null,
        deliveryDate: deliveryDate !== undefined ? deliveryDate : null,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function CreateOrderMutation(order) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation createOrder($input:CreateOrderInput!){
        createOrder(input:$input){
          code
          message
        }
      }
    `,
      {
        input: order,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function EditOrderMutation(order) {
  const response = await API.graphql(
    graphqlOperation(
      `
    mutation editOrder($input:EditOrderInput!){
      editOrder(input:$input){
        code
        message
      }
    }
  `,
      {
        input: order,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function DeleteOrderMutation(order_ID) {
  const response = await API.graphql(
    graphqlOperation(
      `
    mutation deleteOrder($orderID:ID!){
      deleteOrder(input:$orderID){
        code
        message
      }
    }
  `,
      {
        orderID: order_ID,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function CreateProductionLineMutation(productionLineItem) {
  const response = await API.graphql(
    graphqlOperation(
      `
          mutation ProductionLineItem($input:ProductionLineItemInput!){
            createProductionLineItem(input:$input){
              message
              code
            }
          }
        `,
      {
        input: productionLineItem,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function ProductionLineSummary(pDate, pFacility) {
  const response = API.graphql(
    graphqlOperation(
      `
      query GetProductionLineSummary{
          productionLineItemSummary(date: "${pDate}"
          productionFacility: ${pFacility}){
            productionFacility
            productionTime
            itemName
            lineLead
            state
            startTime
            endTime
            breakStartTime
            breakEndTime
            linePopulation
            quantity
            line
            owner
            uniqueId
          }
        }
    `,
      {},
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function PackingSlipPreview(date) {
  const response = await API.graphql(
    graphqlOperation(
      `
  query GetPackingSlips($date:AWSDate!){
    packingSlipsPreview(Date:$date){
      qrCode
      date
      driver
      stop
      deliverySpecialNotes
      dropOffDate
      addressForDelivery
      shopifyOrderNumber
      data{
        itemName
        value
      }
      location
      uniqueId
      type
      clientCode
      subPackingSlipIndex
      totalSubPackingSlips
      packingSlipGroup
    }
  }`,
      { date },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function PackingSlip(date) {
  const response = await API.graphql(
    graphqlOperation(
      `
  query GetPackingSlips($date:AWSDate!){
    packingSlips(date:$date){
      qrCode
      date
      driver
      stop
      deliverySpecialNotes
      dropOffDate
      addressForDelivery
      shopifyOrderNumber
      data{
        itemName
        value
        productionDate
      }
      location
      uniqueId
      type
      clientCode
      subPackingSlipIndex
      totalSubPackingSlips
      packingSlipGroup
    }
  }`,
      { date },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function CreatePickPackLineItemInputMutation(pickPackLineItem) {
  const response = await API.graphql(
    graphqlOperation(
      `
  mutation createPickPackLineItem($input: PickPackLineItemInput!) {
    createPickPackLineItem(input: $input) {
      uniqueId
      date
      productionFacility
      destinationRegion
      itemsCount {
        itemName
        errorCount
      }
      sheetDeliveryTime
      lineStartTime
      lineEndTime
      line
      linePopulation
      stackPopulation
      owner
      notes
    }
   }
        `,
      {
        input: pickPackLineItem,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function ReadPickPackLineItem(uniqueId) {
  const response = await API.graphql(
    graphqlOperation(
      `
    query pickPackLineItemSummary($uniqueId:ID) {
      pickPackLineItemSummary(uniqueId:$uniqueId) {
        uniqueId
        date
        productionFacility
        destinationRegion
        itemsCount {
          itemName
          errorCount
        }
        sheetDeliveryTime
        lineStartTime
        lineEndTime
        line
        linePopulation
        stackPopulation
        owner
        notes
      }
  }
    `,
      {
        uniqueId,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function ListActiveItemSubstitutionRules(uniqueId) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query ListItemSubstitutionRules {
        listItemSubstitutionRules {
          id
          originalItemId
          originalItemDisplayName
          substitutionItemId
          substitutionItemDisplayName
          creatorName
          createdDate
          startDate
          expirationDate
        }
      }
    `,
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function CreateItemSubstitutionRuleMutation(itemSubstitutionRule) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation CreateItemSubstitutionRule ($input: ItemSubstitutionRuleInput!) {
        createItemSubstitutionRule(input: $input) {
          originalItemId
          originalItemDisplayName
          substitutionItemId
          substitutionItemDisplayName
          creatorName
          createdDate
          expirationDate
          startDate
        }
      }
        `,
      {
        input: itemSubstitutionRule,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function ItemProductionCount(itemId, productionDate) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query ItemProductionCount($input: ItemProductionCountInput!) {
        getItemDailyProductionCount(input: $input) {
          amountProduced
          amountToBeProduced
        }
      }
    `,
      {
        input: {
          productionDate,
          itemId,
        },
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function CreateFinishedGood(finishedGoods) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation CreateFinishedGoods($data: [FinishedGoodInput]!) {
        createFinishedGoods(data: $data) {
          createdAt
          expirationDate
          scannedTimestamp
          uniqueId
          itemId
          itemName
        }
      }
      `,
      {
        data: finishedGoods,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function SaveFinishedGoodRun(finishedGoodRun) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation SaveFinishedGoodRun($input: FinishedGoodRunInput!) {
        saveFinishedGoodRun(input: $input) {
          runId,
          itemId,
          lineWorkers,
          lineNumber,
          status
          activeRunTime
        }
      }
      `,
      {
        input: finishedGoodRun,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetFinishedGoodRun(runFilter) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query GetFinishedGoodRun($filter: FilterFinishedGoodRun!) {
        getFinishedGoodRun(filter: $filter) {
          runId
          status
          itemId
          lineWorkers
          lineNumber
          productionDate
          amountProduced
        }
      }
      `,
      {
        filter: runFilter,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetOpsLocations(filter = {}) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query GetOpsLocations($filter:filterOpsLocation){
        opsLocations(filter: $filter){
          locations {
            opportunityName
            id
            type
            routingTag
            parkToFridgeInstructions
            fulfillmentCenter
            deliveryInstructions
            timeZone
            containerType
            status
            region
            operatingModel
            retailer
          }
        }
      }
    `,
      {
        filter,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetLocationRoutes(filterLocationRoutes) {
  const response = await API.graphql(
    graphqlOperation(
      `query LocationRoutes($filter: filterLocationRoutes!) {
        locationRoutes(filter: $filter) {
          routes {
            id
            stockingDate
            market
            route
            stop
            stockingIndex
            stopType
          }
      }
     }
    `,
      { filter: filterLocationRoutes },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response.data.locationRoutes;
}

export async function ReRunAllocation(packingSlipGroup = 'MW') {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation ReRunAllocation($input:RerunAllocationInput!) {
        rerunAllocation(input:$input)
      }
      `,
      {
        input: {
          packingSlipGroup,
        },
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
export async function UpdateItemWorkersUPH(input) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation updateItemWorkersUPH($input:CreateItemWorkersUPHInput!) {
        updateItemWorkersUPH(input:$input) {
          itemName
          lineWorkers
          unitsPerHour
        }
      }
      `,
      {
        input,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
export async function PackingSlipsV2(filter) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query PackingSlipsV2($filter: FilterPackingSlipsV2!) {
        packingSlipsV2(filter: $filter) {
          qrCode
          data {
            itemName
            value
            deviceId
            zone
          }
        }
      }
      `,
      {
        filter,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
export async function PackingSlipV3(filter) {
  const response = await API.graphql(
    graphqlOperation(
      `
  query GetPackingSlipsV3($filter:GetPackingSlipsV3Filter!){
    getPackingSlipsV3(filter:$filter){
      qrCode
      createDate
      route
      stop
      deliverySpecialNotes
      dropOffDate
      addressForDelivery
      shopifyOrderNumber
      itemQuantitiesAndDates{
        itemName
        value
      }
      locationId
      uniqueId
      type
      clientCode
      subPackingSlipIndex
      totalSubPackingSlips
    }
  }`,
      { filter },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
export async function ReadAllPackingSlipGroupValues() {
  const response = await API.graphql(
    graphqlOperation(`
      query GetallPackingSlipGroups{
        getAllPackingSlipGroups{
          stateCode
          packingSlipGroup
        }
      }
    `),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function UploadProductionPlanMutation(items) {
  const response = await API.graphql(
    graphqlOperation(
      `
    mutation UploadProductionPlan($input:[ProductionPlanInput!]!){
      uploadProductionPlan(input:$input)
    }
  `,
      {
        input: items,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetProductionPlan(filter) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query GetProductionPlan($filter:FilterProductionPlan!) {
        getProductionPlan(filter:$filter) {
          id
          productionDate
          sku
          quantity
          facility
          partialToteUnits
          fullToteCount
        }
      }
    `,
      {
        filter,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetJarSize() {
  const response = await API.graphql(
    graphqlOperation(`
      query GetJarSize{
        getJarSize {
          jarSize
          maxJarPerTote
        }
      }
    `),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function UpdateProductionLineCoCMutation(items) {
  const response = await API.graphql(
    graphqlOperation(
      `
    mutation UpdateProductionLineCoC($input:[ProductionLineCoCInput!]!){
      updateProductionLineCoC(input:$input) {
        uniqueId
        success
        message
       }
    }
  `,
      {
        input: items,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetProdItemWorkersUPH(input) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query getItemWorkersUPH($input:ItemWorkersUPHInput!){
        getItemWorkersUPH(input:$input){
          itemName
          unitsPerHour
          lineWorkers
          lineWorkersPriority
          workerPositions {
            people
            job
          }
        }
      }
    `,
      {
        input,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GenerateReceivingInvoicesPDF(invoiceInfo) {
  const response = await API.graphql(
    graphqlOperation(
      `
    mutation GenerateReceivingInvoicesPDF($input:GenerateReceivingInvoicesPDFInput!){
      generateReceivingInvoicesPDF(input:$input)
    }
  `,
      {
        input: invoiceInfo,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetFulfillmentPriority(input) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query GetFulfillmentPriority($input:FulfillmentPriorityInput!){
        getFulfillmentPriority(input:$input){
          locations
          priority
        }
      }
    `,
      {
        input,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function GetHoldoverQuantities(input) {
  const response = await API.graphql(
    graphqlOperation(
      `
      query GetHoldoverQuantities($input:HoldoverQuantityInput!){
        getHoldoverQuantity(input:$input){
          sku
          holdoverQuantity
        }
      }
    `,
      {
        input,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}

export async function UpdateHoldoverQuantity(input) {
  const response = await API.graphql(
    graphqlOperation(
      `
      mutation UpdateHoldoverQuantity($input:UpdateHoldoverQuantityInput!){
        updateHoldoverQuantity(input:$input)
      }
    `,
      {
        input,
      },
    ),
  );
  if (response.error) {
    throw response.error[0];
  }
  return response;
}
