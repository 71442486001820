import { API, Analytics, Auth, Signer } from 'aws-amplify';

async function recordEvent(path) {
  let userID = await Auth.currentUserInfo();
  if (userID !== null) {
    userID = userID['username'];
    Analytics.record('API_Invoked', {
      route: path,
      user: userID,
    });
  }
}

export async function signWSUrl(host) {
  const creds = await Auth.currentUserCredentials();
  const serviceInfo = {
    service: host.split('.')[1],
    region: host.split('.')[2],
  };
  const access_key = creds.accessKeyId;
  const secret_key = creds.secretAccessKey;
  const session_token = creds.sessionToken;

  const result = await Signer.signUrl(
    'wss://' + host + '/prod',
    {
      access_key,
      secret_key,
      session_token,
    },
    serviceInfo,
  );
  return result;
}

export async function invokeApig({ path, method, headers = {}, body }) {
  let response;
  recordEvent(path);
  path = '/prod' + path;
  try {
    switch (method) {
      case 'GET':
        response = await API.get('CMS', path);
        break;
      case 'POST':
        response = await API.post('CMS', path, {
          body: body,
        });
        break;
      case 'PUT':
        response = await API.put('CMS', path, {
          body: body,
          headers: {},
        });
        break;
      case 'DELETE':
        response = await API.del('CMS', path, {
          body: body,
          headers: {},
        });
        break;
      default:
    }
  } catch (e) {
    throw JSON.parse(JSON.stringify(e));
  }
  return response;
}
