export const lineOptions = [
  {
    value: 'None',
    label: '',
  },
  {
    value: 'LINE-1',
    label: 'Line 1',
  },
  {
    value: 'LINE-2',
    label: 'Line 2',
  },
  {
    value: 'LINE-3',
    label: 'Line 3',
  },
  {
    value: 'LINE-4',
    label: 'Line 4',
  },
  {
    value: 'LINE-5',
    label: 'Line 5',
  },
  {
    value: 'LINE-6',
    label: 'Line 6',
  },
  {
    value: 'LINE-0',
    label: 'Other Production Activity',
  },
];

export const lineLeadsOptions = [
  {
    value: 'None',
    label: '',
  },
  {
    value: 'aboozer@farmersfridge.com',
    label: 'aboozer@farmersfridge.com',
  },
  {
    value: 'nbahena@farmersfridge.com',
    label: 'nbahena@farmersfridge.com',
  },
  {
    value: 'tburnett@farmersfridge.com',
    label: 'tburnett@farmersfridge.com',
  },
  {
    value: 'jnunez@farmersfridge.com',
    label: 'jnunez@farmersfridge.com',
  },
  {
    value: 'lvargas@farmersfridge.com',
    label: 'lvargas@farmersfridge.com',
  },
  {
    value: 'smunshi@farmersfridge.com',
    label: 'smunshi@farmersfridge.com',
  },
  {
    value: 'lguerrero@farmersfridge.com',
    label: 'lguerrero@farmersfridge.com',
  },
  {
    value: 'dmorel@farmersfridge.com',
    label: 'dmorel@farmersfridge.com',
  },
  {
    value: 'tjeter@farmersfridge.com',
    label: 'tjeter@farmersfridge.com',
  },
  {
    value: 'sbeltran@farmersfridge.com',
    label: 'sbeltran@farmersfridge.com',
  },
  {
    value: 'amedina@farmerfridge.com',
    label: 'amedina@farmerfridge.com',
  },
  {
    value: 'operez@farmersfridge.com',
    label: 'operez@farmersfridge.com',
  },
  {
    value: 'sofenloch@farmersfridge.com',
    label: 'sofenloch@farmersfridge.com',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
