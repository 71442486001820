import { Authenticator, Greetings } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import React, { Component } from 'react';
import App from './App';
import configProd from './config.prod';
import packageSetting from '../package.json';

Amplify.configure({
  Auth: {
    identityPoolId:
      process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID ||
      configProd.cognito.IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION || configProd.cognito.REGION,
    userPoolId:
      process.env.REACT_APP_COGNITO_USER_POOL_ID ||
      configProd.cognito.USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID ||
      configProd.cognito.APP_CLIENT_ID,
    mandatorySignIn: true,
  },
  Analytics: {
    appId:
      process.env.REACT_APP_ANALYTICS_APP_ID || configProd.analytics.APP_ID,
    region:
      process.env.REACT_APP_ANALYTICS_REGION || configProd.analytics.REGION,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.BUCKET || configProd.AWSS3.BUCKET,
      region: process.env.REGION || configProd.AWSS3.REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'CMS',
        endpoint:
          process.env.REACT_APP_API_GATEWAY_URL || configProd.apiGateway.URL,
        region:
          process.env.REACT_APP_API_GATEWAY_REGION ||
          configProd.apiGateway.REGION,
        service: 'execute-api',
      },
    ],
    aws_appsync_graphqlEndpoint:
      process.env.REACT_APP_APPSYNC_ENDPOINT ||
      configProd.appsync.PROD.ENDPOINT,
    aws_appsync_region:
      process.env.REACT_APP_APPSYNC_REGION || configProd.appsync.PROD.REGION,
    aws_appsync_authenticationType: 'AWS_IAM',
    // aws_appsync_apiKey: configProd.appsync.PROD.API_KEY
    graphql_headers: async () => ({
      'FF-UI-Version': packageSetting.version,
    }),
  },
});

class AppWithAuth extends Component {
  render() {
    return (
      <Authenticator hide={[Greetings]}>
        <App />
      </Authenticator>
    );
  }
}

export default AppWithAuth;
