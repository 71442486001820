import React from 'react';
import styledComponent from '../utils/styledComponent';
import loader from '../assets/img/loader.gif';

const StyledDiv = styledComponent('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const Loading = () => {
  return (
    <StyledDiv>
      <img alt="Loading icon" src={loader} />
    </StyledDiv>
  );
};

export default Loading;
