/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../../utils/styledComponent';
import { Prompt } from 'react-router';
import CreateOptions from './components/CreateOptions';
import { CreateOrderMutation, Items, LocationIDs } from '../../libs/GraphQL';
import { Formik } from 'formik';
import Input from '../../atoms/forms/Input';
import { Auth } from 'aws-amplify';
import Button from '../../atoms/Button';
import moment from 'moment';
import Select from '../../atoms/forms/Select';
import Pagination from '../../atoms/Pagination';
import { Link } from 'react-router-dom';
import Radio from '../../atoms/forms/Radio';
import BaseTable, { Column } from 'react-base-table';
import DatePicker from '../../atoms/forms/DatePicker';
import 'react-base-table/styles.css';
import { sortArrayOfObjects } from '../../libs/utility';

const StyledSection = styledComponent('section', {
  '& .actions': {
    float: 'right',
    marginBottom: '32px',
    marginTop: '16px',
  },
  '& form': {
    marginTop: '16px',
  },
  '& .dropdowns': {
    display: 'flex',
    justifyContent: 'left',
  },
  '& .selectWrapper': {
    marginRight: '80px',
  },
  '& .submitError': {
    color: '#D0021A',
  },
  '& .selectWrapper label, .inputWrapper label, .radioWrapper label, .dateWrapper label':
    {
      fontSize: '14px',
      fontWeight: 'normal',
    },
  '& .inputs': {
    display: 'flex',
    justifyContent: 'left',
  },
  '& .inputWrapper': {
    marginRight: '80px',
  },
  '& .header': {
    fontSize: '24px',
  },
  '& .subheader': {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  '& .section': {
    marginBottom: '50px',
    marginTop: '50px',
  },
  '& .productHeader': {
    display: 'flex',
    justifyContent: 'left',
  },
  '& .subSection': {
    '& .dateWrapper': {
      marginRight: '200px', // TODO: why is this not working like the others
    },
    marginBottom: '25px',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'left',
  },
  '& .quantityInput': {
    width: '50px',
    borderRadius: '5px',
    fontSize: '20px',
    textAlign: 'center',
  },
});

const OrdersCreate = ({ history }) => {
  const [staticItems, setStaticItems] = useState([]);
  const [dynamicItems, setDynamicItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [submitError, setSubmitError] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [orderCadence, setOrderCadence] = useState('oneTime');
  const [filterText, setFilterText] = useState('');
  const [sortBy, setSortBy] = useState({ key: 'id', order: 'asc' });
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderOwner, setOrderOwner] = useState('');

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = dynamicItems.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (staticItems.length === 0) {
      getLiveVendItems();
    }
    if (locations.length === 0) {
      getLocationIds();
    }
    if (orderOwner === '') {
      getAuthenticatedUser();
    }
  }, []);

  const getAuthenticatedUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setOrderOwner(user.signInUserSession.idToken.payload.email);
  };

  const getLiveVendItems = async () => {
    try {
      let liveItems = await Items();
      setStaticItems(sortArrayOfObjects(liveItems.data.items, 'id', 'asc'));
      setDynamicItems(sortArrayOfObjects(liveItems.data.items, 'id', 'asc'));
    } catch (e) {
      console.log(e);
      setSubmitError(true);
    }
  };

  const onColumnSort = (sortByVal) => {
    const sortedData = sortArrayOfObjects(
      dynamicItems,
      sortByVal.key,
      sortByVal.order,
    );
    setSortBy(sortByVal);
    setDynamicItems(sortedData);
  };

  const getLocationIds = async () => {
    try {
      var locations = await LocationIDs();
      setLocations(
        sortArrayOfObjects(locations.data.locations.locations, 'id', 'asc'),
      );
    } catch (e) {
      console.log(e);
      setSubmitError(true);
    }
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach(
      (key) => (obj[key] === null || obj[key] === '') && delete obj[key],
    );
    let cleanItemsCount = obj.itemsCount.filter(
      (item) => parseInt(item.count) !== 0 && item.count !== '',
    );
    obj.itemsCount = cleanItemsCount;
    return obj;
  };

  const cleanData = (data) => {
    if (data.orderType === 'FRIDGE_OTHERS') {
      data.addressForDelivery = data.locationId;
    } else if (data.orderType === 'INTERNAL') {
      data.addressForDelivery = 'KITCHEN';
    }
    let newData = removeEmpty(data);
    return newData;
  };

  const handleSubmitOrder = async (values) => {
    let orderData = cleanData(values);
    orderData.orderOwner = orderOwner;
    try {
      const response = await CreateOrderMutation(orderData);
      let message = response.data.createOrder.message;
      setOrderId(message.split(': ')[1]);
      setShouldShowSuccess(true);
    } catch (error) {
      setSubmitError(true);
    }
  };

  const isSubmitDisabled = (values) => {
    let itemCount = values.itemsCount.length;
    let orderType = values.orderType;
    let clientCode = values.clientCode;
    let locationId = values.locationId;
    let addressForDelivery = values.addressForDelivery;
    let market = values.market;
    return (
      itemCount === 0 ||
      orderType === '' ||
      market === '' ||
      (orderType !== 'FRIDGE_OTHERS' && clientCode === '') ||
      (orderType === 'FRIDGE_OTHERS' && locationId === undefined) ||
      (orderType === 'CUSTOMER' && addressForDelivery === undefined)
    );
  };

  // Used for item text filtering
  useEffect(() => {
    const results = staticItems.filter((item) =>
      item.id.includes(filterText.toUpperCase()),
    );
    setDynamicItems(sortArrayOfObjects(results, 'id', 'asc'));
  }, [filterText]);

  if (shouldShowSuccess) {
    return (
      // TODO: why is inline styling the only way for this to work
      <div
        style={{
          marginTop: '200px',
          textAlign: 'center',
        }}
      >
        <h1>Your order has been successfully created!</h1>
        <Button
          onClick={() => {
            history.push(`/orders/order_id/${orderId}`);
          }}
        >
          View Created Order
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            setShouldShowSuccess(false);
            history.push('/orders-v2/bulk-create');
          }}
        >
          Create New Order
        </Button>
      </div>
    );
  }

  return (
    <StyledSection>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <CreateOptions defaultValue="manual" history={history} />
      <Formik
        initialValues={{
          orderType: '',
          market: '',
          itemsCount: [],
          deliveryDate: moment().format('YYYY-MM-DD'),
          dropOffDate: moment().format('YYYY-MM-DD'),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setShouldBlockNavigation(false);
          handleSubmitOrder(values);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
        }) => {
          const handleValue = (rowData) => {
            let currentItems = values.itemsCount;
            const indexOfValue = currentItems.findIndex(
              (i) => i.itemName === rowData.id,
            );
            if (indexOfValue > -1) {
              return parseInt(currentItems[indexOfValue].count);
            }
          };
          const quantityRenderer = ({ rowData }) => {
            return (
              <div>
                <input
                  className="quantityInput"
                  label=""
                  type="text"
                  name="items"
                  id="items"
                  placeholder=""
                  onChange={(e) => {
                    let currentItems = values.itemsCount;
                    const value = e.target.value;
                    const indexOfValue = currentItems.findIndex(
                      (i) => i.itemName === rowData.id,
                    );
                    if (indexOfValue <= -1) {
                      currentItems.push({
                        itemName: rowData.id,
                        count: parseInt(value),
                      });
                    } else {
                      currentItems[indexOfValue] = {
                        itemName: rowData.id,
                        count: parseInt(value),
                      };
                    }
                    setFieldValue('itemsCount', currentItems);
                  }}
                  value={handleValue(rowData)}
                />
              </div>
            );
          };
          return (
            <form onSubmit={handleSubmit}>
              <div className="dropdowns">
                <Select
                  errors={errors}
                  touched={touched}
                  label="Order Type"
                  id="orderType"
                  options={[
                    { value: '', label: '-- Select One --' },
                    { value: 'FRIDGE_OTHERS', label: 'Fridge Order' },
                    { value: 'CUSTOMER', label: 'New Channel' },
                    { value: 'INTERNAL', label: 'Internal Orders' },
                  ]}
                  onChange={(v) => {
                    setFieldTouched('orderType', true);
                    setFieldValue('orderType', v.value);
                    setAddNewCustomer(false);
                  }}
                  value=""
                />
                {values.orderType === 'FRIDGE_OTHERS' && (
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Location ID"
                    id="locationId"
                    options={locations
                      .map((location) => location.id)
                      .map((location) => ({
                        value: location,
                        label: location,
                      }))}
                    onChange={(v) => {
                      setFieldTouched('locationId', true);
                      setFieldValue('locationId', v.value);
                      setFieldTouched('addressForDelivery', true);
                      setFieldValue('addressForDelivery', v.value);
                    }}
                    value=""
                  />
                )}
                {values.orderType === 'CUSTOMER' && (
                  <Select
                    errors={errors}
                    touched={touched}
                    label="New Channel Order Type"
                    id="clientCode"
                    options={[
                      { value: '', label: '-- Select One --' },
                      { value: 'Delivery', label: 'Owned Delivery' },
                      { value: 'ThirdParty', label: '3PL Delivery' },
                      { value: 'Wholesale', label: 'Wholesale' },
                      { value: 'AmazonGo', label: 'AmazonGo' },
                      { value: 'Catering', label: 'Catering' },
                    ]}
                    onChange={(v) => {
                      setFieldTouched('clientCode', true);
                      setFieldValue('clientCode', v.value);
                    }}
                    value=""
                  />
                )}
                {values.orderType === 'INTERNAL' && (
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Internal Order Type"
                    id="clientCode"
                    options={[
                      { value: '', label: '-- Select One --' },
                      { value: 'Puling', label: 'Pulping' },
                      { value: 'QA', label: 'QA' },
                      { value: 'Marketing', label: 'Marketing' },
                      { value: 'LogisticsRD', label: 'LogisticsRD' },
                      { value: 'Sales', label: 'Sales' },
                    ]}
                    onChange={(v) => {
                      setFieldTouched('clientCode', true);
                      setFieldValue('clientCode', v.value);
                    }}
                    value=""
                  />
                )}
              </div>
              {/* {values.orderType === 'CUSTOMER' && (
                <div>
                  {!addNewCustomer ? (
                    <div>
                      <Input
                        label="Customer"
                        id="customer"
                        type="text"
                        name="customer"
                        placeholder=""
                      />
                      <Link onClick={() => setAddNewCustomer(true)}>
                        + Add new customer
                      </Link>
                    </div>
                  ) : (
                    <Link onClick={() => setAddNewCustomer(false)}>
                      Use Existing Customer
                    </Link>
                  )}
                </div>
              )} */}
              {/* {addNewCustomer && (
                <div className="section">
                  <h2 className="header">New Customer</h2>
                  <div>
                    <h3 className="subheader">Contact Information</h3>
                    <div className="inputs">
                      <Input
                        label="Payee"
                        id="payee"
                        type="text"
                        name="payee"
                        placeholder=""
                      />
                      <Input
                        label="Billing Contact Name"
                        id="billingContactName"
                        type="text"
                        name="billingContactName"
                        placeholder=""
                      />
                    </div>
                    <Input
                      label="Billing Email Address"
                      id="billingEmailAddress"
                      type="text"
                      name="billingEmailAddress"
                      placeholder=""
                    />
                  </div>
                  <div className="section">
                    <h3 className="subheader">Delivery Address</h3>
                    <div className="inputs">
                      <Input
                        label="Address"
                        id="address"
                        type="text"
                        name="address"
                        placeholder=""
                      />
                      <Input
                        label="Unit, Suit, etc. (Optional)"
                        id="address2"
                        type="text"
                        name="address2"
                        placeholder=""
                      />
                    </div>
                    <div className="inputs">
                      <Input
                        label="City"
                        id="city"
                        type="text"
                        name="city"
                        placeholder=""
                      />
                      <Input
                        label="State"
                        id="state"
                        type="text"
                        name="state"
                        placeholder=""
                      />
                    </div>
                    <div className="inputs">
                      <Input
                        label="Zip Code"
                        id="zipCode"
                        type="text"
                        name="zipCode"
                        placeholder=""
                      />
                      <Input
                        label="Delivery Instructions"
                        id="deliveryInstructions"
                        type="text"
                        name="deliveryInstructions"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={
                        (values.orderType === '' &&
                          values.clientCode === '') ||
                        (values.orderType === 'CUSTOMER' &&
                          values.clientCode === '') ||
                        (values.orderType === 'INTERNAL' &&
                          values.clientCode === '')
                      }
                      type="submit"
                      data-testid="bulkOrdersCreate-submit"
                    >
                      Add New Customer
                    </Button>
                  </div>
                </div>
              )} */}
              {values.orderType !== '' && (
                <div>
                  <div className="section">
                    <h3 className="subheader">Delivery Information</h3>
                    {/* <Radio
                      checked={true}
                      label="One Time"
                      name="cadence"
                      value="oneTime"
                      handleChange={v => {
                        setOrderCadence(v.target.value);
                      }}
                    />
                    <Radio
                      label="Recurring"
                      name="cadence"
                      value="recurring"
                      disabled
                      handleChange={v => {
                        setOrderCadence(v.target.value);
                      }}
                    /> */}
                    {orderCadence === 'oneTime' && (
                      <div className="subSection">
                        <DatePicker
                          className="datePicker"
                          currentDate={moment()}
                          error={errors.date}
                          label="Delivery Date"
                          onChange={(v) => {
                            setFieldTouched('deliveryDate', true);
                            setFieldValue(
                              'deliveryDate',
                              moment(v).format('YYYY-MM-DD'),
                            );
                          }}
                        />
                        <DatePicker
                          currentDate={moment()}
                          error={errors.date}
                          label="Drop Off Date"
                          onChange={(v) => {
                            setFieldTouched('dropOffDate', true);
                            setFieldValue(
                              'dropOffDate',
                              moment(v).format('YYYY-MM-DD'),
                            );
                          }}
                        />
                      </div>
                    )}
                    <div className="subSection">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Market"
                        id="market"
                        options={[
                          { value: '', label: '-- Select One --' },
                          { value: 'CHICAGO', label: 'Chicago' },
                          { value: 'INDIANAPOLIS', label: 'Indianapolis' },
                          { value: 'MICHIGAN', label: 'Michigan' },
                          { value: 'MILWAUKEE', label: 'Milwaukee' },
                          { value: 'NEW_JERSEY', label: 'New Jersey' },
                          { value: 'NEW_YORK', label: 'New York' },
                          { value: 'PHILADELPHIA', label: 'Philadelphia' },
                        ]}
                        onChange={(v) => {
                          setFieldTouched('market', true);
                          setFieldValue('market', v.value);
                        }}
                        value=""
                      />
                      <Input
                        label="Delivery Notes"
                        type="text"
                        placeholder=""
                        name="deliverySpecialNotes"
                        handleChange={(e) => {
                          setFieldTouched('deliverySpecialNotes', true);
                          setFieldValue('deliverySpecialNotes', e.target.value);
                        }}
                      />
                    </div>
                    {values.orderType === 'CUSTOMER' && (
                      <div className="subSection">
                        <Input
                          label="Delivery Address"
                          type="text"
                          placeholder=""
                          name="addressForDelivery"
                          handleChange={(e) => {
                            setFieldTouched('addressForDelivery', true);
                            setFieldValue('addressForDelivery', e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="section">
                    <h3 className="subheader">Product Information</h3>
                    <div className="productHeader">
                      <Input
                        label="Search Items"
                        id="itemFilter"
                        type="text"
                        name="itemFilter"
                        placeholder="Enter Item"
                        handleChange={(v) => {
                          setFilterText(v.target.value);
                        }}
                      />
                      <Select
                        errors={errors}
                        touched={touched}
                        label="View"
                        id="itemsPerPage"
                        options={[
                          { value: 10, label: '10' },
                          { value: 25, label: '25' },
                          { value: 50, label: '50' },
                          { value: 100, label: '100' },
                        ]}
                        onChange={(v) => {
                          setEntriesPerPage(v.value);
                          setCurrentPage(1);
                        }}
                        value={10}
                      />
                    </div>
                    <BaseTable
                      onColumnSort={onColumnSort}
                      // TODO: fix quantity sorting
                      sortBy={sortBy}
                      data={currentEntries}
                      width={575}
                      height={600}
                      rowKey="id"
                    >
                      <Column
                        title="Item"
                        key="id"
                        dataKey="id"
                        sortable
                        width={350}
                      />
                      <Column
                        title="Quantity"
                        key="count"
                        dataKey="count"
                        cellRenderer={quantityRenderer}
                        width={100}
                        sortable
                      />
                    </BaseTable>
                    <div>
                      <Pagination
                        recordsPerPage={entriesPerPage}
                        totalRecords={dynamicItems.length}
                        paginate={paginate}
                      />
                    </div>
                    <div>
                      <Input
                        label="Kitchen Notes"
                        type="text"
                        name="kitchenSpecialNotes"
                        placeholder=""
                        handleChange={(e) => {
                          setFieldTouched('kitchenSpecialNotes', true);
                          setFieldValue('kitchenSpecialNotes', e.target.value);
                        }}
                      />
                    </div>
                    {submitError && (
                      <div className="submitError">
                        Oops! There was an error creating the order. You may not
                        have permissions to create orders or there may have been
                        a connection error. Please check your permissions and
                        try creating again.
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="actions">
                <Button
                  disabled={isSubmitDisabled(values)}
                  type="submit"
                  data-testid="bulkOrdersCreate-submit"
                >
                  Submit Order
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </StyledSection>
  );
};

OrdersCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrdersCreate;
