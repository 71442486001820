import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { contextState, contextDispatch } from './ContextProvider';

// For tests Launch Darkly needs some var even if it's fake
const env = process.env.REACT_APP_LAUNCH_DARKLY || 'foo';

const Features = ({ children, user }) => {
  const { contextData } = contextState();
  const dispatch = contextDispatch();
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    if (!fetchData) {
      setFetchData(true);
      const LDCUser = {
        email:
          user && user.signInUserSession
            ? user.signInUserSession.idToken.payload.email
            : 'guest',
        key: user && user.username ? user.username : 'guest',
      };
      const ldclient = LDClient.initialize(env, LDCUser);

      ldclient.on('ready', () => {
        dispatch({
          contextData: {
            ...contextData,
            features: ldclient.allFlags(),
          },
          type: 'set',
        });
      });
    }
  }, [contextData, dispatch, fetchData, user]);

  return <>{Object.keys(contextData.features).length > 0 && children}</>;
};

Features.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.shape(),
    idToken: PropTypes.shape(),
  }),
};

Features.defaultProps = {
  user: {},
};

export default Features;
