import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from '../utils/styledComponent';

const StyledButton = styledComponent('button', {
  '&:disabled': {
    backgroundColor: '#ccc',
  },
  '&.danger': {
    backgroundColor: '#f8f8f8',
    border: '1px solid #e7e7e7',
    color: '#e01f35',
  },
  '&.small': {
    fontSize: '12px',
    padding: '7px 15px 6px 15px',
  },
  border: 'none',
  backgroundColor: '#368007',
  borderRadius: '4px',
  color: '#FFF',
  fontSize: '14px',
  padding: '10px 30px',
});

const Button = ({ children, kind, size, ...otherProps }) => {
  return (
    <StyledButton className={`${kind} ${size}`} {...otherProps}>
      {children}
    </StyledButton>
  );
};
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  kind: PropTypes.oneOf(['danger', 'normal']),
  size: PropTypes.oneOf(['small', 'large']),
};

Button.defaultProps = {
  kind: 'normal',
  size: 'large',
};

export default Button;
